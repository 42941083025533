export default [
  {
    HGVBETRART: 'FHS4020',
    BART_TEXT: 'Abbruchbetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5176',
    BART_TEXT: 'Abfallbeseitigungsbetrieb (außer Fuhrbetrieb)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5177',
    BART_TEXT: 'Abfallbeseitigungsbetrieb (Fuhrbetrieb)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1547',
    BART_TEXT: 'Abflussreinigung',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1548',
    BART_TEXT: 'Abfüllbetrieb für Getränke (auch alkoholhaltig)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1549',
    BART_TEXT: 'Abfüllbetrieb für Getränke (Bier)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1550',
    BART_TEXT: 'Ablegebetrieb (Geflügel)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5136',
    BART_TEXT: 'Ablesedienst Einfache Ablese-, Montage- und Austauscharbeiten',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5137',
    BART_TEXT: 'Ablesedienst Installation, Auswechseln inkl. Ablesen von Stromzählern',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5138',
    BART_TEXT: 'Ablesedienst Installation, Auswechseln inkl. Ablesen von Wasseruhren und –zählern',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1551',
    BART_TEXT: 'Absaugunternehmen',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1552',
    BART_TEXT: 'Abschleppdienst (ohne Reparatur, Verwertung und Kfz)',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5178',
    BART_TEXT: 'Abwässerbeseitigungsbetrieb',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1553',
    BART_TEXT: 'Adressenverlag (ohne Druckerei)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1554',
    BART_TEXT: 'Adressenverlag mit Rotationstiefdruck',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1555',
    BART_TEXT: 'Adressenverlag ohne Rotationstiefdruck',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1556',
    BART_TEXT: 'Airbagherstellung (Luftsack)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1558',
    BART_TEXT: 'Akademie (öffentlich)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1559',
    BART_TEXT: 'Akademie (privat)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0005',
    BART_TEXT: 'Akkreditierter Zertifizierer',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1560',
    BART_TEXT: 'Akkumulatorenherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1561',
    BART_TEXT: 'Aktivkohleerzeugnisse (kosmetisch) - Herstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5179',
    BART_TEXT: 'Akustikbaubetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4135',
    BART_TEXT: 'Akustiker',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4144',
    BART_TEXT: 'Akutkrankenhaus mit verschiedenen Haupt-/Belegabteilungen einschließlich besonderer medizinischer Spezialeinrichtungen (z.B. Transplantationszentren) und einschließlich Geburtshilfe als Haupt- oder als Haupt- und Belegabteilung',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4143',
    BART_TEXT: 'Akutkrankenhaus mit verschiedenen Haupt-/Belegabteilungen einschließlich Geburtshilfe als Haupt- oder als Haupt- und Belegabteilung',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4142',
    BART_TEXT: 'Akutkrankenhaus mit verschiedenen Haupt-/Belegabteilungen; Geburtshilfe ausschließlich als Belegabteilung (nicht als Hauptabteilung)',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4141',
    BART_TEXT: 'Akutkrankenhaus mit verschiedenen Haupt-/Belegabteilungen; ohne Geburtshilfe (weder als Haupt- noch als Belegabteilung)',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1565',
    BART_TEXT: 'Alarmanlagenerrichter',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5180',
    BART_TEXT: 'Alarmanlagenherstellungsbetrieb',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0006',
    BART_TEXT: 'Alcantarabekleidungsfertigung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4346',
    BART_TEXT: 'Alcantarabekleidungshandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4347',
    BART_TEXT: 'Alcantarabekleidungshandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4348',
    BART_TEXT: 'Alcantarawarenhandel (nicht Bekleidung) - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4349',
    BART_TEXT: 'Alcantarawarenhandel (nicht Bekleidung) - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1571',
    BART_TEXT: 'Alkoholdestillation (Trinkalkohol)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1572',
    BART_TEXT: 'Alkoholfreie Getränke - Abfüllung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1573',
    BART_TEXT: 'Alkoholfreie Getränke - Herstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0007',
    BART_TEXT: 'Alkoholherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1574',
    BART_TEXT: 'Alkoholherstellung (Trinkalkohol)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1575',
    BART_TEXT: 'Alkoholherstellung durch Gärung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5411',
    BART_TEXT: 'Alleine tätiger freiberuflicher Lehrer',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0009',
    BART_TEXT: 'Alleinunterhalter',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0010',
    BART_TEXT: 'Allgemeinarzt - Assistenz-Facharzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0011',
    BART_TEXT: 'Allgemeinarzt - freie Praxis - ambulant/operativ',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0012',
    BART_TEXT: 'Allgemeinarzt - freie Praxis - stationär bzw. Belegarzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0013',
    BART_TEXT: 'Allgemeinarzt - stationär - Abteilungsleiter',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0014',
    BART_TEXT: 'Allgemeinarzt - stationär - Chefarzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0015',
    BART_TEXT: 'Allgemeinarzt - stationär - Oberarzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0016',
    BART_TEXT: 'Allgemeinarzt - stationär - Vertretungsarzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0017',
    BART_TEXT: 'Allianz-Hauptvertreter',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5412',
    BART_TEXT: 'Altenheim',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1578',
    BART_TEXT: 'Altenpflegedienst',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4350',
    BART_TEXT: 'Altmetallhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4351',
    BART_TEXT: 'Altmetallhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1580',
    BART_TEXT: 'Altmetallrecycling',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1581',
    BART_TEXT: 'Altmetallverwertung',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4594',
    BART_TEXT: 'Altpapierhandel - Einzelhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4595',
    BART_TEXT: 'Altpapierhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4582',
    BART_TEXT: 'Altpapierhandel, -verwertung - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis',
  },
  {
    HGVBETRART: 'FHS4583',
    BART_TEXT: 'Altpapierhandel, -verwertung - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1583',
    BART_TEXT: 'Altpapierrecycling',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1587',
    BART_TEXT: 'Aludruckguss mit Schmelzmassen bis 2,5 t pro Gefäß',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1588',
    BART_TEXT: 'Aluminiumbronzefarben, -lack - Herstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1589',
    BART_TEXT: 'Aluminiumherstellung',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5181',
    BART_TEXT: 'Aluminiumoxid - Be- und Verarbeitungsbetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1592',
    BART_TEXT: 'Aluminiumoxid - Herstellung (nicht Chemieanlage)',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0020',
    BART_TEXT: 'Ambulanter Pflegedienst',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0021',
    BART_TEXT: 'Amtsarzt - nur dienstliche Tätigkeit',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0022',
    BART_TEXT: 'Amtstierarzt ohne eigene Praxis',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1594',
    BART_TEXT: 'An- und Verkauf (nicht Textil)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0024',
    BART_TEXT: 'Anästhesist - Assistenz-Facharzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0026',
    BART_TEXT: 'Anästhesist - freie Praxis - ambulant/operativ',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0027',
    BART_TEXT: 'Anästhesist - freie Praxis - stationär bzw. Belegarzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0028',
    BART_TEXT: 'Anästhesist - stationär - Abteilungsleiter',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0029',
    BART_TEXT: 'Anästhesist - stationär - Chefarzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0030',
    BART_TEXT: 'Anästhesist - stationär - Oberarzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1595',
    BART_TEXT: 'Anästhesist - stationär - Vertretungsarzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4298',
    BART_TEXT: 'Andenkenhandel (kein Kiosk) - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis',
  },
  {
    HGVBETRART: 'FHS4299',
    BART_TEXT: 'Andenkenhandel (kein Kiosk) - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1597',
    BART_TEXT: 'Änderungsnäherei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1598',
    BART_TEXT: 'Änderungsschneiderei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5373',
    BART_TEXT: 'Angelsportverein',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4300',
    BART_TEXT: 'Anglerbedarfshandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis',
  },
  {
    HGVBETRART: 'FHS4301',
    BART_TEXT: 'Anglerbedarfshandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5182',
    BART_TEXT: 'Anhängerbaubetrieb',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4637',
    BART_TEXT: 'Anhängerhandel (mit Reparatur) - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5346',
    BART_TEXT: 'Anhängerhandel (mit Reparatur) - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4639',
    BART_TEXT: 'Anhängerhandel (ohne Reparatur) - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5058',
    BART_TEXT: 'Anhängerhandel (ohne Reparatur) - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1601',
    BART_TEXT: 'Anilinfarbstoffe - Verarbeitung',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1603',
    BART_TEXT: 'Anlagenberatungsbüro',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0033',
    BART_TEXT: 'Anlegestelle',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1604',
    BART_TEXT: 'Annahmestelle für chemische Reinigung, Wäscherei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1605',
    BART_TEXT: 'Annahmestelle für Lotterie',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0035',
    BART_TEXT: 'Anstreicher',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1606',
    BART_TEXT: 'Anstrichstoffe - Herstellung und Verarbeitung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1607',
    BART_TEXT: 'Antennenerrichter',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0036',
    BART_TEXT: 'Antennenherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1608',
    BART_TEXT: 'Antennenmontagebetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1609',
    BART_TEXT: 'Antibiotikaherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-C',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1610',
    BART_TEXT: 'Antiquariat (Bücher)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4314',
    BART_TEXT: 'Antiquitätenhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5414',
    BART_TEXT: 'Antiquitätenhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1611',
    BART_TEXT: 'Antracenfarbstoffe - Herstellung und Verarbeitung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5183',
    BART_TEXT: 'Anwaltskanzlei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0040',
    BART_TEXT: 'Apotheke',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1614',
    BART_TEXT: 'Appartementhotel (ohne Restaurant)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1615',
    BART_TEXT: 'Appreturbetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1616',
    BART_TEXT: 'Aquarium (Zoo)',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1617',
    BART_TEXT: 'Arbeitnehmerüberlassung',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4352',
    BART_TEXT: 'Arbeitsbekleidungshandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4353',
    BART_TEXT: 'Arbeitsbekleidungshandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1619',
    BART_TEXT: 'Arbeitsbekleidungsherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1620',
    BART_TEXT: 'Arbeitskräfteverleih',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1621',
    BART_TEXT: 'Arbeitsmaschinenherstellung (selbstfahrend)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0044',
    BART_TEXT: 'Arbeitsmediziner',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1622',
    BART_TEXT: 'Arbeitstherapeut',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0045',
    BART_TEXT: 'Architekt - Einzelobjekt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0047',
    BART_TEXT: 'Architekt ohne Objektschadendeckung',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4022',
    BART_TEXT: 'Architekturbüro',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1623',
    BART_TEXT: 'Arena',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1625',
    BART_TEXT: 'Armaturenherstellung (Elektro)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1626',
    BART_TEXT: 'Armaturenherstellung (Metall)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5374',
    BART_TEXT: 'Armbrustschützenverein',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1627',
    BART_TEXT: 'Aromen- und Essenzenherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-C',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5347',
    BART_TEXT: 'Arzneimittelhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5348',
    BART_TEXT: 'Arzneimittelhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-C',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS7813',
    BART_TEXT: 'Arzneimittelherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-C',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS7818',
    BART_TEXT: 'Arzneimittelherstellung nach AMG',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-C',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS7819',
    BART_TEXT: 'Arzneimittelherstellung nicht AMG',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-C',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0051',
    BART_TEXT: 'Arzt - gelegentliche außerdienstliche Tätigkeit',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4188',
    BART_TEXT: 'Arzt – nur freiberufliche Gutachter- und Konsiliartätigkeit',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4643',
    BART_TEXT: 'Ärztebedarfshandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4644',
    BART_TEXT: 'Ärztebedarfshandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1487',
    BART_TEXT: 'Ärztliche Ruhestandsversicherung',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0053',
    BART_TEXT: 'Asphalt-, Bitumenherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4023',
    BART_TEXT: 'Asphaltierbetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0055',
    BART_TEXT: 'Assistenzarzt ohne Facharztanerkennung',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0058',
    BART_TEXT: 'Assistenzzahnarzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1632',
    BART_TEXT: 'Asylbewerberheim',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1633',
    BART_TEXT: 'Atelier (nicht Film- und Fotoatelier)',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1634',
    BART_TEXT: 'Ätherische Öleherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-C',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0060',
    BART_TEXT: 'Auditor',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1637',
    BART_TEXT: 'Aufbereitung gemischter Stoffe (Recycling)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1639',
    BART_TEXT: 'Aufbereitung Kunststoff',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5059',
    BART_TEXT: 'Aufbereitung sortenreiner Stoffe (ausgenommen Metall, Papier, Glas, Kunststoff, Textil)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1641',
    BART_TEXT: 'Aufbereitung Textil',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1642',
    BART_TEXT: 'Aufnahmestudio',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5184',
    BART_TEXT: 'Aufzugbaubetrieb',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0062',
    BART_TEXT: 'Augenarzt - Assistenz-Facharzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0063',
    BART_TEXT: 'Augenarzt - freie Praxis - ambulant/operativ',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0064',
    BART_TEXT: 'Augenarzt - freie Praxis - stationär bzw. Belegarzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0065',
    BART_TEXT: 'Augenarzt - stationär - Abteilungsleiter',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0066',
    BART_TEXT: 'Augenarzt - stationär - Chefarzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0067',
    BART_TEXT: 'Augenarzt - stationär - Oberarzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0068',
    BART_TEXT: 'Augenarzt - stationär - Vertretungsarzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1644',
    BART_TEXT: 'Augenoptiker',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1645',
    BART_TEXT: 'Auktionshaus',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0069',
    BART_TEXT: 'Auskunftei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1648',
    BART_TEXT: 'Aussiedlerwohnheim',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1649',
    BART_TEXT: 'Ausstellung',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5673',
    BART_TEXT: 'Ausstellung, Museum',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0071',
    BART_TEXT: 'Ausstellungen und Messen (auch Tier- und Viehmärkte)',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1651',
    BART_TEXT: 'Ausstellungsbaubetrieb (Holz)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1652',
    BART_TEXT: 'Ausstellungsbaubetrieb (Kunststoff)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1653',
    BART_TEXT: 'Ausstellungsbaubetrieb (Metall)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1655',
    BART_TEXT: 'Autoglaserei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4646',
    BART_TEXT: 'Autohandel (mit Reparatur) - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4647',
    BART_TEXT: 'Autohandel (mit Reparatur) - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4648',
    BART_TEXT: 'Autohandel (ohne Reparatur) - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5060',
    BART_TEXT: 'Autohandel (ohne Reparatur) - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1488',
    BART_TEXT: 'Autohaus (Kraftfahrzeughandels- und -handwerksbetrieb)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0073',
    BART_TEXT: 'Autokino',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1657',
    BART_TEXT: 'Automaten (Werkstatt, Reparatur)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5139',
    BART_TEXT: 'Automatenaufsteller',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4650',
    BART_TEXT: 'Automatenhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4651',
    BART_TEXT: 'Automatenhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1659',
    BART_TEXT: 'Automatenherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1660',
    BART_TEXT: 'Automatenspielhalle',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0074',
    BART_TEXT: 'Automatenwäscherei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5140',
    BART_TEXT: 'Automationstechnikherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1661',
    BART_TEXT: 'Automatische Waschstrasse',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS7801',
    BART_TEXT: 'Automotive-Kooperationen',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5185',
    BART_TEXT: 'Automotorenbaubetrieb',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5186',
    BART_TEXT: 'Automotoreninstandsetzungsbetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1664',
    BART_TEXT: 'Autopflegebetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5141',
    BART_TEXT: 'Autor',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4162',
    BART_TEXT: 'Autoradio-/Navigationssystem-/Freisprecheinrichtungsbau',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4354',
    BART_TEXT: 'Autoradiohandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4355',
    BART_TEXT: 'Autoradiohandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4161',
    BART_TEXT: 'Auto-Sattlerei',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0076',
    BART_TEXT: 'Autoscooter mit Elektromotor',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5386',
    BART_TEXT: 'Autoscooter mit Verbrennungsmotor',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5187',
    BART_TEXT: 'Autovermietungsbüro',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1667',
    BART_TEXT: 'Auto-Verwertung',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1668',
    BART_TEXT: 'Autowaschanlage',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4652',
    BART_TEXT: 'Autozubehörhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5061',
    BART_TEXT: 'Autozubehörhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4654',
    BART_TEXT: 'Autozubehörhandel (ohne Einbau) - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5062',
    BART_TEXT: 'Autozubehörhandel (ohne Einbau) - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1670',
    BART_TEXT: 'Babyausstattungsgeschäft',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5088',
    BART_TEXT: 'Bachblütentherapeut',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4192',
    BART_TEXT: 'Bachelor of Science/Psychologie',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1671',
    BART_TEXT: 'Backaromenherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1672',
    BART_TEXT: 'Bäckerei (mit Backwarenherstellung)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1673',
    BART_TEXT: 'Bäckerei (ohne Backwarenherstellung)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4656',
    BART_TEXT: 'Bäckerei und Handel mit Nonfood - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5349',
    BART_TEXT: 'Bäckerei und Handel mit Nonfood - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4658',
    BART_TEXT: 'Bäckerei und Metzgerei sowie Handel mit Nonfood - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5350',
    BART_TEXT: 'Bäckerei und Metzgerei sowie Handel mit Nonfood - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4126',
    BART_TEXT: 'Bäckerei und Stehcafe',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4660',
    BART_TEXT: 'Bäckereibedarfshandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4661',
    BART_TEXT: 'Bäckereibedarfshandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1676',
    BART_TEXT: 'Backpulverherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4302',
    BART_TEXT: 'Backwarenhandel (ausschließlich Backwaren) - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4303',
    BART_TEXT: 'Backwarenhandel (ausschließlich Backwaren) - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4304',
    BART_TEXT: 'Backwarenhandel (mit weiterem Sortiment) - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4305',
    BART_TEXT: 'Backwarenhandel (mit weiterem Sortiment) - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0084',
    BART_TEXT: 'Backwarenherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1679',
    BART_TEXT: 'Bad',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1680',
    BART_TEXT: 'Badeanstalt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4093',
    BART_TEXT: 'Badmintonanlage',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1681',
    BART_TEXT: 'Badmintoncenter',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4024',
    BART_TEXT: 'Baggerbetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4166',
    BART_TEXT: 'Bahnbetrieb Güterbeförderung mit Gefahrgut',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4167',
    BART_TEXT: 'Bahnbetrieb Güterbeförderung ohne Gefahrgut',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0088',
    BART_TEXT: 'Bahnbetrieb nicht selbständiger Halter',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0089',
    BART_TEXT: 'Bahnbetrieb Personenbeförderung',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1211',
    BART_TEXT: 'Bahnbetrieb sonstiger Eisenbahndienstleister',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1682',
    BART_TEXT: 'Bahnen, elektrische - Herstellung oder Reparatur/Wartung',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0090',
    BART_TEXT: 'Ballettschule',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1683',
    BART_TEXT: 'Ballettstudio',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0091',
    BART_TEXT: 'Ballspielverein',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0092',
    BART_TEXT: 'Bandagist',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0093',
    BART_TEXT: 'Bank',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1684',
    BART_TEXT: 'Bankverwahrung von Kundensachen',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5351',
    BART_TEXT: 'Bar',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4306',
    BART_TEXT: 'Bastlerbedarfshandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4307',
    BART_TEXT: 'Bastlerbedarfshandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0096',
    BART_TEXT: 'Batterien-, Akkumulatorenherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0097',
    BART_TEXT: 'Bau-, Heimwerkermarkt',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-E',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4662',
    BART_TEXT: 'Bau-, Landmaschinen-, -gerätehandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4663',
    BART_TEXT: 'Bau-, Landmaschinen-, -gerätehandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5188',
    BART_TEXT: 'Bau-/Möbelschreinerei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5189',
    BART_TEXT: 'Baubetreuungsunternehmen',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1692',
    BART_TEXT: 'Baubude',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0101',
    BART_TEXT: 'Bauchtänzerin',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0102',
    BART_TEXT: 'Bauflaschnerei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1693',
    BART_TEXT: 'Baugenossenschaft',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4664',
    BART_TEXT: 'Baugerätehandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5420',
    BART_TEXT: 'Baugerätehandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0103',
    BART_TEXT: 'Bauglaserei mit Holzbearbeitung',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0104',
    BART_TEXT: 'Bauglaserei ohne Holzbearbeitung',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1695',
    BART_TEXT: 'Bauherr (gewerblich)',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4025',
    BART_TEXT: 'Bauhof',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1696',
    BART_TEXT: 'Bauingenieur',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0107',
    BART_TEXT: 'Bauingenieur - Einzelobjekt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4026',
    BART_TEXT: 'Bauingenieurbüro, Wasserbau, Rohrleitungsbau',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0110',
    BART_TEXT: 'Bauklempnerei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1697',
    BART_TEXT: 'Baumarkt',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-E',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4666',
    BART_TEXT: 'Baumaschinenhandel (mit Reparatur) - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5422',
    BART_TEXT: 'Baumaschinenhandel (mit Reparatur) - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5423',
    BART_TEXT: 'Baumaschinenhandel (ohne Reparatur) - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5424',
    BART_TEXT: 'Baumaschinenhandel (ohne Reparatur) - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1699',
    BART_TEXT: 'Baumaschinenherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5190',
    BART_TEXT: 'Baumaschinenreparaturbetrieb',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4027',
    BART_TEXT: 'Baumaschinenverleih mit Personal',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5426',
    BART_TEXT: 'Baumaschinenverleih ohne Personal',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4308',
    BART_TEXT: 'Baumaterialienhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4309',
    BART_TEXT: 'Baumaterialienhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1702',
    BART_TEXT: 'Baumschule',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1703',
    BART_TEXT: 'Baumwolle - Ausrüstung und Veredelung',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1704',
    BART_TEXT: 'Baumwollspinnerei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1705',
    BART_TEXT: 'Baumwollweberei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1706',
    BART_TEXT: 'Bauprodukteherstellung (Gummi)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1707',
    BART_TEXT: 'Bauprodukteherstellung (Kunststoff geschäumt) - Kaltverarbeitung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1708',
    BART_TEXT: 'Bauprodukteherstellung (Kunststoff geschäumt) - Warmverarbeitung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1709',
    BART_TEXT: 'Bauprodukteherstellung (Kunststoff ungeschäumt)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0116',
    BART_TEXT: 'Bauprodukteherstellung (Metall)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS7802',
    BART_TEXT: 'Bauproduktherstellung (Kunststoff geschäumt)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0117',
    BART_TEXT: 'Bauschlosserei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1710',
    BART_TEXT: 'Bauschreinerei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1711',
    BART_TEXT: 'Bausparkasse',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0119',
    BART_TEXT: 'Bausparkassenvertreter',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1712',
    BART_TEXT: 'Bauspenglerei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5676',
    BART_TEXT: 'Baustellenwohnwagen',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4310',
    BART_TEXT: 'Baustoffhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4311',
    BART_TEXT: 'Baustoffhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1715',
    BART_TEXT: 'Baustoffherstellung - Mineralien',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1716',
    BART_TEXT: 'Baustoffherstellung (mit Schaumkunststoffen) - Kaltverarbeitung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1717',
    BART_TEXT: 'Baustoffherstellung (mit Schaumkunststoffen) - Warmverarbeitung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1718',
    BART_TEXT: 'Baustoffherstellung (ohne Kunststoffe, ohne Mineralien)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1719',
    BART_TEXT: 'Bauteile, elektrische - Herstellung (Kunststoff)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1720',
    BART_TEXT: 'Bauteile, elektrische - Herstellung (ohne Kunststoff)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1721',
    BART_TEXT: 'Bauteile, elektronische - Herstellung (Kunststoff)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1722',
    BART_TEXT: 'Bauteile, elektronische - Herstellung (ohne Kunststoff)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0122',
    BART_TEXT: 'Bautenschutzbetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0123',
    BART_TEXT: 'Bautextilienherstellung, Herstellung technischer Textilien',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1723',
    BART_TEXT: 'Bautischlerei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5191',
    BART_TEXT: 'Bauträgerunternehmen',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5192',
    BART_TEXT: 'Bautrocknungsbetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1726',
    BART_TEXT: 'Bauunternehmen',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1727',
    BART_TEXT: 'Bauwagen',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5678',
    BART_TEXT: 'Bauwohnwagen',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1731',
    BART_TEXT: 'Bauzimmerei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1732',
    BART_TEXT: 'Beerdigungsinstitut',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1733',
    BART_TEXT: 'Behindertenheim ohne eigene ärztliche Betreuung',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1734',
    BART_TEXT: 'Behindertenpflegeeinrichtung',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4356',
    BART_TEXT: 'Bekleidungshandel (mit Leder-, Alcantarawaren, ohne Pelzwaren) - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4357',
    BART_TEXT: 'Bekleidungshandel (mit Leder-, Alcantarawaren, ohne Pelzwaren) - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4358',
    BART_TEXT: 'Bekleidungshandel (mit Pelzwaren) - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4359',
    BART_TEXT: 'Bekleidungshandel (mit Pelzwaren) - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4360',
    BART_TEXT: 'Bekleidungshandel (ohne Pelz-, Leder-, Alcantarawaren) - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4361',
    BART_TEXT: 'Bekleidungshandel (ohne Pelz-, Leder-, Alcantarawaren) - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4362',
    BART_TEXT: 'Bekleidungshandel mit Pelz-, Leder-, Alcantarawaren - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4363',
    BART_TEXT: 'Bekleidungshandel mit Pelz-, Leder-, Alcantarawaren - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1739',
    BART_TEXT: 'Bekleidungsherstellung (mit Leder, Alcantara ohne Pelz)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1740',
    BART_TEXT: 'Bekleidungsherstellung (mit Pelzwaren)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1741',
    BART_TEXT: 'Bekleidungsherstellung (ohne Pelz, Leder, Alcantara)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4312',
    BART_TEXT: 'Bekleidungszubehörhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4313',
    BART_TEXT: 'Bekleidungszubehörhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4158',
    BART_TEXT: 'Belegklinik mit Geburtshilfe',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4157',
    BART_TEXT: 'Belegklinik ohne Geburtshilfe',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0128',
    BART_TEXT: 'Belegkrankenhaus',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4364',
    BART_TEXT: 'Beleuchtungskörperhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4365',
    BART_TEXT: 'Beleuchtungskörperhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-G',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1746',
    BART_TEXT: 'Benzolfarbstoffe - Verarbeitung (nicht Pharmazie)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0129',
    BART_TEXT: 'Beratender Ingenieur - Einzelobjekt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1748',
    BART_TEXT: 'Beratender Ingenieur (außer Umwelt)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1749',
    BART_TEXT: 'Beratender Ingenieur (Umwelt)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4028',
    BART_TEXT: 'Beratender Ingenieur mit überwiegend wissenschaftlicher Arbeit',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1750',
    BART_TEXT: 'Bergbahnverkehr',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0132',
    BART_TEXT: 'Bergseilbahn',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1753',
    BART_TEXT: 'Bergsteigerschule',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1754',
    BART_TEXT: 'Bergverkehrseinrichtung',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4366',
    BART_TEXT: 'Berufsbekleidungshandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4367',
    BART_TEXT: 'Berufsbekleidungshandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4094',
    BART_TEXT: 'Berufsgenossenschaft',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4029',
    BART_TEXT: 'Berufsschule',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0134',
    BART_TEXT: 'Besamungsinstitut',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5644',
    BART_TEXT: 'Beschäftigungstherapeut',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0135',
    BART_TEXT: 'Besenherstellung',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1757',
    BART_TEXT: 'Besenwirtschaft',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0136',
    BART_TEXT: 'Bestattungsinstitut',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4670',
    BART_TEXT: 'Besteckhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4671',
    BART_TEXT: 'Besteckhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1759',
    BART_TEXT: 'Besteckherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0137',
    BART_TEXT: 'Bestrahlungsinstitut',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0138',
    BART_TEXT: 'Besucherbergwerk',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5193',
    BART_TEXT: 'Betonbaubetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5142',
    BART_TEXT: 'Betonbohrbetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5143',
    BART_TEXT: 'Betonsanierungsbetriebe',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4031',
    BART_TEXT: 'Betonschneidebetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0141',
    BART_TEXT: 'Betonwarenherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1760',
    BART_TEXT: 'Betonwerk',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5555',
    BART_TEXT: 'betreutes Wohnen',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1761',
    BART_TEXT: 'Betriebskantine',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1762',
    BART_TEXT: 'Bettengeschäft (ohne Reinigung)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0143',
    BART_TEXT: 'Bettfedernreinigung',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1763',
    BART_TEXT: 'Bettwarenherstellung (Gummi)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1766',
    BART_TEXT: 'Bettwarenherstellung (Textil)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0145',
    BART_TEXT: 'Bewachungsunternehmen',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1767',
    BART_TEXT: 'Bibliothek',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4268',
    BART_TEXT: 'Bierhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4269',
    BART_TEXT: 'Bierhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-E',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4370',
    BART_TEXT: 'Bijouteriewarenhandel (ohne Juwelierwaren) - Einzelhandel',
    'BART-Gruppe': 'VAL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'VAL-A'
  },
  {
    HGVBETRART: 'FHS5680',
    BART_TEXT: 'Bijouteriewarenhandel (ohne Juwelierwaren) - Großhandel',
    'BART-Gruppe': 'VAL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'VAL-A'
  },
  {
    HGVBETRART: 'FHS4194',
    BART_TEXT: 'Bilanzbuchhalter',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5124',
    BART_TEXT: 'Bild- und Tonstudio',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1772',
    BART_TEXT: 'Bildagentur',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1773',
    BART_TEXT: 'Bildberichterstatter',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4316',
    BART_TEXT: 'Bilderhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5432',
    BART_TEXT: 'Bilderhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1774',
    BART_TEXT: 'Bilderrahmenhandel (ohne Kunstgegenstände)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1775',
    BART_TEXT: 'Bilderrahmenherstellung (Holz)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1777',
    BART_TEXT: 'Bilderrahmenherstellung (Metall)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0149',
    BART_TEXT: 'Bildreporter (freiberuflich)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1778',
    BART_TEXT: 'Bildtechnikstudio',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1779',
    BART_TEXT: 'Billardsalon',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5682',
    BART_TEXT: 'Billardverein',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0152',
    BART_TEXT: 'Biogasanlage',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1785',
    BART_TEXT: 'Biogaskraftwerk',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1786',
    BART_TEXT: 'Bioladen',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1787',
    BART_TEXT: 'Biomasse-Heizwerk',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1788',
    BART_TEXT: 'Biomassekraftwerk (Feststoff)',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1789',
    BART_TEXT: 'Bistro',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1792',
    BART_TEXT: 'Bitumenverarbeitung',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4131',
    BART_TEXT: 'Blechblasinstrumentenbau',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-G',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5194',
    BART_TEXT: 'Blechkonstruktionsbetrieb, grobe',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4032',
    BART_TEXT: 'Blechschmiede',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1799',
    BART_TEXT: 'Blitzschutzanlageninstallationsbetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5195',
    BART_TEXT: 'Blitzschutzanlagenmontagebetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1801',
    BART_TEXT: 'Blockheizkraftwerk (BHKW)',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1802',
    BART_TEXT: 'Blockheizwerk',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1803',
    BART_TEXT: 'Blockschäumerei - Kaltverarbeitung',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1804',
    BART_TEXT: 'Blockschäumerei - Warmverarbeitung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4270',
    BART_TEXT: 'Blumen-, Pflanzenhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4271',
    BART_TEXT: 'Blumen-, Pflanzenhandel - Großhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4372',
    BART_TEXT: 'Blumenhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4373',
    BART_TEXT: 'Blumenhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-C',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4673',
    BART_TEXT: 'Bodenbelaghandel (mit Orientteppichen) - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4674',
    BART_TEXT: 'Bodenbelaghandel (mit Orientteppichen) - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4675',
    BART_TEXT: 'Bodenbelaghandel (ohne Orientteppiche) - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4676',
    BART_TEXT: 'Bodenbelaghandel (ohne Orientteppiche) - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1808',
    BART_TEXT: 'Bodenkarussell',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1810',
    BART_TEXT: 'Bodenverlegebetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1811',
    BART_TEXT: 'Bohnerwachsherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4033',
    BART_TEXT: 'Bohrbetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0162',
    BART_TEXT: 'Bonbonherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1813',
    BART_TEXT: 'Bootsanlegestelle',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1814',
    BART_TEXT: 'Bootsbau (Holz)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1815',
    BART_TEXT: 'Bootsbau (Kunststoff, ohne Polyester)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1816',
    BART_TEXT: 'Bootsbau (Metall)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1817',
    BART_TEXT: 'Bootsbau (Polyester, reaktionshärtende Kunststoffe)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4677',
    BART_TEXT: 'Bootshandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4678',
    BART_TEXT: 'Bootshandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0164',
    BART_TEXT: 'Bootshaus',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0165',
    BART_TEXT: 'Bootslager',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0167',
    BART_TEXT: 'Bootsverleih',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1820',
    BART_TEXT: 'Börsenmakler',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0168',
    BART_TEXT: 'Botanischer Garten',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4035',
    BART_TEXT: 'Böttcherei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1821',
    BART_TEXT: 'Boutique (mit Pelzwaren)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1822',
    BART_TEXT: 'Boutique (ohne Pelz-, Leder-, Alcantarawaren)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1823',
    BART_TEXT: 'Boutique (ohne Pelzwaren, mit Leder-, Alcantarawaren)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1824',
    BART_TEXT: 'Bowlingbahn',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0170',
    BART_TEXT: 'Bowlingcenter',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1825',
    BART_TEXT: 'Brandmeldeanlagenherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5196',
    BART_TEXT: 'Brandmeldeanlagenmontagebetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4000',
    BART_TEXT: 'Brandschutzingenieur',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1827',
    BART_TEXT: 'Branntweinherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1828',
    BART_TEXT: 'Brauerei',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0171',
    BART_TEXT: 'Brauerei ohne Mälzerei',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1831',
    BART_TEXT: 'Bräunungsstudio',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1832',
    BART_TEXT: 'Brautmodengeschäft',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1833',
    BART_TEXT: 'Bremsendienst',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4036',
    BART_TEXT: 'Brennerei',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4679',
    BART_TEXT: 'Brennstoffhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4680',
    BART_TEXT: 'Brennstoffhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4318',
    BART_TEXT: 'Briefmarkenhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5435',
    BART_TEXT: 'Briefmarkenhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5683',
    BART_TEXT: 'Briefmarkenverein',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1839',
    BART_TEXT: 'Brillenherstellung (Kunststoff)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1840',
    BART_TEXT: 'Brillenherstellung (Metall)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1841',
    BART_TEXT: 'Bronzefarben, -lack - Herstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1842',
    BART_TEXT: 'Brotfabrik',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5437',
    BART_TEXT: 'Brotwarenherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1843',
    BART_TEXT: 'Brückenbaubetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4681',
    BART_TEXT: 'Brunnenbau',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1845',
    BART_TEXT: 'Buchbinderei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0179',
    BART_TEXT: 'Buchbinderei (nicht Großbuchbinderei)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1846',
    BART_TEXT: 'Buchdruckerei - ohne Rotationstiefdruck',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1847',
    BART_TEXT: 'Buchdruckerei - Rotationstiefdruck',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1848',
    BART_TEXT: 'Bücherei',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0180',
    BART_TEXT: 'Bücherverleih',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5197',
    BART_TEXT: 'Buchführungshelferbüro',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5198',
    BART_TEXT: 'Buchhaltungsbüro',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4374',
    BART_TEXT: 'Buchhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4375',
    BART_TEXT: 'Buchhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5199',
    BART_TEXT: 'Buchprüfungsbüro',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1851',
    BART_TEXT: 'Büchsenmacherei',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1852',
    BART_TEXT: 'Buchverlag (ohne Druckerei)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0184',
    BART_TEXT: 'Büglerei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5352',
    BART_TEXT: 'Bundeswehrarzt - nur dienstliche Tätigkeit',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4376',
    BART_TEXT: 'Buntmetallhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5439',
    BART_TEXT: 'Buntmetallhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1854',
    BART_TEXT: 'Buntpapier - Herstellung und Verarbeitung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1855',
    BART_TEXT: 'Bürgerhaus',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1856',
    BART_TEXT: 'Bürgersteigreinigung',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1857',
    BART_TEXT: 'Büro für landwirtschaftlichen Betrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1858',
    BART_TEXT: 'Büro innerhalb Handel, Handwerk, Produktion',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0187',
    BART_TEXT: 'Büro- und Kommunikationsgeräteherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4682',
    BART_TEXT: 'Bürobedarfshandel (nicht Büromaschinen, -einrichtung) - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4683',
    BART_TEXT: 'Bürobedarfshandel (nicht Büromaschinen, -einrichtung) - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4684',
    BART_TEXT: 'Büroeinrichtungshandel - Einzelhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4685',
    BART_TEXT: 'Büroeinrichtungshandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4686',
    BART_TEXT: 'Büroeinrichtungshandel ohne Büromaschinen - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4687',
    BART_TEXT: 'Büroeinrichtungshandel ohne Büromaschinen - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4688',
    BART_TEXT: 'Büromaschinenhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4689',
    BART_TEXT: 'Büromaschinenhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-E',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1860',
    BART_TEXT: 'Büromaschinenherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-E',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0191',
    BART_TEXT: 'Büroreinigungsbetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5144',
    BART_TEXT: 'Büroserviceunternehmen',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0192',
    BART_TEXT: 'Bürsten-, Besen-, Pinselherstellung',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0193',
    BART_TEXT: 'Bürstenherstellung',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0194',
    BART_TEXT: 'Busunternehmen',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0195',
    BART_TEXT: 'Café',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1862',
    BART_TEXT: 'Callcenter',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5037',
    BART_TEXT: 'Callshop',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4380',
    BART_TEXT: 'Campingartikelhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4381',
    BART_TEXT: 'Campingartikelhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5390',
    BART_TEXT: 'Cartbahn (stationär)',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1869',
    BART_TEXT: 'Catering',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4692',
    BART_TEXT: 'CD-Handel - Einzelhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4693',
    BART_TEXT: 'CD-Handel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5200',
    BART_TEXT: 'Chemiefaser - Ausrüstungs - und Veredelungsbetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1883',
    BART_TEXT: 'Chemiefasernherstellung (vollsynthetisch)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1884',
    BART_TEXT: 'Chemiefaserspinnerei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1885',
    BART_TEXT: 'Chemiefaserweberei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4694',
    BART_TEXT: 'Chemikalienhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4695',
    BART_TEXT: 'Chemikalienhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-C',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1874',
    BART_TEXT: 'Chemische Anlage, Produkte: Verarbeitung und Herstellung brennbarer, aber nicht leicht entflammbarer Grund-, Zwischen-, und Endprodukte',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1875',
    BART_TEXT: 'Chemische Anlage, Produkte: Verarbeitung und Herstellung brennbarer, jedoch nicht leicht entzündlicher Stoffe und Zubereitungen',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1876',
    BART_TEXT: 'Chemische Anlage, Produkte: Verarbeitung und Herstellung explosiver Grund-, Zwischen- und Endprodukte',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1880',
    BART_TEXT: 'Chemische Anlage, Produkte: Verarbeitung und Herstellung nicht brennbarer Grund-, Zwischen- und Endprodukte',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1881',
    BART_TEXT: 'Chemische Anlage, Produkte: Verarbeitung und Herstellung nicht brennbarer Stoffe und Zubereitungen',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0201',
    BART_TEXT: 'Chemische Reinigung',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1886',
    BART_TEXT: 'Chemische Reinigung (nur Annahmestelle)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0202',
    BART_TEXT: 'Chemisches Labor',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1887',
    BART_TEXT: 'Chinin - Herstellung und Verarbeitung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0208',
    BART_TEXT: 'Chirotherapeut - Assistenz-Facharzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0209',
    BART_TEXT: 'Chirotherapeut - freie Praxis - ambulant/operativ',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0210',
    BART_TEXT: 'Chirotherapeut - freie Praxis - stationär bzw. Belegarzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0212',
    BART_TEXT: 'Chirotherapeut - stationär - Abteilungsleiter',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0213',
    BART_TEXT: 'Chirotherapeut - stationär - Chefarzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0214',
    BART_TEXT: 'Chirotherapeut - stationär - Oberarzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0215',
    BART_TEXT: 'Chirotherapeut - stationär - Vertretungsarzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0216',
    BART_TEXT: 'Chirurg - Assistenz-Facharzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0217',
    BART_TEXT: 'Chirurg - freie Praxis - ambulant/operativ',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0219',
    BART_TEXT: 'Chirurg - freie Praxis - stationär bzw. Belegarzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1888',
    BART_TEXT: 'Chirurg - kosmetisch/ästhetisch - ambulant/operativ',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0220',
    BART_TEXT: 'Chirurg - kosmetisch/ästhetisch - stationär bzw. Belegarzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0221',
    BART_TEXT: 'Chirurg - stationär - Abteilungsleiter',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0222',
    BART_TEXT: 'Chirurg - stationär - Chefarzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0223',
    BART_TEXT: 'Chirurg - stationär - Oberarzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0224',
    BART_TEXT: 'Chirurg - stationär - Vertretungsarzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5353',
    BART_TEXT: 'Chirurgiemechaniker',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5145',
    BART_TEXT: 'Chor',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1890',
    BART_TEXT: 'Chromfarbenherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4039',
    BART_TEXT: 'Clubhaus',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4198',
    BART_TEXT: 'Coach (nicht Sport)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5645',
    BART_TEXT: 'Coach (Sport)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1892',
    BART_TEXT: 'Coiffeur',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1893',
    BART_TEXT: 'Composerstudio',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1894',
    BART_TEXT: 'Computer (Werkstatt, Service ohne Handel)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4584',
    BART_TEXT: 'Computerhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4585',
    BART_TEXT: 'Computerhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1895',
    BART_TEXT: 'Computerherstellung (PC-Herstellung)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5201',
    BART_TEXT: 'Computerprogrammentwicklungsbüro (Software)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5146',
    BART_TEXT: 'Computerreparatur',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4696',
    BART_TEXT: 'Computerspielehandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4697',
    BART_TEXT: 'Computerspielehandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4382',
    BART_TEXT: 'Computerzubehörhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4383',
    BART_TEXT: 'Computerzubehörhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4384',
    BART_TEXT: 'Confiserie (Handel) - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4385',
    BART_TEXT: 'Confiserie (Handel) - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4041',
    BART_TEXT: 'Contactlinsenstudio',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0229',
    BART_TEXT: 'Containerdienst',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4138',
    BART_TEXT: 'Containerdienst (ohne Lager, ohne Verwertung)',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0230',
    BART_TEXT: 'Copy-Shop',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1903',
    BART_TEXT: 'Creperie',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1905',
    BART_TEXT: 'Dachbahnenherstellung (Kunststoff)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1906',
    BART_TEXT: 'Dachdeckerei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1907',
    BART_TEXT: 'Dachpappeherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4699',
    BART_TEXT: 'Darmhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4700',
    BART_TEXT: 'Darmhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-C',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1911',
    BART_TEXT: 'Darrbetriebe',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1912',
    BART_TEXT: 'Datennetzbetreiber',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5202',
    BART_TEXT: 'Datenprogrammentwicklungsbüro (Software)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4701',
    BART_TEXT: 'Datenprogrammhandel (Software) - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4702',
    BART_TEXT: 'Datenprogrammhandel (Software) - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5147',
    BART_TEXT: 'Datenschutzbeauftragter',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1915',
    BART_TEXT: 'Datenverarbeitungsanlagenherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0232',
    BART_TEXT: 'Datenverarbeitungsbetrieb',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0233',
    BART_TEXT: 'Daunendeckenherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5203',
    BART_TEXT: 'Dekorateurbetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1917',
    BART_TEXT: 'Dekorationsgeschäft (mit Orientteppichen)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1918',
    BART_TEXT: 'Dekorationsgeschäft (ohne Orientteppiche)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1919',
    BART_TEXT: 'Delikatessengeschäft',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1920',
    BART_TEXT: 'Delikatesswarenherstellung (Fleisch, Wurst, Fisch)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-C',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1921',
    BART_TEXT: 'Delikatesswarenherstellung (Obst, Gemüse)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1922',
    BART_TEXT: 'Demontage elektrischer Haushaltsgeräte',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1923',
    BART_TEXT: 'Demontage elektrischer und elektronischer Geräte',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4623',
    BART_TEXT: 'Demontage von Produkten in Bauteile',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1925',
    BART_TEXT: 'Dentallabor',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0236',
    BART_TEXT: 'Dermatologe - Assistenz-Facharzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0237',
    BART_TEXT: 'Dermatologe - freie Praxis - ambulant/operativ',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0238',
    BART_TEXT: 'Dermatologe - freie Praxis - stationär bzw. Belegarzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0239',
    BART_TEXT: 'Dermatologe - stationär - Abteilungsleiter',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0240',
    BART_TEXT: 'Dermatologe - stationär - Chefarzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0241',
    BART_TEXT: 'Dermatologe - stationär - Oberarzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0242',
    BART_TEXT: 'Dermatologe - stationär - Vertretungsarzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0243',
    BART_TEXT: 'Desinfektor',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1926',
    BART_TEXT: 'Dessousfertigung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4386',
    BART_TEXT: 'Dessoushandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4387',
    BART_TEXT: 'Dessoushandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1928',
    BART_TEXT: 'Detektei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0244',
    BART_TEXT: 'Detektiv',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4388',
    BART_TEXT: 'Devotionalienhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4389',
    BART_TEXT: 'Devotionalienhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0245',
    BART_TEXT: 'Diagnosegeräte(teile)herstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1931',
    BART_TEXT: 'Diakonisches Heim',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1932',
    BART_TEXT: 'Dialysezentrum',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1935',
    BART_TEXT: 'Dieselmotorenherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4390',
    BART_TEXT: 'Digitalkamerahandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4391',
    BART_TEXT: 'Digitalkamerahandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0246',
    BART_TEXT: 'Diplom-Psychologe',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0247',
    BART_TEXT: 'Diskothek',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0248',
    BART_TEXT: 'DJ',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5204',
    BART_TEXT: 'Dolmetscherbüro',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1941',
    BART_TEXT: 'Dolmetscherschule (öffentlich)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5443',
    BART_TEXT: 'Dolmetscherschule (privat)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4392',
    BART_TEXT: 'Drahtwarenhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5445',
    BART_TEXT: 'Drahtwarenhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1945',
    BART_TEXT: 'Drahtwarenherstellung',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1946',
    BART_TEXT: 'Drahtzieherei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1947',
    BART_TEXT: 'Drechslerei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4199',
    BART_TEXT: 'Dreherei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0251',
    BART_TEXT: 'Drogerie',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5646',
    BART_TEXT: 'Drogerie - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5647',
    BART_TEXT: 'Drogerie - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5038',
    BART_TEXT: 'Droschken-, Taxizentrale',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1951',
    BART_TEXT: 'Druckerei (Kunststoff)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1952',
    BART_TEXT: 'Druckerei (Metall)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1953',
    BART_TEXT: 'Druckerei (Pappe, Papier) ohne Rotationstiefdruck',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1954',
    BART_TEXT: 'Druckerei (Stoffe)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1955',
    BART_TEXT: 'Druckerei (Tapeten) ohne Rotationstiefdruck',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1956',
    BART_TEXT: 'Druckerei, grafischer Betrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1957',
    BART_TEXT: 'Druckfarbenherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1958',
    BART_TEXT: 'Druckformenherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4705',
    BART_TEXT: 'Düngemittelhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4706',
    BART_TEXT: 'Düngemittelhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-C',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0255',
    BART_TEXT: 'Düngemittelherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-C',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1959',
    BART_TEXT: 'Duroplastische Kunststoffartikel - Herstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1960',
    BART_TEXT: 'DVD-Herstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1961',
    BART_TEXT: 'DVD-Verleih',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4396',
    BART_TEXT: 'Edelstahlhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5447',
    BART_TEXT: 'Edelstahlhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1966',
    BART_TEXT: 'EDV-Anlagenherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1967',
    BART_TEXT: 'EDV-Dienstleistungsbetrieb',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5205',
    BART_TEXT: 'Eheberatungsbüro',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4709',
    BART_TEXT: 'Ehehygieneartikelhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4710',
    BART_TEXT: 'Ehehygieneartikelhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0257',
    BART_TEXT: 'Einkaufscenter',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0258',
    BART_TEXT: 'Einkaufsmarkt',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1971',
    BART_TEXT: 'Einkaufszentrale für Einzelhändler',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1972',
    BART_TEXT: 'Einkaufszentrum',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0260',
    BART_TEXT: 'Einmann-Baggerbetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1973',
    BART_TEXT: 'Einreißarbeiten, Abbruchbetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4398',
    BART_TEXT: 'Einrichtungshandel (Möbel - mit Orientteppichen) - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4399',
    BART_TEXT: 'Einrichtungshandel (Möbel - mit Orientteppichen) - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4400',
    BART_TEXT: 'Einrichtungshandel (Möbel - ohne Orientteppiche) - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4401',
    BART_TEXT: 'Einrichtungshandel (Möbel - ohne Orientteppiche) - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5035',
    BART_TEXT: 'Einzelteile/Komponentenherstellung Anlagen- und Kesselbau',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4628',
    BART_TEXT: 'Einzelteile/Komponentenherstellung Baumaschinen, Antriebs-/Fördertechnik',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5036',
    BART_TEXT: 'Einzelteile/Komponentenherstellung Installations- und Lufttechnik',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5032',
    BART_TEXT: 'Einzelteile/Komponentenherstellung Maschinenbau/Spezialmaschinenbau',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5033',
    BART_TEXT: 'Einzelteile/Komponentenherstellung Präzisions- und Automationstechnik',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-C',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5034',
    BART_TEXT: 'Einzelteile/Komponentenherstellung verfahrens- und prozesstechnische Anlagen',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-C',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1978',
    BART_TEXT: 'Eiscafe',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0269',
    BART_TEXT: 'Eisdiele',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1979',
    BART_TEXT: 'Eisen - Flechter, Monierung',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5206',
    BART_TEXT: 'Eisen - Stahlerzeugungsbetrieb',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4608',
    BART_TEXT: 'Eisen-/Metallwarenhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5449',
    BART_TEXT: 'Eisen-/Metallwarenhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1981',
    BART_TEXT: 'Eisenbahn',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1982',
    BART_TEXT: 'Eisenbahnbetrieb',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5207',
    BART_TEXT: 'Eisenbaubetrieb',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5208',
    BART_TEXT: 'Eisengewinnungsbetrieb',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4404',
    BART_TEXT: 'Eisenwarenhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5451',
    BART_TEXT: 'Eisenwarenhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1988',
    BART_TEXT: 'Eisenwarenherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0271',
    BART_TEXT: 'Eislaufbahn',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1989',
    BART_TEXT: 'Eissporthalle',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0272',
    BART_TEXT: 'Eissportverein',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1990',
    BART_TEXT: 'Elektrische Bauteile - Herstellung (Kunststoff)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1991',
    BART_TEXT: 'Elektrische Bauteile - Herstellung (ohne Kunststoff)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1992',
    BART_TEXT: 'Elektrische Kraftstation',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1533',
    BART_TEXT: 'Elektrizitätserzeugung mittels Solar- und Fotovoltaikanlagen bis 300 KWp',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1532',
    BART_TEXT: 'Elektrizitätserzeugung mittels Solar- und Fotovoltaikanlagen über 300 KWp',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4042',
    BART_TEXT: 'Elektrizitätserzeugung mittels Wasserräder',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1535',
    BART_TEXT: 'Elektrizitätserzeugung mittels Windenergieanlagen (ab 3 Anlagen)',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1534',
    BART_TEXT: 'Elektrizitätserzeugung mittels Windenergieanlagen (bis 2 Anlagen)',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1531',
    BART_TEXT: 'Elektrizitätswerk (auch Wasserkraftwerk nicht Solar-/Fotovoltaik)',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5553',
    BART_TEXT: 'Elektrizitätswerk (mit Kohleverbrennung)',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1993',
    BART_TEXT: 'Elektrizitätswerk (Wasserantrieb)',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1994',
    BART_TEXT: 'Elektro-Großmaschinenherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5209',
    BART_TEXT: 'Elektrohandwerksbetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1996',
    BART_TEXT: 'Elektroinstallationsbetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5210',
    BART_TEXT: 'Elektroinstallationsbetrieb und Handel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1997',
    BART_TEXT: 'Elektro-Kleinmaschinenherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4711',
    BART_TEXT: 'Elektromotorenhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4712',
    BART_TEXT: 'Elektromotorenhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1999',
    BART_TEXT: 'Elektromotorenherstellung (ab 5 kW)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2000',
    BART_TEXT: 'Elektromotorenherstellung (bis 5 kW)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4713',
    BART_TEXT: 'Elektronische Bauelemente - Handel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4714',
    BART_TEXT: 'Elektronische Bauelemente - Handel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2003',
    BART_TEXT: 'Elektronische Bauelemente - Herstellung (Kunststoff)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2004',
    BART_TEXT: 'Elektronische Bauelemente - Herstellung (ohne Kunststoff)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2005',
    BART_TEXT: 'Elektroröhrenherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-G',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2006',
    BART_TEXT: 'Elektrotechnik, elektronische Produkte - Herstellung (Bauteile, Glühlampen, Röhren)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2007',
    BART_TEXT: 'Elektrotechnik, elektronische Produkte - Herstellung (Großmaschinen)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0291',
    BART_TEXT: 'Elektrotechniker (Installation am Bau)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4043',
    BART_TEXT: 'Elektrotechnische Werkstatt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2008',
    BART_TEXT: 'Elektrotechnischer Betrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4406',
    BART_TEXT: 'Elektrowarenhandel (mit Unterhaltungs-, Kommunikationselektronik) - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4407',
    BART_TEXT: 'Elektrowarenhandel (mit Unterhaltungs-, Kommunikationselektronik) - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4408',
    BART_TEXT: 'Elektrowarenhandel (ohne Unterhaltungselektronik) - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4409',
    BART_TEXT: 'Elektrowarenhandel (ohne Unterhaltungselektronik) - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2011',
    BART_TEXT: 'Elektrowarenherstellung (mit Unterhaltungselektronik)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2012',
    BART_TEXT: 'Elektrowarenherstellung (ohne Unterhaltungselektronik)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2013',
    BART_TEXT: 'Eloxierbetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5148',
    BART_TEXT: 'Energieberatung',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4715',
    BART_TEXT: 'Energiestoffhandel (nicht Tankstelle) - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4716',
    BART_TEXT: 'Energiestoffhandel (nicht Tankstelle) - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4003',
    BART_TEXT: 'Entbindungspfleger mit Geburtshilfe',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4004',
    BART_TEXT: 'Entbindungspfleger ohne Geburtshilfe',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5149',
    BART_TEXT: 'Entrümpelungsunternehmen (kein Recycling)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2018',
    BART_TEXT: 'Erdengewinnung',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2019',
    BART_TEXT: 'Erdenverarbeitung',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2020',
    BART_TEXT: 'Erdgas-, Bohr- und Förderanlage',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2021',
    BART_TEXT: 'Erdnussprodukte - Herstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-C',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2023',
    BART_TEXT: 'Ergotherapiepraxis',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2024',
    BART_TEXT: 'Erholungsheim',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5453',
    BART_TEXT: 'Erholungsheim ohne eigene ärztliche Betreuung',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2026',
    BART_TEXT: 'Erlebnisbad',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2027',
    BART_TEXT: 'Erlebnispark (stationäre Fahrgeschäfte)',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0302',
    BART_TEXT: 'Eroscenter',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0304',
    BART_TEXT: 'Espressobar',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2036',
    BART_TEXT: 'Essenzenherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-C',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0305',
    BART_TEXT: 'Essig-, Senfherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-C',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2037',
    BART_TEXT: 'Essigherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-C',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1489',
    BART_TEXT: 'Estrichlegebetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2040',
    BART_TEXT: 'Etherische Öle - Herstellung und Verarbeitung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-C',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1537',
    BART_TEXT: 'Eventagentur',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4720',
    BART_TEXT: 'E-Zigarettenhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4721',
    BART_TEXT: 'E-Zigarettenhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2043',
    BART_TEXT: 'Fachakademie (öffentlich)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5455',
    BART_TEXT: 'Fachakademie (privat)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2046',
    BART_TEXT: 'Fachhochschule (öffentlich)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5457',
    BART_TEXT: 'Fachhochschule (privat)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2048',
    BART_TEXT: 'Fachingenieur (außer Umwelt)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2049',
    BART_TEXT: 'Fachingenieur für Elektronik',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2050',
    BART_TEXT: 'Fachingenieur für Heizung',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2051',
    BART_TEXT: 'Fachingenieur für Lüftung',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2052',
    BART_TEXT: 'Fachingenieur für Sanitär',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2053',
    BART_TEXT: 'Fachingenieur für Umwelt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4146',
    BART_TEXT: 'Fachklinik Chirurgie',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS7822',
    BART_TEXT: 'Fachklinik Chirurgie, Orthopädie oder Gynäkologie ohne Geburtshilfe',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4149',
    BART_TEXT: 'Fachklinik Gynäkologie mit Geburtshilfe',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4148',
    BART_TEXT: 'Fachklinik Gynäkologie ohne Geburtshilfe',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4152',
    BART_TEXT: 'Fachklinik Neurologie',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4147',
    BART_TEXT: 'Fachklinik Orthopädie',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4151',
    BART_TEXT: 'Fachklinik Psychiatrie',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4150',
    BART_TEXT: 'Fachklinik Psychiatrie oder Neurologie',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4722',
    BART_TEXT: 'Fachzahnärzte – stationär/ambulant, ohne eigene Praxis - in Kliniken beschäftigt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5150',
    BART_TEXT: 'Facility Management',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2054',
    BART_TEXT: 'Fahnenherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2055',
    BART_TEXT: 'Fahrlehrer',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4322',
    BART_TEXT: 'Fahrradhandel mit Reparatur/Verleih - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4323',
    BART_TEXT: 'Fahrradhandel mit Reparatur/Verleih - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4320',
    BART_TEXT: 'Fahrradhandel ohne Reparatur/Verleih - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4321',
    BART_TEXT: 'Fahrradhandel ohne Reparatur/Verleih - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4044',
    BART_TEXT: 'Fahrradherstellung (auch Teile)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4045',
    BART_TEXT: 'Fahrradreparatur',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5094',
    BART_TEXT: 'Fahrradrikscha',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0313',
    BART_TEXT: 'Fahrradverleih',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0314',
    BART_TEXT: 'Fahrschule',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5211',
    BART_TEXT: 'Fahrzeugbaubetrieb (Kunststoff, ohne Polyester)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5212',
    BART_TEXT: 'Fahrzeugbaubetrieb (Metall)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2061',
    BART_TEXT: 'Fahrzeugteileherstellung (Kunststoff geschäumt) - Kaltverarbeitung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2062',
    BART_TEXT: 'Fahrzeugteileherstellung (Kunststoff geschäumt) - Warmverarbeitung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2063',
    BART_TEXT: 'Fahrzeugteileherstellung (Kunststoff ungeschäumt)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2064',
    BART_TEXT: 'Fahrzeugteileherstellung (Metall)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5213',
    BART_TEXT: 'Fahrzeugverwertungsbetrieb',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4723',
    BART_TEXT: 'Fahrzeugzubehörhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5064',
    BART_TEXT: 'Fahrzeugzubehörhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4725',
    BART_TEXT: 'Fanartikelhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4726',
    BART_TEXT: 'Fanartikelhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0317',
    BART_TEXT: 'Farbbandherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2067',
    BART_TEXT: 'Farben-, Lackherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4410',
    BART_TEXT: 'Farbenhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4411',
    BART_TEXT: 'Farbenhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0320',
    BART_TEXT: 'Färberei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2069',
    BART_TEXT: 'Farbmittelherstellung (Pigmente)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0322',
    BART_TEXT: 'Faschingsverein',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5151',
    BART_TEXT: 'Fassadenbaubetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0323',
    BART_TEXT: 'Fassadenmaler',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0324',
    BART_TEXT: 'Fassadenreinigungsbetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5214',
    BART_TEXT: 'Fassadenverkleidungsmontagebetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5375',
    BART_TEXT: 'Fechtverein',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0326',
    BART_TEXT: 'Federbettenherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2074',
    BART_TEXT: 'Feinkeramikherstellung (handwerklich)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-G',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2075',
    BART_TEXT: 'Feinkostgeschäft',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2076',
    BART_TEXT: 'Feinkostwarenherstellung (Fleisch, Wurst, Fisch)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-C',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2077',
    BART_TEXT: 'Feinkostwarenherstellung (Obst, Gemüse)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2078',
    BART_TEXT: 'Feinmechanisches Handwerk',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2079',
    BART_TEXT: 'Feinsteinzeugherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-G',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4727',
    BART_TEXT: 'Fellehandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4728',
    BART_TEXT: 'Fellehandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5215',
    BART_TEXT: 'Fenstereinbaubetrieb (ohne Herstellung)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4729',
    BART_TEXT: 'Fensterhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4730',
    BART_TEXT: 'Fensterhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-G',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2086',
    BART_TEXT: 'Fensterherstellung (Holz)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-G',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2087',
    BART_TEXT: 'Fensterherstellung (Kunststoff)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-G',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2088',
    BART_TEXT: 'Fensterherstellung (Metall)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-G',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0332',
    BART_TEXT: 'Fensterreinigungsbetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2089',
    BART_TEXT: 'Ferienwohnungsanlage (vermietet, entgeltlich genutzt)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2090',
    BART_TEXT: 'Fernheizwerk',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5554',
    BART_TEXT: 'Fernheizwerk (mit Kohleverbrennung)',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0333',
    BART_TEXT: 'Fernheizwerk mit AVB-Vereinbarung',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0334',
    BART_TEXT: 'Fernheizwerk ohne AVB-Vereinbarung',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0335',
    BART_TEXT: 'Fernküche',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2091',
    BART_TEXT: 'Fernmeldetechnik - Installation und Montage',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2092',
    BART_TEXT: 'Fernsehanstalt',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4414',
    BART_TEXT: 'Fernsehgerätehandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4415',
    BART_TEXT: 'Fernsehgerätehandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5216',
    BART_TEXT: 'Fernsehgerätereparaturbetrieb (ohne Handel)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2096',
    BART_TEXT: 'Fernsehstudio',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2099',
    BART_TEXT: 'Fertigbetonwerk',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2100',
    BART_TEXT: 'Fertiggerichteherstellung (Fleisch, Wurst, Fisch)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2101',
    BART_TEXT: 'Fertiggerichteherstellung (Obst, Gemüse)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2102',
    BART_TEXT: 'Fertiggerichteherstellung (Trockenherstellung)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-C',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2103',
    BART_TEXT: 'Fertigparkettherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2104',
    BART_TEXT: 'Festhalle',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5376',
    BART_TEXT: 'Festveranstaltungen',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2105',
    BART_TEXT: 'Fette, pflanzliche - Herstellung und Verarbeitung (ohne Speisefett)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-C',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5217',
    BART_TEXT: 'Feuerungsbaubetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2108',
    BART_TEXT: 'Feuerverzinkerei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2109',
    BART_TEXT: 'Feuerwehr',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5093',
    BART_TEXT: 'Feuerwehrverein',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4731',
    BART_TEXT: 'Feuerwerker',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0340',
    BART_TEXT: 'Feuerwerksherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4732',
    BART_TEXT: 'Feuerzeughandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4733',
    BART_TEXT: 'Feuerzeughandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2111',
    BART_TEXT: 'Feuerzeugherstellung (Kunststoff)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4402',
    BART_TEXT: 'Film- und Fotogerätehandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4403',
    BART_TEXT: 'Film- und Fotogerätehandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4416',
    BART_TEXT: 'Filmapparatehandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4417',
    BART_TEXT: 'Filmapparatehandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2115',
    BART_TEXT: 'Filmapparatereparatur (ohne Handel)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2116',
    BART_TEXT: 'Filmatelier',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2117',
    BART_TEXT: 'Filmkopieranstalt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2118',
    BART_TEXT: 'Filmmaterialherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2119',
    BART_TEXT: 'Filmstudio',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2120',
    BART_TEXT: 'Filmtheater',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0342',
    BART_TEXT: 'Filmverleih',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4734',
    BART_TEXT: 'Filzwarenhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4735',
    BART_TEXT: 'Filzwarenhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5218',
    BART_TEXT: 'Finanzberatungsbüro',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2124',
    BART_TEXT: 'Fingernagelstudio',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2125',
    BART_TEXT: 'Firnisherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0344',
    BART_TEXT: 'Fischerei',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-C',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4736',
    BART_TEXT: 'Fischereibedarf-, Seilerwarenhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4737',
    BART_TEXT: 'Fischereibedarf-, Seilerwarenhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4738',
    BART_TEXT: 'Fischhandel - Einzelhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-C',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4739',
    BART_TEXT: 'Fischhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-C',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4202',
    BART_TEXT: 'Fischräucherei',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-C',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5219',
    BART_TEXT: 'Fischverarbeitungsbetrieb',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-C',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0348',
    BART_TEXT: 'Fischzucht',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0349',
    BART_TEXT: 'Fitnesscenter',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2130',
    BART_TEXT: 'Fitnessstudio',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2131',
    BART_TEXT: 'Flachglas - Herstellung und Verarbeitung',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5220',
    BART_TEXT: 'Flachsaufbereitungsbetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2133',
    BART_TEXT: 'Flachsspinnerei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2134',
    BART_TEXT: 'Flachsweberei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2136',
    BART_TEXT: 'Flaggenmacherei',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2137',
    BART_TEXT: 'Flaschnerei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2140',
    BART_TEXT: 'Fleischerei (mit Fleisch-, Wurstbe- und -verarbeitung)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-C',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2141',
    BART_TEXT: 'Fleischerei (ohne Fleisch-, Wurstbe- und -verarbeitung)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-C',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4742',
    BART_TEXT: 'Fleischereibedarfshandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4743',
    BART_TEXT: 'Fleischereibedarfshandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2143',
    BART_TEXT: 'Fleischkonservenherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4418',
    BART_TEXT: 'Fleischwarenhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4419',
    BART_TEXT: 'Fleischwarenhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-C',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2146',
    BART_TEXT: 'Fleischwarenherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-C',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5391',
    BART_TEXT: 'Fliegerkarussell, Kettenflieger mit Auf-/Abbewegung',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4420',
    BART_TEXT: 'Fliesenhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4421',
    BART_TEXT: 'Fliesenhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-G',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2148',
    BART_TEXT: 'Fliesenherstellung (Keramik)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-G',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2150',
    BART_TEXT: 'Fliesenlegerbetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5221',
    BART_TEXT: 'Fliesenlegerbetrieb und Handel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0357',
    BART_TEXT: 'Flohzirkus',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2151',
    BART_TEXT: 'Fluggesellschaft',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2152',
    BART_TEXT: 'Flughafenbetrieb',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2153',
    BART_TEXT: 'Flugplatz',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2154',
    BART_TEXT: 'Flugschule',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2156',
    BART_TEXT: 'Flugvermittlung',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5222',
    BART_TEXT: 'Flugzeugbaubetrieb (Kunststoff)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5223',
    BART_TEXT: 'Flugzeugbaubetrieb (Metall)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5224',
    BART_TEXT: 'Flugzeugbaubetrieb (Polyester, reaktionshärtende Kunststoffe)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5225',
    BART_TEXT: 'Flugzeuge - Reparatur - und Wartungsbetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4744',
    BART_TEXT: 'Folienhandel (Kunststoff) - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4745',
    BART_TEXT: 'Folienhandel (Kunststoff) - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2165',
    BART_TEXT: 'Folienherstellung (Kunststoff, ohne Schlauchfolien)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2166',
    BART_TEXT: 'Folienherstellung (Metall)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2167',
    BART_TEXT: 'Folienherstellung (Schlauchfolien)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2169',
    BART_TEXT: 'Fördertechnikherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2170',
    BART_TEXT: 'Forschungsinstitut (biologisch, biochemisch)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2171',
    BART_TEXT: 'Forschungsinstitut (chemisch, physikalisch)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2172',
    BART_TEXT: 'Forschungsinstitut (medizinisch)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2173',
    BART_TEXT: 'Forschungslabor (chemisch, physikalisch)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2174',
    BART_TEXT: 'Forschungslabor (medizinisch)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4204',
    BART_TEXT: 'Fotoapparatereparatur (ohne Handel)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4748',
    BART_TEXT: 'Fotoatelier',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2178',
    BART_TEXT: 'Fotobedarfherstellung (Filme, Papier, Platten)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2179',
    BART_TEXT: 'Fotochemischer Betrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4046',
    BART_TEXT: 'Fotogeschäft',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5377',
    BART_TEXT: 'Fotograf (freiberuflich)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4749',
    BART_TEXT: 'Fotohandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4750',
    BART_TEXT: 'Fotohandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2181',
    BART_TEXT: 'Fotokopierbetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4751',
    BART_TEXT: 'Fotokopiergerätehandel - Einzelhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-E',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4752',
    BART_TEXT: 'Fotokopiergerätehandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-E',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0368',
    BART_TEXT: 'Fotolabor',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2183',
    BART_TEXT: 'Fotosatzbetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2185',
    BART_TEXT: 'Frauenarzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2186',
    BART_TEXT: 'Frauenhaus',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0373',
    BART_TEXT: 'Freibad',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0374',
    BART_TEXT: 'Freiberufliche Künstler ohne handwerkliche Tätigkeit',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0375',
    BART_TEXT: 'Freiberufliche, betriebsärztliche Tätigkeit von Amtsärzten',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0376',
    BART_TEXT: 'Freiberufliche, betriebsärztliche Tätigkeit von Assistenzärzten ohne Facharztanerkennung',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2187',
    BART_TEXT: 'Freier Sachverständiger (Kfz)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2188',
    BART_TEXT: 'Freier Sachverständiger (ohne Kfz)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2189',
    BART_TEXT: 'Freilichtmuseum',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0379',
    BART_TEXT: 'Freilichttheater',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2190',
    BART_TEXT: 'Freiluftstation',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2191',
    BART_TEXT: 'Freiwillige Feuerwehr',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2192',
    BART_TEXT: 'Freizeitbad',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2193',
    BART_TEXT: 'Freizeitpark (stationäre Fahrgeschäfte)',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5152',
    BART_TEXT: 'Fremdenführer',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0381',
    BART_TEXT: 'Fremdenpension bis 8 Betten',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0331',
    BART_TEXT: 'Fremdenverkehrsbüro',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2195',
    BART_TEXT: 'Fremdenzimmer',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2196',
    BART_TEXT: 'Fremdsprachenkorrespondent',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0382',
    BART_TEXT: 'Freskenmaler',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0383',
    BART_TEXT: 'Friedhofsgärtnerei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0384',
    BART_TEXT: 'Frisch-, Fertig- und Transportbetonherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2197',
    BART_TEXT: 'Frischbetonwerk',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0385',
    BART_TEXT: 'Friseur',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4422',
    BART_TEXT: 'Früchtehandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4423',
    BART_TEXT: 'Früchtehandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2199',
    BART_TEXT: 'Fruchtsaftherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0386',
    BART_TEXT: 'Frühstückspension',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4424',
    BART_TEXT: 'Funkanlagenhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4425',
    BART_TEXT: 'Funkanlagenhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4426',
    BART_TEXT: 'Funktelefonhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4427',
    BART_TEXT: 'Funktelefonhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2204',
    BART_TEXT: 'Furnierherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4428',
    BART_TEXT: 'Fußbodenbelaghandel (mit Orientteppichen) - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4429',
    BART_TEXT: 'Fußbodenbelaghandel (mit Orientteppichen) - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4430',
    BART_TEXT: 'Fußbodenbelaghandel (ohne Orientteppiche) - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4431',
    BART_TEXT: 'Fußbodenbelaghandel (ohne Orientteppiche) - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2207',
    BART_TEXT: 'Fußbodenbelagherstellung (Holz)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2210',
    BART_TEXT: 'Fußbodenbelagherstellung (Kunststoff ungeschäumt)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2211',
    BART_TEXT: 'Fußbodenbelagherstellung (Textil)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2212',
    BART_TEXT: 'Fußbodenverlegebetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4206',
    BART_TEXT: 'Fußpflegestudio',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4753',
    BART_TEXT: 'Fußpflegestudio (medizinisch)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4272',
    BART_TEXT: 'Futtermittelhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4273',
    BART_TEXT: 'Futtermittelhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0393',
    BART_TEXT: 'Futtermittelherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5226',
    BART_TEXT: 'Galabaubetrieb und Winterdienst sowie Straßenreinigung',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2214',
    BART_TEXT: 'Galerie',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5378',
    BART_TEXT: 'Galvanisierbetrieb (Kunststoffe)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2216',
    BART_TEXT: 'Galvanisierbetrieb (Metalle)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2217',
    BART_TEXT: 'Garagen, -betrieb',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1201',
    BART_TEXT: 'Garderobenbetrieb, ständig bewacht',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0395',
    BART_TEXT: 'Garderobenverleih',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4432',
    BART_TEXT: 'Gardinenhandel mit Orientteppiche - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4433',
    BART_TEXT: 'Gardinenhandel mit Orientteppiche - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4434',
    BART_TEXT: 'Gardinenhandel ohne Orientteppiche - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4435',
    BART_TEXT: 'Gardinenhandel ohne Orientteppiche - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2220',
    BART_TEXT: 'Gardinenwäscherei, -spannerei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2221',
    BART_TEXT: 'Gardinenweberei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2222',
    BART_TEXT: 'Garnherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5227',
    BART_TEXT: 'Garnverarbeitungsbetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4047',
    BART_TEXT: 'Garten- und Landschaftsarchitekturbüro',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4109',
    BART_TEXT: 'Garten- und Landschaftsbau und Holzfäller',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5228',
    BART_TEXT: 'Garten- und Landschaftsbaubetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2225',
    BART_TEXT: 'Gartenarchitekt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4754',
    BART_TEXT: 'Gartenartikelhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4755',
    BART_TEXT: 'Gartenartikelhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2227',
    BART_TEXT: 'Gartenbaubetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2228',
    BART_TEXT: 'Gartencenter',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1494',
    BART_TEXT: 'Gartenhilfe, selbständig',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2229',
    BART_TEXT: 'Gärtnerei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2230',
    BART_TEXT: 'Gasanlageninstallationsbetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2231',
    BART_TEXT: 'Gaserzeugung und -verteilung (Stadt-, Erdgas)',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4756',
    BART_TEXT: 'Gashandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4757',
    BART_TEXT: 'Gashandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4758',
    BART_TEXT: 'Gashandel (Brenn-, Schweißgase) - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4759',
    BART_TEXT: 'Gashandel (Brenn-, Schweißgase) - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2233',
    BART_TEXT: 'Gasinstallationsbetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2234',
    BART_TEXT: 'Gasthof (mit Beherbergung)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2235',
    BART_TEXT: 'Gasthof (ohne Beherbergung)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0405',
    BART_TEXT: 'Gastroenterologe - Assistenz-Facharzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0406',
    BART_TEXT: 'Gastroenterologe - freie Praxis - ambulant/operativ',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0407',
    BART_TEXT: 'Gastroenterologe - freie Praxis - stationär bzw. Belegarzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0409',
    BART_TEXT: 'Gastroenterologe - stationär - Abteilungsleiter',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0410',
    BART_TEXT: 'Gastroenterologe - stationär - Chefarzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0411',
    BART_TEXT: 'Gastroenterologe - stationär - Oberarzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0412',
    BART_TEXT: 'Gastroenterologe - stationär - Vertretungsarzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5039',
    BART_TEXT: 'Gaststätte (keine Bar, Disco, Vergnügungsbetrieb - mit Beherbergung)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5462',
    BART_TEXT: 'Gaststätte (keine Bar, Disco, Vergnügungsbetrieb - ohne Beherbergung)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5041',
    BART_TEXT: 'Gastwirtschaft (mit Beherbergung)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5464',
    BART_TEXT: 'Gastwirtschaft (ohne Beherbergung)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0419',
    BART_TEXT: 'Gaswerk',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0422',
    BART_TEXT: 'Gebäudereinigungsbetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0423',
    BART_TEXT: 'Gebirgsverein',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4436',
    BART_TEXT: 'Gebrauchtspielwarenhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4437',
    BART_TEXT: 'Gebrauchtspielwarenhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4324',
    BART_TEXT: 'Gebrauchttextilienhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4325',
    BART_TEXT: 'Gebrauchttextilienhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4438',
    BART_TEXT: 'Gebrauchtwarenhandel (ohne Textil) - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4439',
    BART_TEXT: 'Gebrauchtwarenhandel (ohne Textil) - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4005',
    BART_TEXT: 'Geflügelfarm',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-C',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2240',
    BART_TEXT: 'Geflügelhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-C',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4137',
    BART_TEXT: 'Geigenbauer',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-G',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0428',
    BART_TEXT: 'Geisterbahn',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0430',
    BART_TEXT: 'Geldinstitut',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2242',
    BART_TEXT: 'Geldschrankherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4440',
    BART_TEXT: 'Gemäldehandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5466',
    BART_TEXT: 'Gemäldehandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2244',
    BART_TEXT: 'Gemeindepflegestation',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2245',
    BART_TEXT: 'Gemeindeverwaltung',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2246',
    BART_TEXT: 'Gemeinschaftswarenhaus',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2247',
    BART_TEXT: 'Gemischte Stoffe - Recycling (Trennung, Sortierung, Aufbereitung)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4442',
    BART_TEXT: 'Gemüsehandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4443',
    BART_TEXT: 'Gemüsehandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2249',
    BART_TEXT: 'Gemüseverarbeitung (Konserven, Tiefkühlkost)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0431',
    BART_TEXT: 'Generatorenherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0432',
    BART_TEXT: 'Genossenschaftsbank',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0433',
    BART_TEXT: 'Genussmittelgroßhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2250',
    BART_TEXT: 'Geometer',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5229',
    BART_TEXT: 'Geothermiebohrungsbetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0434',
    BART_TEXT: 'Gerberei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5153',
    BART_TEXT: 'Gerichtsvollzieher',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2251',
    BART_TEXT: 'Gerüstbaubetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4048',
    BART_TEXT: 'Gerüstverleih',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0438',
    BART_TEXT: 'Gesamtproduktherstellung Anlagen- und Kesselbau',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0439',
    BART_TEXT: 'Gesamtproduktherstellung Baumaschinen, Antriebs- und Fördertechnik',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0440',
    BART_TEXT: 'Gesamtproduktherstellung Installations- und Lufttechnik',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0441',
    BART_TEXT: 'Gesamtproduktherstellung Maschinenbau / Spezialmaschinenbau',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0442',
    BART_TEXT: 'Gesamtproduktherstellung Präzisions- und Automationstechnik',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0443',
    BART_TEXT: 'Gesamtproduktherstellung verfahrens- und prozesstechnische Anlagen',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0444',
    BART_TEXT: 'Gesangsschule',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5691',
    BART_TEXT: 'Gesangsverein',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2252',
    BART_TEXT: 'Geschäumte Kunststoffe - Herstellung - Kaltverarbeitung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2253',
    BART_TEXT: 'Geschäumte Kunststoffe - Herstellung - Warmverarbeitung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2254',
    BART_TEXT: 'Geschäumte Kunststoffe - Verarbeitung - Kaltverarbeitung',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2255',
    BART_TEXT: 'Geschäumte Kunststoffe - Verarbeitung - Warmverarbeitung',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2256',
    BART_TEXT: 'Geschenkartikelherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0446',
    BART_TEXT: 'Geselliger Verein',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2257',
    BART_TEXT: 'Gesenkschmiede',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2258',
    BART_TEXT: 'Gesteinsbearbeitung (nicht Edelstein)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5230',
    BART_TEXT: 'Gesteinsgewinnungsbetrieb (natürliche Gesteine)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2260',
    BART_TEXT: 'Gesteinsverarbeitung (nicht Edelstein)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0447',
    BART_TEXT: 'Gesundheits-, Körperpflegehandwerk',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5231',
    BART_TEXT: 'Gesundheitsberatungsbüro',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2261',
    BART_TEXT: 'Getränkeabfüllbetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4274',
    BART_TEXT: 'Getränkehandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4275',
    BART_TEXT: 'Getränkehandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-E',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4596',
    BART_TEXT: 'Getränkehandel und Schreibwaren - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4597',
    BART_TEXT: 'Getränkehandel und Schreibwaren - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-E',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2262',
    BART_TEXT: 'Getränkeherstellung (Alkohol)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2263',
    BART_TEXT: 'Getränkeherstellung (alkoholfreie)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2264',
    BART_TEXT: 'Getränkevertrieb',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-E',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4444',
    BART_TEXT: 'Getreidehandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4445',
    BART_TEXT: 'Getreidehandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2266',
    BART_TEXT: 'Getreidemühle',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2267',
    BART_TEXT: 'Getreidetrocknungsanlage',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5065',
    BART_TEXT: 'Gewehre-, Waffenhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4764',
    BART_TEXT: 'Gewehre-, Waffenhandel - Großhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2270',
    BART_TEXT: 'Gewerbliche Tierhaltung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4446',
    BART_TEXT: 'Gewürzhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4447',
    BART_TEXT: 'Gewürzhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2272',
    BART_TEXT: 'Gewürzherstellung (flüssig)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-C',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2273',
    BART_TEXT: 'Gewürzherstellung (Pulver)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-C',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2274',
    BART_TEXT: 'Gewürzmühle',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0451',
    BART_TEXT: 'Gießerei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2275',
    BART_TEXT: 'Gießproduktherstellung (Kunststoff)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2276',
    BART_TEXT: 'Gipserei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2277',
    BART_TEXT: 'Gipsherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2278',
    BART_TEXT: 'Gipswarenherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4207',
    BART_TEXT: 'Gitarrenbau',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-G',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2280',
    BART_TEXT: 'Glasapparatebau',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-G',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2281',
    BART_TEXT: 'Glasbläserei',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-G',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2282',
    BART_TEXT: 'Glaserei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2284',
    BART_TEXT: 'Glasfasern - Herstellung und Verarbeitung',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4448',
    BART_TEXT: 'Glashandel (Baustoff) - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4449',
    BART_TEXT: 'Glashandel (Baustoff) - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-G',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5232',
    BART_TEXT: 'Glashandwerksbetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2287',
    BART_TEXT: 'Glasherstellung (Flachglas)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-G',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2288',
    BART_TEXT: 'Glasherstellung (Hohlglas)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-G',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4049',
    BART_TEXT: 'Glasmalerei',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2289',
    BART_TEXT: 'Glaspapierherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2290',
    BART_TEXT: 'Glasrecycling',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2291',
    BART_TEXT: 'Glasreinigung (Gebäude)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4450',
    BART_TEXT: 'Glaswarenhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4451',
    BART_TEXT: 'Glaswarenhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-G',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2296',
    BART_TEXT: 'Glaswolle - Herstellung und Verarbeitung',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2297',
    BART_TEXT: 'Glätterei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5233',
    BART_TEXT: 'Gleisbaubetrieb',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0465',
    BART_TEXT: 'Glühlampenherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-G',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2302',
    BART_TEXT: 'Gokart-Bahn (stationär)',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0466',
    BART_TEXT: 'Gokart-Bahn mit Elektromotor',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5396',
    BART_TEXT: 'Gokart-Bahn mit Verbrennungsmotor',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0471',
    BART_TEXT: 'Golfclub',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2306',
    BART_TEXT: 'Grabmalherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0474',
    BART_TEXT: 'Grafikdesigner',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2308',
    BART_TEXT: 'Graphischer Betrieb mit Rotationstiefdruck',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2309',
    BART_TEXT: 'Graphischer Betrieb ohne Rotationstiefdruck',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4133',
    BART_TEXT: 'Gravierbetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4626',
    BART_TEXT: 'Grobkeramikherstellung zur industriellen und gewerblichen Nutzung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-G',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4627',
    BART_TEXT: 'Grobkeramikherstellung zur privaten Nutzung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-G',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2314',
    BART_TEXT: 'Grobkeramische Produkte - Herstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-G',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2316',
    BART_TEXT: 'Großbehälterherstellung (Gieß- oder Schleuderprodukte)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2318',
    BART_TEXT: 'Großbehälterherstellung (Holz)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2319',
    BART_TEXT: 'Großbehälterherstellung (Kunststoff, nicht Gieß- oder Schleuderprodukte)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2320',
    BART_TEXT: 'Großbehälterherstellung (Metall)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0478',
    BART_TEXT: 'Großbuchbinderei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5051',
    BART_TEXT: 'Großhandel für Einzelhändler',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0482',
    BART_TEXT: 'Großküche',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0483',
    BART_TEXT: 'Großmarkt',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0484',
    BART_TEXT: 'Großmaschinenherstellung (Elektrotechnik)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0485',
    BART_TEXT: 'Großschlachterei',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2322',
    BART_TEXT: 'Grundstücksmakler',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5234',
    BART_TEXT: 'Grundstücksverwaltungsbüro',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2324',
    BART_TEXT: 'Gummibandweberei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2325',
    BART_TEXT: 'Gummiherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5235',
    BART_TEXT: 'Gummiverarbeitungsbetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4770',
    BART_TEXT: 'Gummiwarenhandel (mit Schaumgummi) - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4771',
    BART_TEXT: 'Gummiwarenhandel (mit Schaumgummi) - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4772',
    BART_TEXT: 'Gummiwarenhandel (ohne Schaumgummi und ohne Reifen) - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4773',
    BART_TEXT: 'Gummiwarenhandel (ohne Schaumgummi und ohne Reifen) - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2329',
    BART_TEXT: 'Gummiwarenherstellung (ohne Schaumgummi)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2330',
    BART_TEXT: 'Gymnastikstudio',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0487',
    BART_TEXT: 'Gynäkologe mit Geburtshilfe - Assistenz-Facharzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0489',
    BART_TEXT: 'Gynäkologe mit Geburtshilfe - freie Praxis',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0490',
    BART_TEXT: 'Gynäkologe mit Geburtshilfe - stationär - Abteilungsleiter',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0491',
    BART_TEXT: 'Gynäkologe mit Geburtshilfe - stationär - Chefarzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0492',
    BART_TEXT: 'Gynäkologe mit Geburtshilfe - stationär - Oberarzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0493',
    BART_TEXT: 'Gynäkologe mit Geburtshilfe - stationär - Vertretungsarzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0494',
    BART_TEXT: 'Gynäkologe ohne Geburtshilfe - Assistenz-Facharzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0495',
    BART_TEXT: 'Gynäkologe ohne Geburtshilfe - freie Praxis - ambulant',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0496',
    BART_TEXT: 'Gynäkologe ohne Geburtshilfe - freie Praxis - ambulant/operativ',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0497',
    BART_TEXT: 'Gynäkologe ohne Geburtshilfe - freie Praxis - stationär bzw. Belegarzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0498',
    BART_TEXT: 'Gynäkologe ohne Geburtshilfe - stationär - Abteilungsleiter',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0499',
    BART_TEXT: 'Gynäkologe ohne Geburtshilfe - stationär - Chefarzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0500',
    BART_TEXT: 'Gynäkologe ohne Geburtshilfe - stationär - Oberarzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0501',
    BART_TEXT: 'Gynäkologe ohne Geburtshilfe - stationär - Vertretungsarzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2331',
    BART_TEXT: 'Haarsprayherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2332',
    BART_TEXT: 'Haarstudio',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4276',
    BART_TEXT: 'Haarteilehandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4277',
    BART_TEXT: 'Haarteilehandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2333',
    BART_TEXT: 'Haarteileherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2334',
    BART_TEXT: 'Hafenbetrieb (ohne Lager)',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2336',
    BART_TEXT: 'Hairstylist',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2338',
    BART_TEXT: 'Halbstoffherstellung (Holzfaser, Papier, Pappe, Strohfaser)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0504',
    BART_TEXT: 'Halfpipe',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0505',
    BART_TEXT: 'Hallenbad',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0506',
    BART_TEXT: 'Hals-, Nasen-, Ohrenarzt - Assistenz-Facharzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0507',
    BART_TEXT: 'Hals-, Nasen-, Ohrenarzt - freie Praxis - ambulant/operativ',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0508',
    BART_TEXT: 'Hals-, Nasen-, Ohrenarzt - freie Praxis - stationär bzw. Belegarzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0509',
    BART_TEXT: 'Hals-, Nasen-, Ohrenarzt - stationär - Abteilungsleiter',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0510',
    BART_TEXT: 'Hals-, Nasen-, Ohrenarzt - stationär - Chefarzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0511',
    BART_TEXT: 'Hals-, Nasen-, Ohrenarzt - stationär - Oberarzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0512',
    BART_TEXT: 'Hals-, Nasen-, Ohrenarzt - stationär - Vertretungsarzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4278',
    BART_TEXT: 'Handarbeitsbedarfshandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4279',
    BART_TEXT: 'Handarbeitsbedarfshandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4774',
    BART_TEXT: 'Handel mit feuergefährlichen Stoffen - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4775',
    BART_TEXT: 'Handel mit feuergefährlichen Stoffen - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4600',
    BART_TEXT: 'Handel und Elektrohandwerk - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4601',
    BART_TEXT: 'Handel und Elektrohandwerk - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0517',
    BART_TEXT: 'Handelsvertreter gemäß § 84 HGB ohne Auslieferungslager',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5236',
    BART_TEXT: 'Handyreparaturbetrieb (ohne Handel)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2342',
    BART_TEXT: 'Handyshop',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2345',
    BART_TEXT: 'Härterei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2346',
    BART_TEXT: 'Hartfaserplattenherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2350',
    BART_TEXT: 'Hauptberufliche Vertretung der Allianz Gruppe',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0518',
    BART_TEXT: 'Haus- und Grundbesitzerverein',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0521',
    BART_TEXT: 'Haus- und Heimtextilienherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4452',
    BART_TEXT: 'Haushaltsgerätehandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4453',
    BART_TEXT: 'Haushaltsgerätehandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0524',
    BART_TEXT: 'Haushaltsgeräteherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0522',
    BART_TEXT: 'haushaltsnahe Dienstleistungen, handwerklich',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0523',
    BART_TEXT: 'haushaltsnahe Dienstleistungen, nicht handwerklich',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4776',
    BART_TEXT: 'Haushaltswarenhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4777',
    BART_TEXT: 'Haushaltswarenhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5237',
    BART_TEXT: 'Hausmeisterservicebetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2355',
    BART_TEXT: 'Hausrat eingelagert',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2356',
    BART_TEXT: 'Hausrat in Musterwohnung',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4208',
    BART_TEXT: 'Hausrat in vermieteter Übergangsunterkunft',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2357',
    BART_TEXT: 'Hausrat in vermieteter Wohnung',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0527',
    BART_TEXT: 'Hausreinigung',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2358',
    BART_TEXT: 'Hausschlachterei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4454',
    BART_TEXT: 'Haustextilienhandel (mit Orientteppichen) - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4455',
    BART_TEXT: 'Haustextilienhandel (mit Orientteppichen) - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4456',
    BART_TEXT: 'Haustextilienhandel (ohne Orientteppiche) - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4457',
    BART_TEXT: 'Haustextilienhandel (ohne Orientteppiche) - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2361',
    BART_TEXT: 'Hausverwaltung',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4778',
    BART_TEXT: 'Häutehandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4779',
    BART_TEXT: 'Häutehandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2363',
    BART_TEXT: 'HDF-Plattenherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4006',
    BART_TEXT: 'Hebamme mit Geburtshilfe',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4007',
    BART_TEXT: 'Hebamme ohne Geburtshilfe',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0531',
    BART_TEXT: 'Heilbad',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2366',
    BART_TEXT: 'Heilgymnastiker',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5648',
    BART_TEXT: 'Heilpädagogik',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0532',
    BART_TEXT: 'Heilpraktiker',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5085',
    BART_TEXT: 'Heilpraktiker für Psychotherapie',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2367',
    BART_TEXT: 'Heim (außer Jugend-, Kur-, Tier-, Vereinsheim)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5695',
    BART_TEXT: 'Heimatverein',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4458',
    BART_TEXT: 'Heimtextilienhandel (mit Orientteppichen) - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4459',
    BART_TEXT: 'Heimtextilienhandel (mit Orientteppichen) - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4460',
    BART_TEXT: 'Heimtextilienhandel (ohne Orientteppiche) - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4461',
    BART_TEXT: 'Heimtextilienhandel (ohne Orientteppiche) - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4780',
    BART_TEXT: 'Heimwerkerbedarfshandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4781',
    BART_TEXT: 'Heimwerkerbedarfshandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-E',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2371',
    BART_TEXT: 'Heimwerkermarkt',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-E',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0535',
    BART_TEXT: 'Heißmangel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2372',
    BART_TEXT: 'Heizkesselherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2373',
    BART_TEXT: 'Heizkörperherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2374',
    BART_TEXT: 'Heizkraftwerk',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4782',
    BART_TEXT: 'Heizölhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4783',
    BART_TEXT: 'Heizölhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2376',
    BART_TEXT: 'Heizungsingenieur',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2377',
    BART_TEXT: 'Heizungsinstallationsbetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2378',
    BART_TEXT: 'Heizwerk',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2379',
    BART_TEXT: 'Hemdenfabrikation',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2380',
    BART_TEXT: 'Hemdengeschäft',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2381',
    BART_TEXT: 'Hemdenherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2382',
    BART_TEXT: 'Heraklitplattenherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2384',
    BART_TEXT: 'Herrenausstatter',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0537',
    BART_TEXT: 'Herstellung alkoholfreier Getränke',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0541',
    BART_TEXT: 'Herstellung von Prothesen, Implantate - auch Teile (z.B. Herzschrittmacher)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0545',
    BART_TEXT: 'Herstellung von sonstigen Diagnose-, Sterilisier- und Laborgeräten (auch Teile)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0546',
    BART_TEXT: 'Herstellung von therapeutische Geräte auch Teile (z.B. Dialyse, Druckkammern, OP-Kammern)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2385',
    BART_TEXT: 'Heutrocknungsanlage',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2386',
    BART_TEXT: 'Hobbymarkt',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-E',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0549',
    BART_TEXT: 'Hobelwerk',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4051',
    BART_TEXT: 'Hoch- und Tiefbaubetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4052',
    BART_TEXT: 'Hochbaubetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2388',
    BART_TEXT: 'Hochschule (öffentlich)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5470',
    BART_TEXT: 'Hochschule (privat)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2390',
    BART_TEXT: 'Hohlglas - Herstellung und Verarbeitung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-G',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0556',
    BART_TEXT: 'Hohlglasherstellung, -verarbeitung zur industriellen und gewerblichen Nutzung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-G',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0557',
    BART_TEXT: 'Hohlglasherstellung, -verarbeitung zur privaten Nutzung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-G',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2391',
    BART_TEXT: 'Holzbauelementeherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2392',
    BART_TEXT: 'Holzbearbeitendes Handwerk',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2393',
    BART_TEXT: 'Holzbearbeitungsbetrieb (nicht Sägewerk, nicht Holzmöbel)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5238',
    BART_TEXT: 'Holzbearbeitungsbetrieb, sonstige (nicht rein handwerklich)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2394',
    BART_TEXT: 'Holzbildhauerbetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4136',
    BART_TEXT: 'Holzblasinstrumentenbau',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-G',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0563',
    BART_TEXT: 'Holzfäller',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2396',
    BART_TEXT: 'Holzfaser - Halbstoffherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2397',
    BART_TEXT: 'Holzfaserherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2398',
    BART_TEXT: 'Holzfaserplatten und -spanplatten - Herstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2399',
    BART_TEXT: 'Holzfaserplattenherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2400',
    BART_TEXT: 'Holzgaskraftwerk',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4464',
    BART_TEXT: 'Holzhandel (mit Holzbearbeitung, nicht Sägewerk) - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4465',
    BART_TEXT: 'Holzhandel (mit Holzbearbeitung, nicht Sägewerk) - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4466',
    BART_TEXT: 'Holzhandel (ohne Bearbeitung) - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4467',
    BART_TEXT: 'Holzhandel (ohne Bearbeitung) - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5239',
    BART_TEXT: 'Holzimprägnierungsbetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2404',
    BART_TEXT: 'Holzkittherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2406',
    BART_TEXT: 'Holzmöbelherstellung (Serienfertigung)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2407',
    BART_TEXT: 'Holzpappeherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2408',
    BART_TEXT: 'Holzpelletherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0565',
    BART_TEXT: 'Holzrücker',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2410',
    BART_TEXT: 'Holzschliffherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2411',
    BART_TEXT: 'Holzschnitzerei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4087',
    BART_TEXT: 'Holzschuhmacher',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2413',
    BART_TEXT: 'Holzspanplattenherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2414',
    BART_TEXT: 'Holzstoffherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2415',
    BART_TEXT: 'Holzverarbeitendes Handwerk',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2416',
    BART_TEXT: 'Holzwolle - Herstellung und Verarbeitung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2417',
    BART_TEXT: 'Holzwolleplattenherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2418',
    BART_TEXT: 'Holzzement - Herstellung und Verarbeitung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5154',
    BART_TEXT: 'Home-Staging',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4280',
    BART_TEXT: 'Hopfenhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4281',
    BART_TEXT: 'Hopfenhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5046',
    BART_TEXT: 'Hörgeräteakustiker',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5043',
    BART_TEXT: 'Hörgerätehandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5354',
    BART_TEXT: 'Hörgerätehandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2420',
    BART_TEXT: 'Hörgeräteherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0570',
    BART_TEXT: 'Hotel Garni',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0571',
    BART_TEXT: 'Hotel mit Restaurant',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4787',
    BART_TEXT: 'Hotelbedarfshandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4788',
    BART_TEXT: 'Hotelbedarfshandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4791',
    BART_TEXT: 'Hoteleinrichtungshandel (ohne Orientteppiche) - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4792',
    BART_TEXT: 'Hoteleinrichtungshandel (ohne Orientteppiche) - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0575',
    BART_TEXT: 'Hufschmied',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0578',
    BART_TEXT: 'Humangenetiker - freie Praxis - ambulant/operativ',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0579',
    BART_TEXT: 'Humangenetiker - stationär - Abteilungsleiter',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0580',
    BART_TEXT: 'Humangenetiker - stationär - Chefarzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0581',
    BART_TEXT: 'Humangenetiker - stationär - Oberarzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0583',
    BART_TEXT: 'Hundedressur',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4468',
    BART_TEXT: 'Hundehandel - Einzelhandel',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4469',
    BART_TEXT: 'Hundehandel - Großhandel',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0584',
    BART_TEXT: 'Hundepension',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0585',
    BART_TEXT: 'Hundesalon',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0587',
    BART_TEXT: 'Hundezucht',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4326',
    BART_TEXT: 'Huthandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4327',
    BART_TEXT: 'Huthandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2424',
    BART_TEXT: 'Hutherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2425',
    BART_TEXT: 'Hütte (außer Eisen)',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2427',
    BART_TEXT: 'Hygienepapierherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2429',
    BART_TEXT: 'Imbiss (freistehend, jedoch ohne Imbiss-, Verkaufswagen)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2430',
    BART_TEXT: 'Imbiss (im Gebäude)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5473',
    BART_TEXT: 'Imbissstube (im Gebäude)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2432',
    BART_TEXT: 'Imbisswagen',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0593',
    BART_TEXT: 'Imkerei',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4056',
    BART_TEXT: 'Immobilienmakler',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2434',
    BART_TEXT: 'Imprägnierung (Textilien)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2436',
    BART_TEXT: 'Indigofarbstoffe - Verarbeitung (ohne Textilien)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2437',
    BART_TEXT: 'Indigofarbstoffe - Verarbeitung (Textilien)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2438',
    BART_TEXT: 'Indoorbahn',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2439',
    BART_TEXT: 'Industrieanstrichbetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5155',
    BART_TEXT: 'Industriekletterer',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5240',
    BART_TEXT: 'Industriereinigungsbetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5156',
    BART_TEXT: 'Influencer Social Media',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4630',
    BART_TEXT: 'Informationstechnik-Teileherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2441',
    BART_TEXT: 'Informationstechnologie-Unternehmen',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1513',
    BART_TEXT: 'Informationstechnologie-Unternehmen (reiner Handel)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0597',
    BART_TEXT: 'Informationstechnologie-Unternehmen (Soft-, Hardware, internetbezogene Dienstleistungen)',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2442',
    BART_TEXT: 'Ingenieur (außer Umwelt)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2443',
    BART_TEXT: 'Ingenieur (Umwelt)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4057',
    BART_TEXT: 'Ingenieur für Erd- und Grundbau, Bodenmechanik, Bodengutachter',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4008',
    BART_TEXT: 'Ingenieur für Haustechnik (Heizung, Sanitär, Elektrohaustechnik - ohne Klima, Fotovoltaik)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4058',
    BART_TEXT: 'Ingenieur für Klima, Lüftung, technische Gebäudeausrüstung',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4009',
    BART_TEXT: 'Ingenieur für Maschinenbau',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4059',
    BART_TEXT: 'Ingenieur für Stadt-, Regional- und Raumplanung',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4010',
    BART_TEXT: 'Ingenieur für Stadt-, Regional- und Raumplanung; Sachverständiger für Bauschäden',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4011',
    BART_TEXT: 'Ingenieur für Straßenbau',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4060',
    BART_TEXT: 'Ingenieur für thermische Bauphysik, Schallschutz und Raumakustik',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4061',
    BART_TEXT: 'Ingenieur für Verkehrsanlagen, Sicherungs- und Signaltechnik',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4012',
    BART_TEXT: 'Ingenieur für Verkehrsanlagen, Sicherungs- und Signaltechnik (Steuerungstechnik), Straßenbau',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4013',
    BART_TEXT: 'Ingenieur für Verkehrsanlagen, Sicherungs- und Signaltechnik , Straßenbau',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0607',
    BART_TEXT: 'Ingenieur ohne Objektschadendeckung',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4062',
    BART_TEXT: 'Ingenieure/Architekten für Umwelttechnik',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2444',
    BART_TEXT: 'Inkassobüro',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0609',
    BART_TEXT: 'Inlinebahn',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4063',
    BART_TEXT: 'Innenarchitekturbüro',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5241',
    BART_TEXT: 'Insolvenzverwaltungsbüro',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0611',
    BART_TEXT: 'Intensiv-Tierhaltung (sonstige)',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0612',
    BART_TEXT: 'Internat (öffentlich)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0613',
    BART_TEXT: 'Internat (privat)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0614',
    BART_TEXT: 'Internet-Café',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2446',
    BART_TEXT: 'Internetprovider',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4014',
    BART_TEXT: 'Internist - freie Praxis - stationär bzw. Belegarzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS7824',
    BART_TEXT: 'Internist (mit Kardiologie) - stationär - Vertretungsarzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0620',
    BART_TEXT: 'Internist (ohne Kardiologie) - Assistenz-Facharzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0621',
    BART_TEXT: 'Internist (ohne Kardiologie) - freie Praxis - ambulant/operativ',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0622',
    BART_TEXT: 'Internist (ohne Kardiologie) - freie Praxis - stationär bzw. Belegarzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0623',
    BART_TEXT: 'Internist (ohne Kardiologie) - stationär - Abteilungsleiter',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0624',
    BART_TEXT: 'Internist (ohne Kardiologie) - stationär - Chefarzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0625',
    BART_TEXT: 'Internist (ohne Kardiologie) - stationär - Oberarzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0626',
    BART_TEXT: 'Internist (ohne Kardiologie) - stationär - Vertretungsarzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2447',
    BART_TEXT: 'Isolier- und Bautenschutzbetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2448',
    BART_TEXT: 'Isolierbandherstellung (Gummi)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2450',
    BART_TEXT: 'Isolierbetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2451',
    BART_TEXT: 'Isolierwarenherstellung (Gummi)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2452',
    BART_TEXT: 'Isolierwarenherstellung (Kunststoff)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2453',
    BART_TEXT: 'Jachtbau (Holz)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2454',
    BART_TEXT: 'Jachtbau (Kunststoff, ohne Polyester)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2455',
    BART_TEXT: 'Jachtbau (Metall)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2456',
    BART_TEXT: 'Jachtbau (Polyester, reaktionshärtende Kunststoffe)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4793',
    BART_TEXT: 'Jagdausrüstungshandel (mit Waffen) - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5355',
    BART_TEXT: 'Jagdausrüstungshandel (mit Waffen) - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4795',
    BART_TEXT: 'Jagdausrüstungshandel (ohne Waffen) - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4796',
    BART_TEXT: 'Jagdausrüstungshandel (ohne Waffen) - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4797',
    BART_TEXT: 'Jalousienhandel (einschließlich Einbau) - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4798',
    BART_TEXT: 'Jalousienhandel (einschließlich Einbau) - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2461',
    BART_TEXT: 'Jalousienherstellung (Kunststoff)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2462',
    BART_TEXT: 'Jalousienherstellung (Metall)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2463',
    BART_TEXT: 'Jeansgeschäft',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0630',
    BART_TEXT: 'Journalist (freiberuflich)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0631',
    BART_TEXT: 'Jugendheim',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2464',
    BART_TEXT: 'Jugendherberge',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5356',
    BART_TEXT: 'Jugendzentrum',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2466',
    BART_TEXT: 'Juwelierwarenhandel (nur Betriebseinrichtung)',
    'BART-Gruppe': 'VAL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'VAL-A'
  },
  {
    HGVBETRART: 'FHS5697',
    BART_TEXT: 'Juwelierwarenherstellung (nur Betriebseinrichtung)',
    'BART-Gruppe': 'VAL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'VAL-A'
  },
  {
    HGVBETRART: 'FHS2469',
    BART_TEXT: 'Kabarett (Kleinkunstbühne)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5357',
    BART_TEXT: 'Kabarett (Nachtlokal)',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0636',
    BART_TEXT: 'Kabelherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0637',
    BART_TEXT: 'Kabinenseilbahn',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2471',
    BART_TEXT: 'Kachelherstellung (Keramik)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-G',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2472',
    BART_TEXT: 'Kachelofenbau',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4474',
    BART_TEXT: 'Kaffeehandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4475',
    BART_TEXT: 'Kaffeehandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2475',
    BART_TEXT: 'Kaffeeherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0639',
    BART_TEXT: 'Kaffeerösterei',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2476',
    BART_TEXT: 'Kälber-/Schweinemästerei',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2478',
    BART_TEXT: 'Kalikopapierherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2480',
    BART_TEXT: 'Kalksandsteinherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2481',
    BART_TEXT: 'Kältetechnikbetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0644',
    BART_TEXT: 'Kamerafrau/mann (freiberuflich)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4800',
    BART_TEXT: 'Kaminhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4801',
    BART_TEXT: 'Kaminhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0645',
    BART_TEXT: 'Kaminkehrer',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4802',
    BART_TEXT: 'Kaminofenhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4803',
    BART_TEXT: 'Kaminofenhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4095',
    BART_TEXT: 'Kammer (berufsständische Körperschaft)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0646',
    BART_TEXT: 'Kammerjäger',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2485',
    BART_TEXT: 'Kammgarn - Ausrüstung und Veredelung',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2486',
    BART_TEXT: 'Kammgarnspinnerei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2487',
    BART_TEXT: 'Kammgarnweberei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2488',
    BART_TEXT: 'Kampfsportschule',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2489',
    BART_TEXT: 'Kanalbaubetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2490',
    BART_TEXT: 'Kanalisationsanlagenbau',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2491',
    BART_TEXT: 'Kanalreinigungsbetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0647',
    BART_TEXT: 'Kantine',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2493',
    BART_TEXT: 'Kapelle (Kirche)',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0649',
    BART_TEXT: 'Kardiologe - Assistenz-Facharzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0651',
    BART_TEXT: 'Kardiologe - freie Praxis - ambulant/operativ',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4064',
    BART_TEXT: 'Kardiologe - freie Praxis - stationär bzw. Belegarzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0653',
    BART_TEXT: 'Kardiologe - stationär - Abteilungsleiter',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0654',
    BART_TEXT: 'Kardiologe - stationär - Chefarzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0655',
    BART_TEXT: 'Kardiologe - stationär - Oberarzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0656',
    BART_TEXT: 'Kardiologe - stationär - Vertretungsarzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4804',
    BART_TEXT: 'Karnevalsartikelhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4805',
    BART_TEXT: 'Karnevalsartikelhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0657',
    BART_TEXT: 'Karnevalsverein',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5242',
    BART_TEXT: 'Karosseriebaubetrieb (Kunststoff, ohne Polyester)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5243',
    BART_TEXT: 'Karosseriebaubetrieb (Metall)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5244',
    BART_TEXT: 'Karosseriebaubetrieb (Polyester, reaktionshärtende Kunststoffe)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2499',
    BART_TEXT: 'Kartbahn (stationär)',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0659',
    BART_TEXT: 'Kartenservice, Ticket-Hotline',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2500',
    BART_TEXT: 'Kartenvorverkaufsstelle',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2501',
    BART_TEXT: 'Karthographische Anstalt',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0660',
    BART_TEXT: 'Kartoffelerzeugnisherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-C',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2505',
    BART_TEXT: 'Kartographischer Betrieb - mit Rotationstiefdruck',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2506',
    BART_TEXT: 'Kartographischer Betrieb ohne Rotationstiefdruck',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0662',
    BART_TEXT: 'Kartonagenherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5245',
    BART_TEXT: 'Kartonagenverarbeitungsbetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2508',
    BART_TEXT: 'Kartonherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2509',
    BART_TEXT: 'Kartonverarbeitung',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2510',
    BART_TEXT: 'Karussell für Kleinkinder',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2511',
    BART_TEXT: 'Käseladen',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0663',
    BART_TEXT: 'Käserei',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0664',
    BART_TEXT: 'Kasperltheater',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2513',
    BART_TEXT: 'Katzenpension',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0665',
    BART_TEXT: 'Kaufhaus',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2515',
    BART_TEXT: 'Kautschukherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2516',
    BART_TEXT: 'Kegelsportcenter',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5379',
    BART_TEXT: 'Kegelverein',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2518',
    BART_TEXT: 'Kellerei',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2519',
    BART_TEXT: 'Kellertheater',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2520',
    BART_TEXT: 'Kelterei',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5157',
    BART_TEXT: 'Keramikhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5158',
    BART_TEXT: 'Keramikhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-G',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5246',
    BART_TEXT: 'Keramikhandwerksbetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2521',
    BART_TEXT: 'Keramikherstellung (Feinkeramik)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-G',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2523',
    BART_TEXT: 'Kerzenherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0670',
    BART_TEXT: 'Kessel - und Tankauskleidungsbetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0671',
    BART_TEXT: 'Kessel- und Tankentrostungsbetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2524',
    BART_TEXT: 'Kessel-, Maschinenhaus',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2525',
    BART_TEXT: 'Kesselbau',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-C',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2526',
    BART_TEXT: 'Kesselhaus',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2527',
    BART_TEXT: 'Kesselreinigung',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2528',
    BART_TEXT: 'Kesselreparatur',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0675',
    BART_TEXT: 'Kfz-Abschleppdienst',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4163',
    BART_TEXT: 'Kfz-Beklebung/-Beschriftung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS7816',
    BART_TEXT: 'Kfz-Dienstleister',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2529',
    BART_TEXT: 'Kfz-Elektrikbetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0678',
    BART_TEXT: 'Kfz-Elektrik-Herstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2530',
    BART_TEXT: 'Kfz-Flaschnerei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2531',
    BART_TEXT: 'Kfz-Garagen, -betrieb (ohne Tankstelle und ohne Reparatur)',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2532',
    BART_TEXT: 'Kfz-Glaserei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4806',
    BART_TEXT: 'Kfz-Handel (mit Reparatur) - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5358',
    BART_TEXT: 'Kfz-Handel (mit Reparatur) - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4808',
    BART_TEXT: 'Kfz-Handel (ohne Reparatur) - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5066',
    BART_TEXT: 'Kfz-Handel (ohne Reparatur) - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2535',
    BART_TEXT: 'Kfz-Herstellung (Kunststoff, ohne Polyester)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2536',
    BART_TEXT: 'Kfz-Herstellung (Metall)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2537',
    BART_TEXT: 'Kfz-Herstellung (Polyester, reaktionshärtende Kunststoffe)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0680',
    BART_TEXT: 'Kfz-Komponentenherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2538',
    BART_TEXT: 'Kfz-Lackiererei',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2539',
    BART_TEXT: 'Kfz-Pflegebetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4810',
    BART_TEXT: 'Kfz-Radiohandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5067',
    BART_TEXT: 'Kfz-Radiohandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4478',
    BART_TEXT: 'Kfz-Reifen-, Felgenhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4479',
    BART_TEXT: 'Kfz-Reifen-, Felgenhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2542',
    BART_TEXT: 'Kfz-Reifenverwertung',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4480',
    BART_TEXT: 'Kfz-Reparaturwerkstatt (mit Handel) - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4481',
    BART_TEXT: 'Kfz-Reparaturwerkstatt (mit Handel) - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2543',
    BART_TEXT: 'Kfz-Reparaturwerkstatt (ohne Handel)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0685',
    BART_TEXT: 'Kfz-Sachverständiger',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2545',
    BART_TEXT: 'Kfz-Sattlerei',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4164',
    BART_TEXT: 'Kfz-Scheibentönung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4165',
    BART_TEXT: 'Kfz-Sonnen-/Schiebedacheinbau',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2546',
    BART_TEXT: 'Kfz-Spenglerei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0686',
    BART_TEXT: 'Kfz-Teileherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2547',
    BART_TEXT: 'Kfz-Verleih (Büro)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2548',
    BART_TEXT: 'Kfz-Verwertung',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2549',
    BART_TEXT: 'Kfz-Verwertung (Demontage)',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2550',
    BART_TEXT: 'Kfz-Waschanlage',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4812',
    BART_TEXT: 'Kfz-Zubehörhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5068',
    BART_TEXT: 'Kfz-Zubehörhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2552',
    BART_TEXT: 'Kieferchirurg',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2553',
    BART_TEXT: 'Kieferorthopäde',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0691',
    BART_TEXT: 'Kieferorthopäde - Assistenz-Facharzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0692',
    BART_TEXT: 'Kieferorthopäde - freie Praxis - ambulant/operativ',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0693',
    BART_TEXT: 'Kieferorthopäde - freie Praxis - stationär bzw. Belegarzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0694',
    BART_TEXT: 'Kieferorthopäde - stationär - Abteilungsleiter',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0695',
    BART_TEXT: 'Kieferorthopäde - stationär - Chefarzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0696',
    BART_TEXT: 'Kieferorthopäde - stationär - Oberarzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS7825',
    BART_TEXT: 'Kieferorthopäde - stationär - Vertretungsarzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2554',
    BART_TEXT: 'Kiesgewinnung',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2555',
    BART_TEXT: 'Kiesgrube',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0698',
    BART_TEXT: 'Kieswerk',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0699',
    BART_TEXT: 'Kinderarzt - Assistenz-Facharzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0700',
    BART_TEXT: 'Kinderarzt - freie Praxis - ambulant/operativ',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0701',
    BART_TEXT: 'Kinderarzt - freie Praxis - stationär bzw. Belegarzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0702',
    BART_TEXT: 'Kinderarzt - stationär - Abteilungsleiter',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0703',
    BART_TEXT: 'Kinderarzt - stationär - Chefarzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0704',
    BART_TEXT: 'Kinderarzt - stationär - Oberarzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0705',
    BART_TEXT: 'Kinderarzt - stationär - Vertretungsarzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4482',
    BART_TEXT: 'Kinderbekleidungshandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4483',
    BART_TEXT: 'Kinderbekleidungshandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0706',
    BART_TEXT: 'Kindergarten (öffentlich)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0707',
    BART_TEXT: 'Kindergarten (privat)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0708',
    BART_TEXT: 'Kinderheim',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2558',
    BART_TEXT: 'Kinderheim (nicht Jugendheim)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2559',
    BART_TEXT: 'Kinderhort',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2560',
    BART_TEXT: 'Kindermodengeschäft',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2561',
    BART_TEXT: 'Kinderoberbekleidungsgeschäft',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2562',
    BART_TEXT: 'Kindertagesstätte',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2563',
    BART_TEXT: 'Kinesiologe',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0709',
    BART_TEXT: 'Kino',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2564',
    BART_TEXT: 'Kinocenter',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2565',
    BART_TEXT: 'Kiosk (freistehend, jedoch ohne Verkaufswagen)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2566',
    BART_TEXT: 'Kiosk (im Gebäude)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0711',
    BART_TEXT: 'Kirche',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2567',
    BART_TEXT: 'Kirchenbeamter',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0712',
    BART_TEXT: 'Kirchengemeinde',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0713',
    BART_TEXT: 'Kirchenmaler',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2568',
    BART_TEXT: 'Kitt - Herstellung und Verarbeitung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2569',
    BART_TEXT: 'Kläranlage',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2570',
    BART_TEXT: 'Klärwerk',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0714',
    BART_TEXT: 'Klauenschneider',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5247',
    BART_TEXT: 'Klavierbaubetrieb',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-G',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4815',
    BART_TEXT: 'Klavierhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4816',
    BART_TEXT: 'Klavierhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-G',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2573',
    BART_TEXT: 'Klebebandherstellung (Gummi)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2574',
    BART_TEXT: 'Klebebandherstellung (Kunststoff)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2575',
    BART_TEXT: 'Klebelack - Herstellung und Verarbeitung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0715',
    BART_TEXT: 'Klebstoffherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0716',
    BART_TEXT: 'Kleiderfabrik',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2578',
    BART_TEXT: 'Kleiderherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2579',
    BART_TEXT: 'kleine ländliche Brennerei',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2580',
    BART_TEXT: 'kleine ländliche Obstkelterei',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0717',
    BART_TEXT: 'Kleingartenverein',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2581',
    BART_TEXT: 'Kleinkunstbühne',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2582',
    BART_TEXT: 'Kleinmaschinenherstellung (elektrische)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0718',
    BART_TEXT: 'Kleintierverein',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0719',
    BART_TEXT: 'Kleinzoo',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2583',
    BART_TEXT: 'Klempnerei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2584',
    BART_TEXT: 'Klimaanlageninstallation',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2585',
    BART_TEXT: 'Klimaanlageninstallationsbetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2586',
    BART_TEXT: 'Klimageräteherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2587',
    BART_TEXT: 'Klinik',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2588',
    BART_TEXT: 'Klischeeherstellung',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2590',
    BART_TEXT: 'Kneipe',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4817',
    BART_TEXT: 'Kohlenhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4818',
    BART_TEXT: 'Kohlenhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5248',
    BART_TEXT: 'Kohlensäuregewinnungsbetrieb (aus natürlichen Vorkommnissen)',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2597',
    BART_TEXT: 'Kohlepapierherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2598',
    BART_TEXT: 'Kombinationslackherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2599',
    BART_TEXT: 'Kommunale Einrichtung',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2600',
    BART_TEXT: 'Kommunalverwaltung',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5249',
    BART_TEXT: 'Kommunikationselektronikreparaturbetrieb (ohne Handel)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4819',
    BART_TEXT: 'Kommunikationsgerätehandel (ohne Handyhandel) - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4820',
    BART_TEXT: 'Kommunikationsgerätehandel (ohne Handyhandel) - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2603',
    BART_TEXT: 'Kommunikationsgeräteherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4821',
    BART_TEXT: 'Kommunikationstechnikhandel (ohne Handyhandel) - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4822',
    BART_TEXT: 'Kommunikationstechnikhandel (ohne Handyhandel) - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2605',
    BART_TEXT: 'Kompostierungsanlage',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2606',
    BART_TEXT: 'Kompostwerk',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2608',
    BART_TEXT: 'Konditorei (mit Backwarenherstellung)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4484',
    BART_TEXT: 'Konditorei (ohne Backwarenherstellung)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4823',
    BART_TEXT: 'Konditoreibedarfshandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4824',
    BART_TEXT: 'Konditoreibedarfshandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4485',
    BART_TEXT: 'Konditorwarenhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4486',
    BART_TEXT: 'Konditorwarenhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2613',
    BART_TEXT: 'Konfektionsfertigung (mit Pelzwaren)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2614',
    BART_TEXT: 'Konfektionsfertigung (ohne Pelz, Leder, Alcantara)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2615',
    BART_TEXT: 'Konfektionsgeschäft (mit Pelzwaren)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2616',
    BART_TEXT: 'Konfektionsgeschäft (ohne Pelz-, Leder-, Alcantarawaren)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2617',
    BART_TEXT: 'Konfektionsgeschäft (ohne Pelzwaren, mit Leder-, Alcantarawaren)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4487',
    BART_TEXT: 'Konfiserie (Handel) - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4488',
    BART_TEXT: 'Konfiserie (Handel) - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4632',
    BART_TEXT: 'Kongresse, Tagungen, öffentliche Tanzveranstaltungen, Konzerte',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2620',
    BART_TEXT: 'Kongresshalle',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2622',
    BART_TEXT: 'Konservenherstellung (Fleisch, Wurst, Fisch)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2623',
    BART_TEXT: 'Konservenherstellung (Obst, Gemüse)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2624',
    BART_TEXT: 'Konsulat',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2625',
    BART_TEXT: 'Kontaktlinsenherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2626',
    BART_TEXT: 'Kontaktlinsenstudio',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2627',
    BART_TEXT: 'Konzerthalle',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2629',
    BART_TEXT: 'Kopieranstalt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2630',
    BART_TEXT: 'Korbmacherei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4826',
    BART_TEXT: 'Korbwarenhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4827',
    BART_TEXT: 'Korbwarenhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0730',
    BART_TEXT: 'Korbwarenherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1525',
    BART_TEXT: 'Korkwarenherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2632',
    BART_TEXT: 'Korrespondenzbüro',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2634',
    BART_TEXT: 'Kosmetikaherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0734',
    BART_TEXT: 'Kosmetiker, staatlich geprüft, freiberuflich',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4828',
    BART_TEXT: 'Kosmetikhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4829',
    BART_TEXT: 'Kosmetikhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0735',
    BART_TEXT: 'Kosmetikherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2636',
    BART_TEXT: 'Kosmetiksalon - Handel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5250',
    BART_TEXT: 'Kosmetiksalon - Handwerk, staatlich geprüft',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2638',
    BART_TEXT: 'Kosmetikschule (öffentlich)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5484',
    BART_TEXT: 'Kosmetikschule (privat)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0736',
    BART_TEXT: 'Kostümverleih',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2640',
    BART_TEXT: 'Kraftfutterherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0739',
    BART_TEXT: 'Kraftfuttermittelherstellung (Austauschfutter - Magermilchbasis)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0741',
    BART_TEXT: 'Kraftfuttermittelherstellung (sonstige)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2641',
    BART_TEXT: 'Kraftstation, elektrische',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2643',
    BART_TEXT: 'Kraftwerk (mit Wasserantrieb)',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0742',
    BART_TEXT: 'Krankengymnast, freiberuflich, staatlich geprüft',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2645',
    BART_TEXT: 'Krankenhaus',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0743',
    BART_TEXT: 'Krankenhaus - Augenheilkunde',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0745',
    BART_TEXT: 'Krankenhaus - Chirurgie',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0746',
    BART_TEXT: 'Krankenhaus - Dermatologie',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0747',
    BART_TEXT: 'Krankenhaus - Frauenheilkunde und Gynäkologie mit Geburtshilfe',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0748',
    BART_TEXT: 'Krankenhaus - Frauenheilkunde und Gynäkologie ohne Geburtshilfe',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1542',
    BART_TEXT: 'Krankenhaus - Geriatrie',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0749',
    BART_TEXT: 'Krankenhaus - Hals-Nasen-Ohren',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0750',
    BART_TEXT: 'Krankenhaus - Innere Medizin I',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0751',
    BART_TEXT: 'Krankenhaus - Innere Medizin II',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0752',
    BART_TEXT: 'Krankenhaus - Intensivmedizin mit Anästhesie',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1540',
    BART_TEXT: 'Krankenhaus - Kinderheilkunde einschließlich Onkologie mit Neonatologie',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1541',
    BART_TEXT: 'Krankenhaus - Kinderheilkunde einschließlich Onkologie ohne Neonatologie',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0754',
    BART_TEXT: 'Krankenhaus - Laboratoriumsmedizin',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0755',
    BART_TEXT: 'Krankenhaus - Neurologie',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0756',
    BART_TEXT: 'Krankenhaus - Orthopädie mit Rheumatologie',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0757',
    BART_TEXT: 'Krankenhaus - Psychiatrie',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0758',
    BART_TEXT: 'Krankenhaus - Radiologie, Strahlentherapie, Nuklearmedizin',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0759',
    BART_TEXT: 'Krankenhaus - sonstige Risiken',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0760',
    BART_TEXT: 'Krankenhaus - Urologie',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0761',
    BART_TEXT: 'Krankenhaus - Zahnheilkunde',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4159',
    BART_TEXT: 'Krankenhausverbund',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2646',
    BART_TEXT: 'Krankenkasse',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2647',
    BART_TEXT: 'Krankenpflegedienst',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2648',
    BART_TEXT: 'Krankenpfleger/-schwester, freiberuflich, staatlich geprüft',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5159',
    BART_TEXT: 'Krankentransporte',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4491',
    BART_TEXT: 'Kräuterhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4492',
    BART_TEXT: 'Kräuterhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0764',
    BART_TEXT: 'Kreditinstitut',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5251',
    BART_TEXT: 'Kreditvermittlungsbüro',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2654',
    BART_TEXT: 'Kreidepigmente, künstliche - Herstellung und Verarbeitung (Chemie)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4830',
    BART_TEXT: 'Kristallwarenhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4831',
    BART_TEXT: 'Kristallwarenhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-G',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2658',
    BART_TEXT: 'Küchenbetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4610',
    BART_TEXT: 'Küchenhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4611',
    BART_TEXT: 'Küchenhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5048',
    BART_TEXT: 'Küchenmonteur - mit Installationsarbeiten',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5050',
    BART_TEXT: 'Küchenmonteur - ohne Installationsarbeiten',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4015',
    BART_TEXT: 'Küchenstudio',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2659',
    BART_TEXT: 'Kugellagerherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2660',
    BART_TEXT: 'Kühlanlageninstallation',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0769',
    BART_TEXT: 'Kühlhausbetrieb',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2661',
    BART_TEXT: 'Kühlthekenherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5128',
    BART_TEXT: 'Kulturzentrum',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2663',
    BART_TEXT: 'Kulturzentrum (nicht religiös)',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2664',
    BART_TEXT: 'Kulturzentrum (religiös)',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0770',
    BART_TEXT: 'Kunstausstellung',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2666',
    BART_TEXT: 'Kunstfaserherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4282',
    BART_TEXT: 'Kunstgewerbehandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4283',
    BART_TEXT: 'Kunstgewerbehandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2667',
    BART_TEXT: 'Kunstgewerblicher Betrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4493',
    BART_TEXT: 'Kunsthandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5486',
    BART_TEXT: 'Kunsthandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5252',
    BART_TEXT: 'Kunstharze - Be- und Verarbeitungsbetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2670',
    BART_TEXT: 'Kunstharzlackherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2673',
    BART_TEXT: 'Kunsthonigherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2674',
    BART_TEXT: 'Kunstkeramikherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-G',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2675',
    BART_TEXT: 'Kunstleder - Herstellung und Verarbeitung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2676',
    BART_TEXT: 'Kunstlederhandwerk',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2677',
    BART_TEXT: 'Künstler, freiberuflich, nicht handwerklich tätig',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2678',
    BART_TEXT: 'Künstleragentur',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1543',
    BART_TEXT: 'Künstleratelier',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2679',
    BART_TEXT: 'Kunstmaleratelier',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2680',
    BART_TEXT: 'Kunstsammlung',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2681',
    BART_TEXT: 'Kunstschlosserei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2682',
    BART_TEXT: 'Kunstschmiede',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2686',
    BART_TEXT: 'Kunststoffbearbeitendes Handwerk',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0783',
    BART_TEXT: 'Kunststoffherstellung / Herstellung chemischer Grundstoffe',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2687',
    BART_TEXT: 'Kunststoffrecycling',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2688',
    BART_TEXT: 'Kunststoffspritzerei',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2689',
    BART_TEXT: 'Kunststoffverarbeitendes Handwerk',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5253',
    BART_TEXT: 'Kunststoffverarbeitungsbetrieb (mit Schaumstoffen) - Kaltverarbeitung',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5254',
    BART_TEXT: 'Kunststoffverarbeitungsbetrieb (mit Schaumstoffen) - Warmverarbeitung',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5255',
    BART_TEXT: 'Kunststoffverarbeitungsbetrieb (ohne Schaumstoffe, ohne Polyester)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5256',
    BART_TEXT: 'Kunststoffverarbeitungsbetrieb (Polyester, ohne Schaumstoffe)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4833',
    BART_TEXT: 'Kunststoffwarenhandel (mit Schaumstoffen) - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4834',
    BART_TEXT: 'Kunststoffwarenhandel (mit Schaumstoffen) - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4835',
    BART_TEXT: 'Kunststoffwarenhandel (ohne Schaumstoffe) - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4836',
    BART_TEXT: 'Kunststoffwarenhandel (ohne Schaumstoffe) - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2696',
    BART_TEXT: 'Kunststopferei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2698',
    BART_TEXT: 'Kupferschmiede',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4154',
    BART_TEXT: 'Kur- /Rehaklinik',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0789',
    BART_TEXT: 'Kurheim ohne eigene ärztliche Betreuung',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4155',
    BART_TEXT: 'Kurklinik',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0790',
    BART_TEXT: 'Kürschnerei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5257',
    BART_TEXT: 'Kürschnerei mit Pelzwarenhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0791',
    BART_TEXT: 'Kurzwarengeschäft',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2704',
    BART_TEXT: 'Labor (biologisch, biochemisch)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2705',
    BART_TEXT: 'Labor (chemisch)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2706',
    BART_TEXT: 'Labor (Dental)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2707',
    BART_TEXT: 'Labor (Foto)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2708',
    BART_TEXT: 'Labor (medizinisch)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2709',
    BART_TEXT: 'Labor (pharmazeutisch)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2710',
    BART_TEXT: 'Labor (physikalisch)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5160',
    BART_TEXT: 'Laborbedarfshandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5161',
    BART_TEXT: 'Laborbedarfshandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0793',
    BART_TEXT: 'Laborbetrieb - Apotheker',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0794',
    BART_TEXT: 'Laborbetrieb - Ärzte',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0795',
    BART_TEXT: 'Laborbetrieb - Biologe',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0796',
    BART_TEXT: 'Laborbetrieb - Chemiker',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0797',
    BART_TEXT: 'Laborbetrieb - Ingenieur',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0798',
    BART_TEXT: 'Laborbetrieb - Physiker',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4497',
    BART_TEXT: 'Lackhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4498',
    BART_TEXT: 'Lackhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2712',
    BART_TEXT: 'Lackherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0799',
    BART_TEXT: 'Lackiererei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2714',
    BART_TEXT: 'Ladenbaubetrieb (Holz)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2715',
    BART_TEXT: 'Ladenbaubetrieb (Kunststoff)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS2716',
    BART_TEXT: 'Ladenbaubetrieb (Metall)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3041',
    BART_TEXT: 'Lagerbehälterherstellung (Kunststoff)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3042',
    BART_TEXT: 'Lagerbehälterherstellung (Metall)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3043',
    BART_TEXT: 'Laienspielgruppe',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3046',
    BART_TEXT: 'Lampengestelleherstellung (Holz)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3048',
    BART_TEXT: 'Lampengestelleherstellung (Metall)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4837',
    BART_TEXT: 'Lampenhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4838',
    BART_TEXT: 'Lampenhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-G',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0804',
    BART_TEXT: 'Lampenherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-G',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5380',
    BART_TEXT: 'Land-, forstwirtschaftliche Pflanzenschutzbetriebe',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5381',
    BART_TEXT: 'Land-, forstwirtschaftliche Schädlingsbekämpfung',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4839',
    BART_TEXT: 'Landgerätehandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4840',
    BART_TEXT: 'Landgerätehandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4841',
    BART_TEXT: 'Landmaschinenhandel (mit Reparatur) - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5359',
    BART_TEXT: 'Landmaschinenhandel (mit Reparatur) - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4843',
    BART_TEXT: 'Landmaschinenhandel (ohne Reparatur) - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5069',
    BART_TEXT: 'Landmaschinenhandel (ohne Reparatur) - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3059',
    BART_TEXT: 'Landmaschinenherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5258',
    BART_TEXT: 'Landmaschinenreparaturbetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4845',
    BART_TEXT: 'Landproduktehandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5360',
    BART_TEXT: 'Landproduktehandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3063',
    BART_TEXT: 'Landschaftsarchitekt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3064',
    BART_TEXT: 'Landschaftsbaubetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4066',
    BART_TEXT: 'Landschaftsgärtnerei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3065',
    BART_TEXT: 'Landschulheim',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4636',
    BART_TEXT: 'Landwirtschaftlicher Lohnmaschinenbetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5259',
    BART_TEXT: 'Landwirtschaftlicher Maschinenring (Büro)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0820',
    BART_TEXT: 'Lascherei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3072',
    BART_TEXT: 'Lautsprecherherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-G',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4847',
    BART_TEXT: 'Lebensmittelgewerbebedarfshandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4848',
    BART_TEXT: 'Lebensmittelgewerbebedarfshandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4499',
    BART_TEXT: 'Lebensmittelhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4500',
    BART_TEXT: 'Lebensmittelhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-C',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3075',
    BART_TEXT: 'Lebkuchenherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3076',
    BART_TEXT: 'Leder-, Alcantarawarenfertigung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4501',
    BART_TEXT: 'Lederbekleidungshandel (mit Pelzwaren) - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4502',
    BART_TEXT: 'Lederbekleidungshandel (mit Pelzwaren) - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4503',
    BART_TEXT: 'Lederbekleidungshandel (ohne Pelzwaren) - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4504',
    BART_TEXT: 'Lederbekleidungshandel (ohne Pelzwaren) - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3080',
    BART_TEXT: 'Lederbekleidungsherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5260',
    BART_TEXT: 'Lederhandwerksbetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5361',
    BART_TEXT: 'Lederherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4505',
    BART_TEXT: 'Lederwarenhandel (nicht Bekleidung) - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4506',
    BART_TEXT: 'Lederwarenhandel (nicht Bekleidung) - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0832',
    BART_TEXT: 'Lederwarenherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3084',
    BART_TEXT: 'Lederwarenherstellung (ohne Sattlerei)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0833',
    BART_TEXT: 'Lederzuschneiderei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4016',
    BART_TEXT: 'Legehennenbetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3085',
    BART_TEXT: 'Lehranstalt, technische (öffentlich)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5489',
    BART_TEXT: 'Lehranstalt, technische (privat)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3087',
    BART_TEXT: 'Lehrer',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3088',
    BART_TEXT: 'Lehrgerüstbaubetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3089',
    BART_TEXT: 'Leichtmetallgießerei mit Schmelzmassen bis 2,5 t pro Gefäß',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3091',
    BART_TEXT: 'Leihbücherei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3093',
    BART_TEXT: 'Leihhaus (Schmucksachenanteil überwiegt nicht)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0837',
    BART_TEXT: 'Leimherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3095',
    BART_TEXT: 'Leitungsherstellung (Kabel)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4849',
    BART_TEXT: 'Leuchtenhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4850',
    BART_TEXT: 'Leuchtenhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-G',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0838',
    BART_TEXT: 'Leuchtenherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-G',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3097',
    BART_TEXT: 'Leuchtmittelherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-G',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3100',
    BART_TEXT: 'Lichtkuppeln - Herstellung (ohne Polyester)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-G',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3102',
    BART_TEXT: 'Lichtpausbetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3105',
    BART_TEXT: 'Lichtsatzstudio',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3106',
    BART_TEXT: 'Lichtspielhaus',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5129',
    BART_TEXT: 'Lieferservice (warme Speisen)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3107',
    BART_TEXT: 'Likörherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3111',
    BART_TEXT: 'Linoleumleger',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3112',
    BART_TEXT: 'Lithographischer Betrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0841',
    BART_TEXT: 'Logopäde, freiberuflich',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0842',
    BART_TEXT: 'Lohnsteuerhilfe-Verein',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5261',
    BART_TEXT: 'Lokomotivenbaubetrieb',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3114',
    BART_TEXT: 'Lotterieannahme',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3115',
    BART_TEXT: 'Luftfahrzeuge - Reparatur und Wartung',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3118',
    BART_TEXT: 'Luftfahrzeugherstellung (Metall)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3119',
    BART_TEXT: 'Luftfahrzeugherstellung (Polyester, reaktionshärtende Kunststoffe)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5262',
    BART_TEXT: 'Luftheizungsbaubetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3120',
    BART_TEXT: 'Lüftungsinstallationsbetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3121',
    BART_TEXT: 'Lüftungstechnikherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5263',
    BART_TEXT: 'Magnesiumverarbeitungsbetrieb (mechanisch unter 0,1 mm Toleranz)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3132',
    BART_TEXT: 'Makler',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4851',
    BART_TEXT: 'Malartikelhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4852',
    BART_TEXT: 'Malartikelhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4509',
    BART_TEXT: 'Malbedarfshandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4510',
    BART_TEXT: 'Malbedarfshandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5264',
    BART_TEXT: 'Maler- und Trockenbaubetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5162',
    BART_TEXT: 'Maler-, Tapezierer- und Lackiererei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3135',
    BART_TEXT: 'Malerbetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0858',
    BART_TEXT: 'Malschule',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0860',
    BART_TEXT: 'Malzextraktherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3136',
    BART_TEXT: 'Mangelbetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0861',
    BART_TEXT: 'Margarineherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-C',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0862',
    BART_TEXT: 'Marionettentheater',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4853',
    BART_TEXT: 'Markisenhandel (einschließlich Einbau) - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4854',
    BART_TEXT: 'Markisenhandel (einschließlich Einbau) - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3138',
    BART_TEXT: 'Markisenherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0863',
    BART_TEXT: 'Markt- und Meinungsforschungsinstitut',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0864',
    BART_TEXT: 'Markthalle',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0865',
    BART_TEXT: 'Marmeladenherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5265',
    BART_TEXT: 'Maschinenbaubetrieb (grob)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5266',
    BART_TEXT: 'Maschinenbaubetrieb (Toleranzbereich unter 0,1 mm)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5492',
    BART_TEXT: 'Maschinenhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5493',
    BART_TEXT: 'Maschinenhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3143',
    BART_TEXT: 'Maschinenhaus',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5267',
    BART_TEXT: 'Maschinenreinigungsbetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5268',
    BART_TEXT: 'Maschinenreparaturbetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5269',
    BART_TEXT: 'Maschinenring (landwirtschaftlich, Büro)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3146',
    BART_TEXT: 'Maschinenschlosserei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3147',
    BART_TEXT: 'Maschinenteileherstellung (Gummi)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3148',
    BART_TEXT: 'Maschinenteileherstellung (Kunststoff, ohne Polyester)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3149',
    BART_TEXT: 'Maschinenteileherstellung (Metall - grob)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3150',
    BART_TEXT: 'Maschinenteileherstellung (Metall)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3151',
    BART_TEXT: 'Maschinenteileherstellung (Polyester, reaktionshärtende Kunststoffe)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3152',
    BART_TEXT: 'Massageinstitut (medizinisch)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3153',
    BART_TEXT: 'Massagesalon (medizinisch)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3154',
    BART_TEXT: 'Massagesalon (nicht medizinisch)',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0868',
    BART_TEXT: 'Masseur, freiberuflich, staatlich geprüft',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4226',
    BART_TEXT: 'Master of Science/Psychologie',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4511',
    BART_TEXT: 'Matratzenhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4512',
    BART_TEXT: 'Matratzenhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3157',
    BART_TEXT: 'Matratzenherstellung (Gummi - ungeschäumt)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3158',
    BART_TEXT: 'Matratzenherstellung (Kunststoff ungeschäumt)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3159',
    BART_TEXT: 'Matratzenherstellung (Schaumgummi, -kunststoff) - Kaltverarbeitung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3160',
    BART_TEXT: 'Matratzenherstellung (Schaumgummi, -kunststoff) - Warmverarbeitung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0870',
    BART_TEXT: 'Matratzenherstellung (Textil)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4068',
    BART_TEXT: 'Maurerbetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3161',
    BART_TEXT: 'MDF-Plattenherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5163',
    BART_TEXT: 'Mediationsbetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4857',
    BART_TEXT: 'Medizinische Artikel - Handel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5362',
    BART_TEXT: 'Medizinische Artikel - Handel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3163',
    BART_TEXT: 'Medizinische Artikel (Werkstatt)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4859',
    BART_TEXT: 'Medizinische Massagen',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4860',
    BART_TEXT: 'Medizinischer Artikelhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5363',
    BART_TEXT: 'Medizinischer Artikelhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0874',
    BART_TEXT: 'Medizinischer Bademeister, freiberuflich, staatlich geprüft',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0875',
    BART_TEXT: 'Medizinisches Labor',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0876',
    BART_TEXT: 'Medizinisches Massageinstitut',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4096',
    BART_TEXT: 'Medizinisches Versorgungszentrum (MVZ)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3165',
    BART_TEXT: 'Medizinstudent im praktischen Jahr, später Assistenz(-Zahn)arzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3166',
    BART_TEXT: 'Mehrzweckhalle (Kultur, Tagung, Sport)',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3167',
    BART_TEXT: 'Meierei',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0880',
    BART_TEXT: 'Menagerie',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3173',
    BART_TEXT: 'Mess- und Regeltechnik (Elektrotechnischer Betrieb)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3174',
    BART_TEXT: 'Mess- und Regeltechnik (Feinmechanischer Betrieb)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0881',
    BART_TEXT: 'Messe',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3175',
    BART_TEXT: 'Messebaubetrieb (Holz)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3176',
    BART_TEXT: 'Messebaubetrieb (Kunststoff)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3177',
    BART_TEXT: 'Messebaubetrieb (Metall)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3178',
    BART_TEXT: 'Messehalle',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3180',
    BART_TEXT: 'Metall - Veredelung und Beschichtung (galvanisch)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3181',
    BART_TEXT: 'Metall - Veredelung und Beschichtung (keramisch)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3182',
    BART_TEXT: 'Metall - Veredelung und Beschichtung (Pulverbeschichtung)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3183',
    BART_TEXT: 'Metallarmaturenbaubetrieb',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3184',
    BART_TEXT: 'Metallbaubetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5270',
    BART_TEXT: 'Metallbearbeitungs, -verarbeitungsbetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5271',
    BART_TEXT: 'Metallbearbeitungsbetrieb (Toleranzbereich über 0,1 mm)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5272',
    BART_TEXT: 'Metallbearbeitungsbetrieb (Toleranzbereich unter 0,1 mm)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5273',
    BART_TEXT: 'Metallfassadenbaubetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3189',
    BART_TEXT: 'Metallgehäusebau',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3190',
    BART_TEXT: 'Metallgießerei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5494',
    BART_TEXT: 'Metallhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5495',
    BART_TEXT: 'Metallhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3192',
    BART_TEXT: 'Metallhandwerk',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3193',
    BART_TEXT: 'Metalllegierung - Herstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3194',
    BART_TEXT: 'Metallrecycling',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3195',
    BART_TEXT: 'Metallschmiede',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3196',
    BART_TEXT: 'Metallstanzerei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5274',
    BART_TEXT: 'Metallverarbeitungsbetrieb (Toleranzbereich über 0,1 mm)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5275',
    BART_TEXT: 'Metallverarbeitungsbetrieb (Toleranzbereich unter 0,1 mm)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5276',
    BART_TEXT: 'Metallveredelungs, -beschichtungsbetrieb (Kunststoff)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5277',
    BART_TEXT: 'Metallveredelungs, -beschichtungsbetrieb (Kunststoff-Pulverbeschichtung)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5278',
    BART_TEXT: 'Metallveredelungsbetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3202',
    BART_TEXT: 'Metallverpackungsmittelherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3203',
    BART_TEXT: 'Metallwarenbearbeitung',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5496',
    BART_TEXT: 'Metallwarenhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5497',
    BART_TEXT: 'Metallwarenhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3207',
    BART_TEXT: 'Metzgerei (mit Fleisch-, Wurstbe- und -verarbeitung)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5364',
    BART_TEXT: 'Metzgerei (ohne Fleisch-, Wurstbe- und -verarbeitung)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4115',
    BART_TEXT: 'Metzgerei mit Imbiss bzw. Catering',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4866',
    BART_TEXT: 'Metzgereibedarfshandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4867',
    BART_TEXT: 'Metzgereibedarfshandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4515',
    BART_TEXT: 'Miederwarenhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4516',
    BART_TEXT: 'Miederwarenhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3211',
    BART_TEXT: 'Miederwarenherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5279',
    BART_TEXT: 'Mieterberatungsbüro',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5556',
    BART_TEXT: 'Mietwerkstatt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3212',
    BART_TEXT: 'Milchproduktherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3213',
    BART_TEXT: 'Milchpulver - Herstellung und Verarbeitung (Lebensmittel)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-C',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3214',
    BART_TEXT: 'Milchpulver - Herstellung und Verarbeitung (Tierfutter)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-C',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5280',
    BART_TEXT: 'Milchverarbeitungsbetrieb',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3216',
    BART_TEXT: 'Mineralfarben - Herstellung und Verarbeitung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0895',
    BART_TEXT: 'Mineralfuttermittelherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5281',
    BART_TEXT: 'Mineralien - Gewinnungs- und Aufbereitungsbetrieb',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3218',
    BART_TEXT: 'Mineralien (Edelsteine) - Gewinnung und Aufbereitung',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4227',
    BART_TEXT: 'Mineralienbe- und verarbeitung (nicht Edelsteinschleiferei)',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4868',
    BART_TEXT: 'Mineralölhandel (nicht Tankstelle, nicht Schmierstoffe) - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4869',
    BART_TEXT: 'Mineralölhandel (nicht Tankstelle, nicht Schmierstoffe) - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3221',
    BART_TEXT: 'Mineralwassergewinnung und -abfüllung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0896',
    BART_TEXT: 'Minigolfanlage',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4517',
    BART_TEXT: 'Möbelhandel (mit Orientteppichen) - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4518',
    BART_TEXT: 'Möbelhandel (mit Orientteppichen) - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4519',
    BART_TEXT: 'Möbelhandel (ohne Orientteppiche) - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4520',
    BART_TEXT: 'Möbelhandel (ohne Orientteppiche) - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3225',
    BART_TEXT: 'Möbelherstellung (Holz) - Serienfertigung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-C',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3226',
    BART_TEXT: 'Möbelherstellung (Metall)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-C',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3227',
    BART_TEXT: 'Möbelherstellung (Polster)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-C',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3228',
    BART_TEXT: 'Möbellackiererei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3229',
    BART_TEXT: 'Möbelschreinerei (keine Serienfertigung)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3230',
    BART_TEXT: 'Möbelschreinerei (Serienfertigung)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4228',
    BART_TEXT: 'Möbeltischlerei (keine Serienfertigung)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4229',
    BART_TEXT: 'Möbeltischlerei (Serienfertigung)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4160',
    BART_TEXT: 'Mobiler Kfz-Pflegedienst',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4521',
    BART_TEXT: 'Mobilfunkgerätehandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4522',
    BART_TEXT: 'Mobilfunkgerätehandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4231',
    BART_TEXT: 'Modeatelier',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5282',
    BART_TEXT: 'Modellbaubetrieb (Holz)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5130',
    BART_TEXT: 'Modellbaubetrieb (Kunststoff)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5283',
    BART_TEXT: 'Modellbaubetrieb (Metall)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4870',
    BART_TEXT: 'Modellbausatzhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4871',
    BART_TEXT: 'Modellbausatzhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3239',
    BART_TEXT: 'Modellbausatzherstellung (Holz)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3240',
    BART_TEXT: 'Modellbausatzherstellung (Kunststoff)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3241',
    BART_TEXT: 'Modellbausatzherstellung (Metall)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3242',
    BART_TEXT: 'Modellherstellung (Holz)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3243',
    BART_TEXT: 'Modellherstellung (Kunststoff)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3244',
    BART_TEXT: 'Modellherstellung (Metall)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5704',
    BART_TEXT: 'Modeschmuckhandel (nur Betriebseinrichtung)',
    'BART-Gruppe': 'VAL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'VAL-A'
  },
  {
    HGVBETRART: 'FHS4874',
    BART_TEXT: 'Modeschmuckhandel (ohne Juwelierwaren) - Einzelhandel',
    'BART-Gruppe': 'VAL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'VAL-A'
  },
  {
    HGVBETRART: 'FHS5706',
    BART_TEXT: 'Modeschmuckhandel (ohne Juwelierwaren) - Großhandel',
    'BART-Gruppe': 'VAL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'VAL-A'
  },
  {
    HGVBETRART: 'FHS7828',
    BART_TEXT: 'Modeschmuckherstellung (nur Betriebseinrichtung)',
    'BART-Gruppe': 'VAL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'VAL-A'
  },
  {
    HGVBETRART: 'FHS3248',
    BART_TEXT: 'Modeschmuckherstellung (ohne Juwelierwaren)',
    'BART-Gruppe': 'VAL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'VAL-A'
  },
  {
    HGVBETRART: 'FHS3249',
    BART_TEXT: 'Molkerei',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0908',
    BART_TEXT: 'Molkerei, Meierei',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3250',
    BART_TEXT: 'Mörtelwerk',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3252',
    BART_TEXT: 'Moschee',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3253',
    BART_TEXT: 'Mosterei',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3254',
    BART_TEXT: 'Motel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4232',
    BART_TEXT: 'Motopädie',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0910',
    BART_TEXT: 'Motorenherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4876',
    BART_TEXT: 'Motorradhandel (mit Reparatur) - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5365',
    BART_TEXT: 'Motorradhandel (mit Reparatur) - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4878',
    BART_TEXT: 'Motorradhandel (ohne Reparatur) - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5070',
    BART_TEXT: 'Motorradhandel (ohne Reparatur) - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4612',
    BART_TEXT: 'Motorrad-Reparaturwerkstatt (mit Handel) - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4613',
    BART_TEXT: 'Motorrad-Reparaturwerkstatt (mit Handel) - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4236',
    BART_TEXT: 'Motorrad-Reparaturwerkstatt (ohne Handel)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4880',
    BART_TEXT: 'Motorradzubehörhandel (mit Reparatur) - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5366',
    BART_TEXT: 'Motorradzubehörhandel (mit Reparatur) - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4882',
    BART_TEXT: 'Motorradzubehörhandel (ohne Reparatur) - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5071',
    BART_TEXT: 'Motorradzubehörhandel (ohne Reparatur) - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3257',
    BART_TEXT: 'Motorsportverein',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3258',
    BART_TEXT: 'Mühle (Getreide)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3260',
    BART_TEXT: 'Mühle (Öle)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0912',
    BART_TEXT: 'Müllabfuhr',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3261',
    BART_TEXT: 'Müllverbrennungsanlage (nicht Sondermüllverbrennungsanlage)',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5560',
    BART_TEXT: 'Müllverbrennungsanlage ohne Stromerzeugung',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5284',
    BART_TEXT: 'Multifunktionshandwerksbetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0913',
    BART_TEXT: 'Mund-, Kiefer-, Gesichtschirurg - freie Praxis - ambulant/operativ',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0914',
    BART_TEXT: 'Mund-, Kiefer-, Gesichtschirurg - freie Praxis - stationär bzw. Belegarzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0916',
    BART_TEXT: 'Mund-, Kiefer-, Gesichtschirurg - stationär bzw. Belegarzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0915',
    BART_TEXT: 'Mund-, Kiefer-, Gesichtschirurg ambulant/operativ',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0917',
    BART_TEXT: 'Mund-Kiefer-Gesichtschirurgie - Assistenz-Facharzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0918',
    BART_TEXT: 'Mund-Kiefer-Gesichtschirurgie - stationär - Abteilungsleiter',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0919',
    BART_TEXT: 'Mund-Kiefer-Gesichtschirurgie - stationär - Chefarzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0920',
    BART_TEXT: 'Mund-Kiefer-Gesichtschirurgie - stationär - Oberarzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS7829',
    BART_TEXT: 'Mund-Kiefer-Gesichtschirurgie - stationär - Vertretungsarzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0922',
    BART_TEXT: 'Munitionsbergungsbetrieb',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4328',
    BART_TEXT: 'Münzenhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5499',
    BART_TEXT: 'Münzenhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3263',
    BART_TEXT: 'Münzwaschsalon',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0925',
    BART_TEXT: 'Museum',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4523',
    BART_TEXT: 'Musikalienhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4524',
    BART_TEXT: 'Musikalienhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5285',
    BART_TEXT: 'Musikinstrumentenbaubetrieb (Elektronik)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-G',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5286',
    BART_TEXT: 'Musikinstrumentenbaubetrieb (Holz)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-G',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5287',
    BART_TEXT: 'Musikinstrumentenbaubetrieb (Kunststoff)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-G',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5288',
    BART_TEXT: 'Musikinstrumentenbaubetrieb (Metall)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-G',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4886',
    BART_TEXT: 'Musikinstrumentenhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4887',
    BART_TEXT: 'Musikinstrumentenhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4134',
    BART_TEXT: 'Musikinstrumentenherstellung (handwerkliche Betriebe)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-G',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0930',
    BART_TEXT: 'Musikinstrumentenherstellung (industrielle Betriebe)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-G',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3271',
    BART_TEXT: 'Musikschule (öffentlich)',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5501',
    BART_TEXT: 'Musikschule (privat)',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5649',
    BART_TEXT: 'Musiktherapeut',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0932',
    BART_TEXT: 'Musikverein',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5164',
    BART_TEXT: 'Musikverlag',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3274',
    BART_TEXT: 'Musterwohnung (Mobiliar)',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4525',
    BART_TEXT: 'Mützenhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4526',
    BART_TEXT: 'Mützenhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3276',
    BART_TEXT: 'Mützenherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5650',
    BART_TEXT: 'Nachhilfe',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3277',
    BART_TEXT: 'Nachlasspfleger',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3278',
    BART_TEXT: 'Nachlassverwalter',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3279',
    BART_TEXT: 'Nachtclub',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4241',
    BART_TEXT: 'Nachtlokal',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3281',
    BART_TEXT: 'Nadelfilzherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0933',
    BART_TEXT: 'Nagelstudio',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3282',
    BART_TEXT: 'Näherei (Bekleidung, keine Änderungsschneiderei)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3283',
    BART_TEXT: 'Näherei (nicht Bekleidung)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4889',
    BART_TEXT: 'Nähmaschinenhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4890',
    BART_TEXT: 'Nähmaschinenhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3285',
    BART_TEXT: 'Nähmaschinenherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5289',
    BART_TEXT: 'Nähmaschinenreparaturbetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4892',
    BART_TEXT: 'Nahrungsmittelgewerbebedarfshandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4527',
    BART_TEXT: 'Nahrungsmittelhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4528',
    BART_TEXT: 'Nahrungsmittelhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0937',
    BART_TEXT: 'Narrenzunft',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3289',
    BART_TEXT: 'Naturharz (Farben, Lack) - Herstellung und Verarbeitung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3290',
    BART_TEXT: 'Naturharz (Klebstoffe) - Herstellung und Verarbeitung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3291',
    BART_TEXT: 'Naturkostladen',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3292',
    BART_TEXT: 'Naturseidenspinnerei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3293',
    BART_TEXT: 'Naturseidenweberei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3294',
    BART_TEXT: 'Natursteinbearbeitungsbetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0938',
    BART_TEXT: 'Nervenheilanstalt',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0940',
    BART_TEXT: 'Neurologe - Assistenz-Facharzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0941',
    BART_TEXT: 'Neurologe - freie Praxis - ambulant/operativ',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0942',
    BART_TEXT: 'Neurologe - freie Praxis - stationär bzw. Belegarzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0943',
    BART_TEXT: 'Neurologe - stationär - Abteilungsleiter',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0944',
    BART_TEXT: 'Neurologe - stationär - Chefarzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0945',
    BART_TEXT: 'Neurologe - stationär - Oberarzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0946',
    BART_TEXT: 'Neurologe - stationär - Vertretungsarzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3295',
    BART_TEXT: 'Nichteisengießerei mit Schmelzmassen bis 2,5 t pro Gefäß',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3297',
    BART_TEXT: 'Nitrocelluloselackherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3298',
    BART_TEXT: 'Nitrolackherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5290',
    BART_TEXT: 'Notariat',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3299',
    BART_TEXT: 'Nudelherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0949',
    BART_TEXT: 'Nuklearmediziner - Assistenz-Facharzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0950',
    BART_TEXT: 'Nuklearmediziner - freie Praxis - ambulant/operativ',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0951',
    BART_TEXT: 'Nuklearmediziner - freie Praxis - stationär bzw. Belegarzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0952',
    BART_TEXT: 'Nuklearmediziner - stationär - Abteilungsleiter',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0953',
    BART_TEXT: 'Nuklearmediziner - stationär - Chefarzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0954',
    BART_TEXT: 'Nuklearmediziner - stationär - Oberarzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS7830',
    BART_TEXT: 'Nuklearmediziner - stationär - Vertretungsarzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3300',
    BART_TEXT: 'Obdachlosenheim',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4529',
    BART_TEXT: 'Obsthandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4530',
    BART_TEXT: 'Obsthandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0962',
    BART_TEXT: 'Obstkelterei',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3303',
    BART_TEXT: 'Obstkonservenherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3304',
    BART_TEXT: 'Obstverarbeitung (Konserven, Tiefkühlkost)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5291',
    BART_TEXT: 'Ofenbaubetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4893',
    BART_TEXT: 'Ofenhandel (nicht E-Herde) - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4894',
    BART_TEXT: 'Ofenhandel (nicht E-Herde) - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4895',
    BART_TEXT: 'Ofenhandel mit Montage sowie Schornsteinfeger - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4896',
    BART_TEXT: 'Ofenhandel mit Montage sowie Schornsteinfeger - Großhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3307',
    BART_TEXT: 'Ofensetzer',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3308',
    BART_TEXT: 'Offsetdruckerei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3309',
    BART_TEXT: 'Öle, etherische - Herstellung und Verarbeitung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-C',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3310',
    BART_TEXT: 'Öle, pflanzliche - Herstellung und Verarbeitung (ohne Speiseöl)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-C',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3311',
    BART_TEXT: 'Öle, tierische - Herstellung und Verarbeitung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4897',
    BART_TEXT: 'Ölhandel (nicht Schmierstoffe) - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4898',
    BART_TEXT: 'Ölhandel (nicht Schmierstoffe) - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3313',
    BART_TEXT: 'Ölheizungsinstallationsbetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0965',
    BART_TEXT: 'Ölmühle',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-C',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3314',
    BART_TEXT: 'Öltank - Reinigung und Wartung',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3315',
    BART_TEXT: 'Omnibusunternehmen',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3316',
    BART_TEXT: 'Online-Dienst',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4069',
    BART_TEXT: 'Optiker',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0967',
    BART_TEXT: 'Optikherstellung (auch Teile)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-G',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3317',
    BART_TEXT: 'Optische Gläser - Herstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-G',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3318',
    BART_TEXT: 'Optischer Betrieb (Feinmechanik)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3319',
    BART_TEXT: 'Optisches Handwerk',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0970',
    BART_TEXT: 'Oralchirurg - Assistenz-Facharzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0971',
    BART_TEXT: 'Oralchirurg - freie Praxis - ambulant/operativ',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0972',
    BART_TEXT: 'Oralchirurg - freie Praxis - stationär bzw. Belegarzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0973',
    BART_TEXT: 'Oralchirurg - stationär - Abteilungsleiter',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0974',
    BART_TEXT: 'Oralchirurg - stationär - Chefarzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0975',
    BART_TEXT: 'Oralchirurg - stationär - Oberarzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS7831',
    BART_TEXT: 'Oralchirurg - stationär - Vertretungsarzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4614',
    BART_TEXT: 'Ordenhandel - Einzelhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4615',
    BART_TEXT: 'Ordenhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5292',
    BART_TEXT: 'Orgelbaubetrieb (Pfeifenorgel)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-G',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4903',
    BART_TEXT: 'Orientteppichhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4904',
    BART_TEXT: 'Orientteppichhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0978',
    BART_TEXT: 'Orthopäde - Assistenz-Facharzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0979',
    BART_TEXT: 'Orthopäde - freie Praxis - ambulant',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0980',
    BART_TEXT: 'Orthopäde - freie Praxis - ambulant/operativ',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0981',
    BART_TEXT: 'Orthopäde - freie Praxis - stationär bzw. Belegarzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0982',
    BART_TEXT: 'Orthopäde - stationär - Abteilungsleiter',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0983',
    BART_TEXT: 'Orthopäde - stationär - Chefarzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0984',
    BART_TEXT: 'Orthopäde - stationär - Oberarzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0985',
    BART_TEXT: 'Orthopäde - stationär - Vertretungsarzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4905',
    BART_TEXT: 'Orthopädieartikelhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4906',
    BART_TEXT: 'Orthopädieartikelhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0986',
    BART_TEXT: 'Orthopädische Werkstatt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS7832',
    BART_TEXT: 'Osteopath',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5072',
    BART_TEXT: 'Pannenhilfe (mit Werkstatt)',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3324',
    BART_TEXT: 'Papier - Halbstoffherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0988',
    BART_TEXT: 'Papierhandwerk',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3325',
    BART_TEXT: 'Papierherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3327',
    BART_TEXT: 'Papierrecycling',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5293',
    BART_TEXT: 'Papierverarbeitungsbetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4907',
    BART_TEXT: 'Papierwarenhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4908',
    BART_TEXT: 'Papierwarenhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3330',
    BART_TEXT: 'Pappe - Halbstoffherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3331',
    BART_TEXT: 'Pappeherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3332',
    BART_TEXT: 'Pappeverarbeitung',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3333',
    BART_TEXT: 'Paraffinpapierherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3334',
    BART_TEXT: 'Paraxin - Herstellung und Verarbeitung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-C',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0992',
    BART_TEXT: 'Parfümerie',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0993',
    BART_TEXT: 'Parfümeriewarenherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3335',
    BART_TEXT: 'Parfümherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0994',
    BART_TEXT: 'Parkettherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3336',
    BART_TEXT: 'Parkettlegebetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0996',
    BART_TEXT: 'Parkhaus',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3337',
    BART_TEXT: 'Parkhaus, -Verwaltung',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3338',
    BART_TEXT: 'Partei (politisch)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5294',
    BART_TEXT: 'Partnervermittlungsbüro',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3340',
    BART_TEXT: 'Party-Service',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5295',
    BART_TEXT: 'Patentanwaltskanzlei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0998',
    BART_TEXT: 'Pathologe',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3342',
    BART_TEXT: 'Pauspapierherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3343',
    BART_TEXT: 'PC-Herstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3344',
    BART_TEXT: 'Pediküresalon',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3345',
    BART_TEXT: 'Pelletherstellung (Holz)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS0999',
    BART_TEXT: 'Pelz verarbeitendes Handwerk',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4909',
    BART_TEXT: 'Pelzbekleidungshandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4910',
    BART_TEXT: 'Pelzbekleidungshandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3347',
    BART_TEXT: 'Penicillin - Herstellung und Verarbeitung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-C',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3348',
    BART_TEXT: 'Pension (mit Restaurant)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3349',
    BART_TEXT: 'Pension (ohne Restaurant)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3350',
    BART_TEXT: 'Pergamentpapierherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4245',
    BART_TEXT: 'Personal Trainer (nicht Sport)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5651',
    BART_TEXT: 'Personal Trainer (Sport)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5296',
    BART_TEXT: 'Personalberatungsbüro (mit Personalverleih)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5297',
    BART_TEXT: 'Personalberatungsbüro (ohne Personalverleih)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5131',
    BART_TEXT: 'Perücken- und Harteilehandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5132',
    BART_TEXT: 'Perücken- und Harteilehandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4284',
    BART_TEXT: 'Perückenhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4285',
    BART_TEXT: 'Perückenhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3353',
    BART_TEXT: 'Perückenherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3356',
    BART_TEXT: 'Pfandhaus (Schmucksachenanteil überwiegt nicht)',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4330',
    BART_TEXT: 'Pfeifenhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4331',
    BART_TEXT: 'Pfeifenhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3357',
    BART_TEXT: 'Pferdebedarf (Sattlerei)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4913',
    BART_TEXT: 'Pferdebedarfshandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4914',
    BART_TEXT: 'Pferdebedarfshandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3359',
    BART_TEXT: 'Pferdemetzgerei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3360',
    BART_TEXT: 'Pferdepension',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3361',
    BART_TEXT: 'Pferderennbahn',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3362',
    BART_TEXT: 'Pferdestall',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3363',
    BART_TEXT: 'Pferdeverleih',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3364',
    BART_TEXT: 'Pferdezucht',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4531',
    BART_TEXT: 'Pflanzenhandel (mit Gärtnerei) - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4532',
    BART_TEXT: 'Pflanzenhandel (mit Gärtnerei) - Großhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4533',
    BART_TEXT: 'Pflanzenhandel (ohne Gärtnerei) - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4534',
    BART_TEXT: 'Pflanzenhandel (ohne Gärtnerei) - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5298',
    BART_TEXT: 'Pflasterei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3370',
    BART_TEXT: 'Pflasterungsbetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3371',
    BART_TEXT: 'Pflegedienst (ambulant)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3372',
    BART_TEXT: 'Pflegeheim',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1011',
    BART_TEXT: 'Pflegeheim ohne eigene ärztliche Betreuung',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1012',
    BART_TEXT: 'Pharmazeutikaherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-C',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1013',
    BART_TEXT: 'Pharmazeutischer Betrieb mit Deckungsvorsorgepflicht nach AMG',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-C',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1014',
    BART_TEXT: 'Pharmazeutischer Betrieb ohne Deckungsvorsorgepflicht nach AMG',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-C',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1016',
    BART_TEXT: 'Pharmazeutisches Labor',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4099',
    BART_TEXT: 'Physikalische und Rehabilitative Medizin - freie Praxis - ambulant',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4100',
    BART_TEXT: 'Physikalische und Rehabilitative Medizin - freie Praxis - ambulant/operativ',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4101',
    BART_TEXT: 'Physikalische und Rehabilitative Medizin - freie Praxis - stationär bzw. Belegarzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1017',
    BART_TEXT: 'Physikalisches Labor',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1018',
    BART_TEXT: 'Physio-, Ergotherapeut, freiberuflich, staatlich geprüft',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3380',
    BART_TEXT: 'Piercingstudio',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1019',
    BART_TEXT: 'Pinselherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3381',
    BART_TEXT: 'Pizzaimbiss (freistehend, jedoch ohne Imbiss-, Verkaufswagen)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3382',
    BART_TEXT: 'Pizzaimbiss (im Gebäude)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3383',
    BART_TEXT: 'Pizza-Service',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1020',
    BART_TEXT: 'Pizzeria',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5165',
    BART_TEXT: 'Plakatierer',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3384',
    BART_TEXT: 'Plattenlegerbetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3385',
    BART_TEXT: 'Plätterei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4248',
    BART_TEXT: 'Podologe',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4616',
    BART_TEXT: 'Pokalhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4617',
    BART_TEXT: 'Pokalhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1022',
    BART_TEXT: 'Polier- und Schleifmittelherstellung (Chemie)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1023',
    BART_TEXT: 'Politische Gemeinde',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5367',
    BART_TEXT: 'Polizeiarzt - nur dienstliche Tätigkeit',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3388',
    BART_TEXT: 'Polsterei',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1028',
    BART_TEXT: 'Polstermöbelherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5299',
    BART_TEXT: 'Polyamide - Be- und Verarbeitungsbetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3396',
    BART_TEXT: 'Polyester (glasfaserverstärkte Formteile) - Herstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3398',
    BART_TEXT: 'Polyethylen - Spritzguss- und Strangpressformlinge - Herstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3400',
    BART_TEXT: 'Polyethylen (Bahnen und Folien) - Herstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3405',
    BART_TEXT: 'Polystyrol (Schaumkunststoffe) - Herstellung und Verarbeitung - Kaltverarbeitung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3406',
    BART_TEXT: 'Polystyrol (Schaumkunststoffe) - Herstellung und Verarbeitung - Warmverarbeitung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3407',
    BART_TEXT: 'Polyurethan - Formschäumerei - Kaltverarbeitung',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3409',
    BART_TEXT: 'Polyurethan (Hartschaum) - Herstellung und Verarbeitung - Kaltverarbeitung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3411',
    BART_TEXT: 'Polyurethan-Schaumkunststoffe - Herstellung und Verarbeitung - Kaltverarbeitung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3412',
    BART_TEXT: 'Polyurethan-Schaumkunststoffe - Herstellung und Verarbeitung - Warmverarbeitung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3414',
    BART_TEXT: 'Polyvinylchlorid - Spritzguss-, Strangpress-, Gieß- und Schleudergussproduktherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1029',
    BART_TEXT: 'Ponyreiten',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4915',
    BART_TEXT: 'Pornohandel - Einzelhandel',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4916',
    BART_TEXT: 'Pornohandel - Großhandel',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1030',
    BART_TEXT: 'Porzellanherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-G',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1031',
    BART_TEXT: 'Porzellanherstellung zur industriellen und gewerblichen Nutzung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-G',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4250',
    BART_TEXT: 'Porzellanmalerei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4251',
    BART_TEXT: 'Porzellanpuppenherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-G',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4917',
    BART_TEXT: 'Porzellanwarenhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4918',
    BART_TEXT: 'Porzellanwarenhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-G',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3424',
    BART_TEXT: 'Posamenteherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3426',
    BART_TEXT: 'Prägeanstalt (Metall)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5133',
    BART_TEXT: 'Präzisionsmetallbe- und -verarbeitungsbetrieb (Toleranzbereich über 0,1 mm)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5300',
    BART_TEXT: 'Präzisionsmetallbe- und -verarbeitungsbetrieb (Toleranzbereich unter 0,1 mm)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3429',
    BART_TEXT: 'Presseagentur',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3431',
    BART_TEXT: 'Pressplattenherstellung (Holz)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1034',
    BART_TEXT: 'Presswerk',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1035',
    BART_TEXT: 'Privatschule',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3432',
    BART_TEXT: 'Profilherstellung (Kunststoff)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4071',
    BART_TEXT: 'Projektsteuererbüro',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4921',
    BART_TEXT: 'Propangashandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4922',
    BART_TEXT: 'Propangashandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3435',
    BART_TEXT: 'Prozeßagent',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3436',
    BART_TEXT: 'Prüfingenieur',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4072',
    BART_TEXT: 'Prüfingenieur für Baustatik (ausschließlich hoheitliche Tätigkeit)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3437',
    BART_TEXT: 'Psychiatrische Klinik',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3438',
    BART_TEXT: 'Psychologe',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4252',
    BART_TEXT: 'Psychologische Berater',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4253',
    BART_TEXT: 'Psychologische Psychotherapeuten',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1041',
    BART_TEXT: 'Psychotherapeut - Assistenz-Facharzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1042',
    BART_TEXT: 'Psychotherapeut - freie Praxis - ambulant/operativ',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1043',
    BART_TEXT: 'Psychotherapeut - freie Praxis - stationär bzw. Belegarzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1044',
    BART_TEXT: 'Psychotherapeut - stationär - Abteilungsleiter',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1045',
    BART_TEXT: 'Psychotherapeut - stationär - Chefarzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1046',
    BART_TEXT: 'Psychotherapeut - stationär - Oberarzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1047',
    BART_TEXT: 'Psychotherapeut - stationär - Vertretungsarzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3439',
    BART_TEXT: 'Pub',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1048',
    BART_TEXT: 'Public-Relations-Agentur',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3441',
    BART_TEXT: 'Puderherstellung (kosmetische)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5301',
    BART_TEXT: 'Pulverbeschichtungsbetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3443',
    BART_TEXT: 'Pumpstation',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3444',
    BART_TEXT: 'Putzerei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3445',
    BART_TEXT: 'Putzmittelherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1050',
    BART_TEXT: 'Pyrotechniker',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5382',
    BART_TEXT: 'Radfahrverein',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4586',
    BART_TEXT: 'Radio-, Fernseh-, Unterhaltungselektronikhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4587',
    BART_TEXT: 'Radio-, Fernseh-, Unterhaltungselektronikhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3449',
    BART_TEXT: 'Radiogerätereparatur (ohne Handel)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4923',
    BART_TEXT: 'Radiologe - stationär/ambulant, ohne eigene Praxis - in Kliniken beschäftigt - Abteilungsleiter',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4924',
    BART_TEXT: 'Radiologe - stationär/ambulant, ohne eigene Praxis - in Kliniken beschäftigt – Chefarzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1056',
    BART_TEXT: 'Radiologe (diagnostisch) - Assistenz-Facharzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1057',
    BART_TEXT: 'Radiologe (diagnostisch) - freie Praxis - ambulant/operativ',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1058',
    BART_TEXT: 'Radiologe (diagnostisch) - freie Praxis - stationär bzw. Belegarzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1059',
    BART_TEXT: 'Radiologe (diagnostisch) - stationär - Abteilungsleiter',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1060',
    BART_TEXT: 'Radiologe (diagnostisch) - stationär - Chefarzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1061',
    BART_TEXT: 'Radiologe (diagnostisch) - stationär - Oberarzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS7833',
    BART_TEXT: 'Radiologe (diagnostisch) - stationär - Vertretungsarzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1063',
    BART_TEXT: 'Radiologe (therapeutisch und diagnostisch/therapeutisch) - Assistenz-Facharzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1064',
    BART_TEXT: 'Radiologe (therapeutisch und diagnostisch/therapeutisch) - freie Praxis - ambulant/operativ',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1065',
    BART_TEXT: 'Radiologe (therapeutisch und diagnostisch/therapeutisch) - freie Praxis - stationär bzw. Belegarzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1066',
    BART_TEXT: 'Radiologe (therapeutisch und diagnostisch/therapeutisch) - stationär - Abteilungsleiter',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1067',
    BART_TEXT: 'Radiologe (therapeutisch und diagnostisch/therapeutisch) - stationär - Chefarzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1068',
    BART_TEXT: 'Radiologe (therapeutisch und diagnostisch/therapeutisch) - stationär - Oberarzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS7834',
    BART_TEXT: 'Radiologe (therapeutisch und diagnostisch/therapeutisch) - stationär - Vertretungsarzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3450',
    BART_TEXT: 'Raiffeisenbank',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3451',
    BART_TEXT: 'Rathaus',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3452',
    BART_TEXT: 'Räucherei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3453',
    BART_TEXT: 'Räucherwarenfertigung (geräucherte Waren)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-C',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4536',
    BART_TEXT: 'Räucherwarenhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4537',
    BART_TEXT: 'Räucherwarenhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-C',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4925',
    BART_TEXT: 'Raumausstatter (mit Orientteppichen) und Handel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4926',
    BART_TEXT: 'Raumausstatter (mit Orientteppichen) und Handel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4927',
    BART_TEXT: 'Raumausstatter (ohne Orientteppiche) und Handel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4928',
    BART_TEXT: 'Raumausstatter (ohne Orientteppiche) und Handel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3455',
    BART_TEXT: 'Raumausstattungsbetrieb (mit Orientteppichen)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3456',
    BART_TEXT: 'Raumausstattungsbetrieb (ohne Orientteppiche)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3457',
    BART_TEXT: 'Reaktionsklebstoffe - Herstellung und Verarbeitung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3458',
    BART_TEXT: 'Rechenzentrum',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5302',
    BART_TEXT: 'Rechtsanwaltskanzlei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5303',
    BART_TEXT: 'Rechtsberatungsbüro',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1075',
    BART_TEXT: 'Rechtsmediziner',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5074',
    BART_TEXT: 'Recycling - Aufbereitung sortenreiner Stoffe',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3461',
    BART_TEXT: 'Recycling - Kompostierung',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3462',
    BART_TEXT: 'Recycling Altpapier',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3463',
    BART_TEXT: 'Recycling gemischter Stoffe - Trennung, Sortierung, Aufbereitung',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3465',
    BART_TEXT: 'Recycling Kunststoff',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3466',
    BART_TEXT: 'Recycling Metall',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1077',
    BART_TEXT: 'Reformhaus',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4286',
    BART_TEXT: 'Reformwarenhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4287',
    BART_TEXT: 'Reformwarenhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-C',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3468',
    BART_TEXT: 'Regel- und Messtechnik (Elektrotechnischer Betrieb)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3469',
    BART_TEXT: 'Regel- und Messtechnik (Feinmechanischer Betrieb)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4156',
    BART_TEXT: 'Rehaklinik',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4538',
    BART_TEXT: 'Reifenhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4539',
    BART_TEXT: 'Reifenhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3472',
    BART_TEXT: 'Reifenherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5304',
    BART_TEXT: 'Reifenrunderneuerungsbetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3475',
    BART_TEXT: 'Reinigung, chemisch (auch inkl. Annahmestelle)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5305',
    BART_TEXT: 'Reinigungsfachkraft, selbständig',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1490',
    BART_TEXT: 'Reinigungshilfe, selbständig',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3476',
    BART_TEXT: 'Reinigungsmittelherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1083',
    BART_TEXT: 'Reisebüro',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5166',
    BART_TEXT: 'Reiseführer',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4631',
    BART_TEXT: 'Reiseunternehmer/-veranstalter für Abenteuerreisen',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3477',
    BART_TEXT: 'Reiseveranstalter',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3478',
    BART_TEXT: 'Reiseveranstalter, innerhalb Europas',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3479',
    BART_TEXT: 'Reiseveranstalter, weltweit',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1088',
    BART_TEXT: 'Reit-, Fahrverein',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4540',
    BART_TEXT: 'Reiterbedarfshandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4541',
    BART_TEXT: 'Reiterbedarfshandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3484',
    BART_TEXT: 'Reiterhof',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3485',
    BART_TEXT: 'Reithalle',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3486',
    BART_TEXT: 'Reitlehrer',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1090',
    BART_TEXT: 'Reitschule',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1089',
    BART_TEXT: 'Reitsportbetrieb',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3487',
    BART_TEXT: 'Reitstall',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3488',
    BART_TEXT: 'religiöse Vereinigung',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5306',
    BART_TEXT: 'Rentenberatungsbüro',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1091',
    BART_TEXT: 'Reporter (freiberuflich)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3491',
    BART_TEXT: 'Reproduktionstechnischer Betrieb ohne Rotationstiefdruck',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3492',
    BART_TEXT: 'Reprographischer Betrieb ohne Rotationstiefdruck',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3493',
    BART_TEXT: 'Restaurant (keine Bar, Disco, Vergnügungsbetrieb)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3494',
    BART_TEXT: 'Restaurierungsbetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1097',
    BART_TEXT: 'Riesenrad',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1098',
    BART_TEXT: 'Rindermästerei',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4542',
    BART_TEXT: 'Rohlederhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4543',
    BART_TEXT: 'Rohlederhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3498',
    BART_TEXT: 'Rohrglasherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-G',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1102',
    BART_TEXT: 'Rohrreinigungsbetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4288',
    BART_TEXT: 'Rohtabakhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4289',
    BART_TEXT: 'Rohtabakhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4929',
    BART_TEXT: 'Rollladenhandel (einschließlich Einbau) - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4930',
    BART_TEXT: 'Rollladenhandel (einschließlich Einbau) - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3501',
    BART_TEXT: 'Rollladenherstellung (Holz)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3502',
    BART_TEXT: 'Rollladenherstellung (Kunststoff)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3503',
    BART_TEXT: 'Rollladenherstellung (Metall)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1104',
    BART_TEXT: 'Rollschuhbahn',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1105',
    BART_TEXT: 'Röntgenapparateherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1106',
    BART_TEXT: 'Röntgeninstitut',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1109',
    BART_TEXT: 'Röntgenröhrenherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-G',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3504',
    BART_TEXT: 'Rösterei (Kaffee)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3505',
    BART_TEXT: 'Rotationstiefdruckerei (Pappe, Papier)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1119',
    BART_TEXT: 'Ruderverein',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3507',
    BART_TEXT: 'Rundfunkanstalt',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4544',
    BART_TEXT: 'Rundfunkgerätehandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4545',
    BART_TEXT: 'Rundfunkgerätehandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3510',
    BART_TEXT: 'Rundfunksender',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3511',
    BART_TEXT: 'Rundfunkstudio',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4931',
    BART_TEXT: 'Saatguthandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4932',
    BART_TEXT: 'Saatguthandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3513',
    BART_TEXT: 'Saatgutherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1120',
    BART_TEXT: 'Saatzuchtbetriebe',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5307',
    BART_TEXT: 'Sachverständigerbüro',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5308',
    BART_TEXT: 'Sachverständigerbüro für Bauschäden',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5045',
    BART_TEXT: 'Saftbar',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1121',
    BART_TEXT: 'Säfteherstellung (pharmazeutische)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-C',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1122',
    BART_TEXT: 'Sägewerk',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5309',
    BART_TEXT: 'Salz - Gewinnungs- und Verarbeitungsbetrieb (Bergbau und artverwandte)',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1125',
    BART_TEXT: 'Salzbergbau',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4933',
    BART_TEXT: 'Samenhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4934',
    BART_TEXT: 'Samenhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3520',
    BART_TEXT: 'Sanatorium',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1127',
    BART_TEXT: 'Sandgrube',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4588',
    BART_TEXT: 'Sanitärartikelhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4589',
    BART_TEXT: 'Sanitärartikelhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3521',
    BART_TEXT: 'Sanitärinstallationsbetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4935',
    BART_TEXT: 'Sanitätsartikelhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4936',
    BART_TEXT: 'Sanitätsartikelhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1130',
    BART_TEXT: 'Sanitätshaus',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1131',
    BART_TEXT: 'Sanitätsverein',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3523',
    BART_TEXT: 'Satellitenanlagenherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3524',
    BART_TEXT: 'Sattlerei',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3527',
    BART_TEXT: 'Sauna (nicht Vergnügungsbetrieb)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3530',
    BART_TEXT: 'Schablonenherstellung (Kunststoff)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1135',
    BART_TEXT: 'Schachverein',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5310',
    BART_TEXT: 'Schädlingsbekämpfungsbetrieb (Desinfektor)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4633',
    BART_TEXT: 'Schäfer',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5134',
    BART_TEXT: 'Schäfer/Wanderschäferei',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4546',
    BART_TEXT: 'Schallplattenhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4547',
    BART_TEXT: 'Schallplattenhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3535',
    BART_TEXT: 'Schälmühle',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5311',
    BART_TEXT: 'Schaltanlagenbaubetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3537',
    BART_TEXT: 'Schalungsbau',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3538',
    BART_TEXT: 'Schamottewarenherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-G',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5167',
    BART_TEXT: 'Schankanlagenservicebetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3539',
    BART_TEXT: 'Schankwirtschaft (keine Bar, Disco, Vergnügungsbetrieb)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1141',
    BART_TEXT: 'Schau-, Verkaufsbude ohne Vorführung',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1143',
    BART_TEXT: 'Schaukel für Kleinkinder',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5312',
    BART_TEXT: 'Schaumgummiverarbeitungsbetrieb - Kaltverarbeitung',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5313',
    BART_TEXT: 'Schaumgummiverarbeitungsbetrieb - Warmverarbeitung',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5314',
    BART_TEXT: 'Schaumkunststoffbearbeitungsbetrieb - Kaltverarbeitung',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5315',
    BART_TEXT: 'Schaumkunststoffbearbeitungsbetrieb - Warmverarbeitung',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4939',
    BART_TEXT: 'Schaumkunststoffhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4940',
    BART_TEXT: 'Schaumkunststoffhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3550',
    BART_TEXT: 'Schaumkunststoffherstellung (Blockschaum) - Kaltverarbeitung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3551',
    BART_TEXT: 'Schaumkunststoffherstellung (Blockschaum) - Warmverarbeitung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5316',
    BART_TEXT: 'Schaumkunststoffverarbeitungsbetrieb - Kaltverarbeitung',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5317',
    BART_TEXT: 'Schaumkunststoffverarbeitungsbetrieb - Warmverarbeitung',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3554',
    BART_TEXT: 'Schaumweinherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3555',
    BART_TEXT: 'Schauspielschule (öffentlich)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5514',
    BART_TEXT: 'Schauspielschule (privat)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3557',
    BART_TEXT: 'Schausteller',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3561',
    BART_TEXT: 'Schieferdeckerei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5318',
    BART_TEXT: 'Schienenfahrzeugbaubetrieb (Kunststoff, ohne Polyester)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5319',
    BART_TEXT: 'Schienenfahrzeugbaubetrieb (Polyester, reaktionshärtende Kunststoffe)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5320',
    BART_TEXT: 'Schienenfahrzeuge - Reparatur- und Wartungsbetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1151',
    BART_TEXT: 'Schienenfahrzeugteile- und -Komponentenherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4254',
    BART_TEXT: 'Schießanlage/Schießbahn',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1152',
    BART_TEXT: 'Schießstand, -bude',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5321',
    BART_TEXT: 'Schiffsbaubetrieb (Holz)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5322',
    BART_TEXT: 'Schiffsbaubetrieb (Kunststoff, ohne Polyester)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5323',
    BART_TEXT: 'Schiffsbaubetrieb (Metall)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5324',
    BART_TEXT: 'Schiffsbaubetrieb (Polyester, reaktionshärtende Kunststoffe)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3571',
    BART_TEXT: 'Schiffssachverständiger',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1216',
    BART_TEXT: 'Schiffsschaukel ohne Überschlag',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1162',
    BART_TEXT: 'Schiffszimmerei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4941',
    BART_TEXT: 'Schilderhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4942',
    BART_TEXT: 'Schilderhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4332',
    BART_TEXT: 'Schirmhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4333',
    BART_TEXT: 'Schirmhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3573',
    BART_TEXT: 'Schlachterei (mit Fleisch-, Wurstbe- und -verarbeitung)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3574',
    BART_TEXT: 'Schlachterei (ohne Fleisch-, Wurstbe- und -verarbeitung)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1165',
    BART_TEXT: 'Schlachthaus',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3576',
    BART_TEXT: 'Schlachthof',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3578',
    BART_TEXT: 'Schlauchfolienherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3579',
    BART_TEXT: 'Schleifkörperherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3580',
    BART_TEXT: 'Schleifmittelherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3581',
    BART_TEXT: 'Schlepplift',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1168',
    BART_TEXT: 'Schloß',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3584',
    BART_TEXT: 'Schlosserei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3585',
    BART_TEXT: 'Schlüsseldienst',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3586',
    BART_TEXT: 'Schmiede',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3587',
    BART_TEXT: 'Schmiedewerk',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4945',
    BART_TEXT: 'Schmierstoffhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4946',
    BART_TEXT: 'Schmierstoffhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5714',
    BART_TEXT: 'Schmuckwarenhandel (nur Betriebseinrichtung)',
    'BART-Gruppe': 'VAL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'VAL-A'
  },
  {
    HGVBETRART: 'FHS5715',
    BART_TEXT: 'Schmuckwarenherstellung (nur Betriebseinrichtung)',
    'BART-Gruppe': 'VAL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'VAL-A'
  },
  {
    HGVBETRART: 'FHS3594',
    BART_TEXT: 'Schneiderei (keine Änderungsschneiderei)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3595',
    BART_TEXT: 'Schnellimbiss (freistehend, jedoch ohne Imbiss-, Verkaufswagen)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3596',
    BART_TEXT: 'Schnellimbiss (im Gebäude)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3597',
    BART_TEXT: 'Schnitzerei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1178',
    BART_TEXT: 'Schokoladenherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3598',
    BART_TEXT: 'Schönheitschirurg',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3599',
    BART_TEXT: 'Schönheitspflegesalon',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3600',
    BART_TEXT: 'Schornsteinbau',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3601',
    BART_TEXT: 'Schornsteinfegerbetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3602',
    BART_TEXT: 'Schrauben- und Mutternherstellung (gepresst)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3603',
    BART_TEXT: 'Schrauben- und Mutternherstellung (geschnitten)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4550',
    BART_TEXT: 'Schreib-, Papierwarenhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4551',
    BART_TEXT: 'Schreib-, Papierwarenhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1180',
    BART_TEXT: 'Schreibbüro',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4552',
    BART_TEXT: 'Schreibwarenhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4553',
    BART_TEXT: 'Schreibwarenhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4598',
    BART_TEXT: 'Schreibwarenhandel und Handel mit Nonfood - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4599',
    BART_TEXT: 'Schreibwarenhandel und Handel mit Nonfood - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3606',
    BART_TEXT: 'Schreinerei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5325',
    BART_TEXT: 'Schreinerei und Trockenbaubetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3607',
    BART_TEXT: 'Schrotmühle',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4554',
    BART_TEXT: 'Schrotthandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4555',
    BART_TEXT: 'Schrotthandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3609',
    BART_TEXT: 'Schrottverwertung',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5095',
    BART_TEXT: 'Schuh- und Schlüsselservice',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3610',
    BART_TEXT: 'Schuhcremeherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3611',
    BART_TEXT: 'Schuhgeschäft',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4334',
    BART_TEXT: 'Schuhhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4335',
    BART_TEXT: 'Schuhhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3612',
    BART_TEXT: 'Schuhherstellung (Gummi)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3613',
    BART_TEXT: 'Schuhherstellung (Leder)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1187',
    BART_TEXT: 'Schuhmacherei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3614',
    BART_TEXT: 'Schuhreparatur',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1188',
    BART_TEXT: 'Schuldnerberatung',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1189',
    BART_TEXT: 'Schule (öffentlich)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1190',
    BART_TEXT: 'Schule (privat)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5652',
    BART_TEXT: 'Schule der bildenden und darstellenden Künste',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3615',
    BART_TEXT: 'Schulkindergarten',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1191',
    BART_TEXT: 'Schullandheim',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3616',
    BART_TEXT: 'Schulsporthalle (öffentlich)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3617',
    BART_TEXT: 'Schulsporthalle (privat)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5653',
    BART_TEXT: 'Schulungsinstitute (Nachhilfe/Coaching/EDV)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5326',
    BART_TEXT: 'Schusterei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5383',
    BART_TEXT: 'Schützenverein',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3618',
    BART_TEXT: 'Schweine-/Kälbermästerei',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3619',
    BART_TEXT: 'Schweißerei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1195',
    BART_TEXT: 'Schwerathletikverein',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3621',
    BART_TEXT: 'Schwesternwohnheim',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3622',
    BART_TEXT: 'Schwimmbad',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1197',
    BART_TEXT: 'Schwimmverein',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3623',
    BART_TEXT: 'Secondhand-Shop (Textilien)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3624',
    BART_TEXT: 'Segelmacherei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3625',
    BART_TEXT: 'Segelschule',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1198',
    BART_TEXT: 'Segelverein',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3626',
    BART_TEXT: 'Seidenspinnerei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3627',
    BART_TEXT: 'Seidenweberei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1199',
    BART_TEXT: 'Seifen-, Putz-, Wasch-, Reinigungsmittelherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4947',
    BART_TEXT: 'Seifenhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4948',
    BART_TEXT: 'Seifenhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3629',
    BART_TEXT: 'Seifenherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3630',
    BART_TEXT: 'Seifensiederei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3631',
    BART_TEXT: 'Seilbahnbetrieb',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1200',
    BART_TEXT: 'Seilerei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4949',
    BART_TEXT: 'Seilerwarenhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4950',
    BART_TEXT: 'Seilerwarenhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3633',
    BART_TEXT: 'Seilerwarenherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3634',
    BART_TEXT: 'Sektherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3635',
    BART_TEXT: 'Sektkellerei',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5168',
    BART_TEXT: 'Selbsthilfeverein',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3637',
    BART_TEXT: 'Senfherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-C',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5522',
    BART_TEXT: 'Seniorenheim',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5523',
    BART_TEXT: 'Seniorenheim ohne eigene ärztliche Betreuung',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3639',
    BART_TEXT: 'Sesselliftbetrieb',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4951',
    BART_TEXT: 'Sexartikelhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4952',
    BART_TEXT: 'Sexartikelhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5557',
    BART_TEXT: 'Shisha Bar',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5368',
    BART_TEXT: 'Sicherheitsmonteur',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3642',
    BART_TEXT: 'Siebdruckerei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3643',
    BART_TEXT: 'Siedlungsgenossenschaft',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3648',
    BART_TEXT: 'Siliconlack - Herstellung und Verarbeitung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3650',
    BART_TEXT: 'Siliziumherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3651',
    BART_TEXT: 'Siloherstellung (Beton)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3652',
    BART_TEXT: 'Siloherstellung (Gummi)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3653',
    BART_TEXT: 'Siloherstellung (Kunststoff)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3654',
    BART_TEXT: 'Siloherstellung (Metall)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5327',
    BART_TEXT: 'Sinterungsbetrieb (Metallpulver)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3658',
    BART_TEXT: 'Skatebahn',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1206',
    BART_TEXT: 'Skatverein',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4556',
    BART_TEXT: 'Skihandel - Einzelhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4557',
    BART_TEXT: 'Skihandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3660',
    BART_TEXT: 'Skilehrer',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3661',
    BART_TEXT: 'Skiliftbetrieb',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1207',
    BART_TEXT: 'Skischlepplift',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3662',
    BART_TEXT: 'Skischule',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3663',
    BART_TEXT: 'Softwareentwicklung',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4955',
    BART_TEXT: 'Softwarehandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4956',
    BART_TEXT: 'Softwarehandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3665',
    BART_TEXT: 'Softwareverlag',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1208',
    BART_TEXT: 'Solaranlagenherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-G',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3666',
    BART_TEXT: 'Solarheizungsbau',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1209',
    BART_TEXT: 'Solarium',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3667',
    BART_TEXT: 'Sondermülldeponie',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5561',
    BART_TEXT: 'Sondermüllverbrennungsanlage mit Stromerzeugung',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3669',
    BART_TEXT: 'Sonnenstudio',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5084',
    BART_TEXT: 'Sonnenstudio und Kosmetik',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4153',
    BART_TEXT: 'Sonstige Fachklinik',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5052',
    BART_TEXT: 'sonstige feinmechanische Betriebe (nicht Meß-/Regeltechnik)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3670',
    BART_TEXT: 'sonstige Schaukel',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4957',
    BART_TEXT: 'Souvenierhandel (kein Kiosk) - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4958',
    BART_TEXT: 'Souvenierhandel (kein Kiosk) - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3672',
    BART_TEXT: 'Sozialstation',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3673',
    BART_TEXT: 'Spanplattenherstellung (magnesitgebunden)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3674',
    BART_TEXT: 'Spanplattenherstellung (zementgebunden)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1221',
    BART_TEXT: 'Sparkasse',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1223',
    BART_TEXT: 'Speiseeisherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1224',
    BART_TEXT: 'Speisefettherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-C',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3676',
    BART_TEXT: 'Speiselokal',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3677',
    BART_TEXT: 'Speiseölherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-C',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3678',
    BART_TEXT: 'Spenglerei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3680',
    BART_TEXT: 'Spezialpapierherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3681',
    BART_TEXT: 'Spielbank',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1225',
    BART_TEXT: 'Spielhalle',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3682',
    BART_TEXT: 'Spielkasino',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5723',
    BART_TEXT: 'Spielothek',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5724',
    BART_TEXT: 'Spielsalon',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4290',
    BART_TEXT: 'Spielwarenhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4291',
    BART_TEXT: 'Spielwarenhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3684',
    BART_TEXT: 'Spielwarenherstellung (Holz)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3685',
    BART_TEXT: 'Spielwarenherstellung (Kunststoff)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3686',
    BART_TEXT: 'Spielwarenherstellung (Metall)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1229',
    BART_TEXT: 'Spinnerei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3687',
    BART_TEXT: 'Spirituosenbrennerei',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4336',
    BART_TEXT: 'Spirituosenhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4337',
    BART_TEXT: 'Spirituosenhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1231',
    BART_TEXT: 'Spirituosenherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4338',
    BART_TEXT: 'Sportartikelhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4339',
    BART_TEXT: 'Sportartikelhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4959',
    BART_TEXT: 'Sportboothandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4960',
    BART_TEXT: 'Sportboothandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4620',
    BART_TEXT: 'Sportgeräteherstellung nicht Fahrräder (überwiegend Holz)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4622',
    BART_TEXT: 'Sportgeräteherstellung nicht Fahrräder (überwiegend Kunststoff)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1236',
    BART_TEXT: 'Sportgeräteverleih',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1237',
    BART_TEXT: 'Sporthalle',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3689',
    BART_TEXT: 'Sportheim',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5654',
    BART_TEXT: 'Sportlehrer',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1238',
    BART_TEXT: 'Sportschule',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1239',
    BART_TEXT: 'Sportstudio',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5666',
    BART_TEXT: 'Sportverein',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5667',
    BART_TEXT: 'Sportverein (nicht Vereinsheim)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5726',
    BART_TEXT: 'Sportverein (ohne Vertrags-, Berufssportler oder -trainer)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3693',
    BART_TEXT: 'Sprachenschule (öffentlich)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5526',
    BART_TEXT: 'Sprachenschule (privat)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1244',
    BART_TEXT: 'Sprengbetrieb',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4962',
    BART_TEXT: 'Sprengmeister',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3695',
    BART_TEXT: 'Spritzgussproduktherstellung (Kunststoff)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3696',
    BART_TEXT: 'Spritzlackiererei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3697',
    BART_TEXT: 'Squashanlage',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3698',
    BART_TEXT: 'Squashcenter',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3699',
    BART_TEXT: 'Squashhalle',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3700',
    BART_TEXT: 'Stadion',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3701',
    BART_TEXT: 'Stadthalle',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5169',
    BART_TEXT: 'Stahl-, Metall-, Eisenbaubetrieb (Bauhauptgewerbe)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-C',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5328',
    BART_TEXT: 'Stahlbaubetrieb',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-C',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3703',
    BART_TEXT: 'Stahlerzeugung',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4965',
    BART_TEXT: 'Stahlhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5528',
    BART_TEXT: 'Stahlhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-C',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3705',
    BART_TEXT: 'Stahlstichdruckerei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5329',
    BART_TEXT: 'Stahlverarbeitungsbetrieb (kein Stahl-, Walz- und Hammerwerk)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4967',
    BART_TEXT: 'Stahlwarenhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5530',
    BART_TEXT: 'Stahlwarenhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-C',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3708',
    BART_TEXT: 'Stahlwarenherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-C',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3713',
    BART_TEXT: 'Statiker',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4076',
    BART_TEXT: 'Statiker, Tragwerksplaner, Prüfingenieur für Baustatik (hoheitliche und privatrechtliche Tätigkeit)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4019',
    BART_TEXT: 'Statiker, Tragwerksplaner, Prüfingenieur für Baustatik (hoheitliche und privatrechtliche Tätigkeit), Brandschutzingenieur',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1253',
    BART_TEXT: 'Stauereibetrieb',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3714',
    BART_TEXT: 'Steakhaus',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5330',
    BART_TEXT: 'Steinbe- und -verarbeitungsbetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3717',
    BART_TEXT: 'Steinbearbeitung',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3718',
    BART_TEXT: 'Steinbildhauerei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1261',
    BART_TEXT: 'Steinbruch',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3719',
    BART_TEXT: 'Steingewinnung',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3724',
    BART_TEXT: 'Steinmetzbetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3725',
    BART_TEXT: 'Steinsägerei, -schleiferei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3726',
    BART_TEXT: 'Steinverarbeitendes Handwerk',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3727',
    BART_TEXT: 'Steinverarbeitung',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1267',
    BART_TEXT: 'Steinverarbeitung (natürliche Gesteine / nicht handwerklich)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1268',
    BART_TEXT: 'Stempelherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1269',
    BART_TEXT: 'Steppdeckenherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3730',
    BART_TEXT: 'Stepperei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5331',
    BART_TEXT: 'Steuerberatungsbüro',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1274',
    BART_TEXT: 'Steuerungstechnik-Teileherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3731',
    BART_TEXT: 'Stickerei',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3733',
    BART_TEXT: 'Stoffdruckerei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4604',
    BART_TEXT: 'Stoffhandel - Einzelhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4605',
    BART_TEXT: 'Stoffhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1275',
    BART_TEXT: 'Strahlentherapeut - Assistenz-Facharzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1276',
    BART_TEXT: 'Strahlentherapeut - freie Praxis - ambulant/operativ',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1277',
    BART_TEXT: 'Strahlentherapeut - freie Praxis - stationär bzw. Belegarzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1278',
    BART_TEXT: 'Strahlentherapeut - stationär - Abteilungsleiter',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1279',
    BART_TEXT: 'Strahlentherapeut - stationär - Chefarzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1280',
    BART_TEXT: 'Strahlentherapeut - stationär - Oberarzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1281',
    BART_TEXT: 'Strahlentherapeut - stationär - Vertretungsarzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4970',
    BART_TEXT: 'Strahlentherapeut-Neurologe - stationär/ambulant, ohne eigene Praxis - in Kliniken beschäftigt – Abteilungsleiter',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4971',
    BART_TEXT: 'Strahlentherapeut-Neurologe - stationär/ambulant, ohne eigene Praxis - in Kliniken beschäftigt – Chefarzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3735',
    BART_TEXT: 'Strangpressproduktherstellung (Kunststoff)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5332',
    BART_TEXT: 'Straßen- und Bürgersteigreinigungsbetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4077',
    BART_TEXT: 'Straßenbaubetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3738',
    BART_TEXT: 'Straßenreinigung',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4121',
    BART_TEXT: 'Straßenreinigung und Winterdienst',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3739',
    BART_TEXT: 'Straußwirtschaft',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3740',
    BART_TEXT: 'Streichgarn - Ausrüstung und Veredelung',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3741',
    BART_TEXT: 'Streichgarnspinnerei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3742',
    BART_TEXT: 'Streichgarnweberei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1286',
    BART_TEXT: 'Strickerei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4560',
    BART_TEXT: 'Strickmaschinenhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4561',
    BART_TEXT: 'Strickmaschinenhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4562',
    BART_TEXT: 'Strickwarenhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4563',
    BART_TEXT: 'Strickwarenhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3746',
    BART_TEXT: 'Strickwarenherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4972',
    BART_TEXT: 'Strickwarenzubehörhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4973',
    BART_TEXT: 'Strickwarenzubehörhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3748',
    BART_TEXT: 'Stripteaselokal',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3749',
    BART_TEXT: 'Strohfaser - Halbstoffherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1287',
    BART_TEXT: 'Strohflechterei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3750',
    BART_TEXT: 'Stromnetzbetreiber',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4340',
    BART_TEXT: 'Strumpfhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4341',
    BART_TEXT: 'Strumpfhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3752',
    BART_TEXT: 'Strumpfherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1289',
    BART_TEXT: 'Studentenheim',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3754',
    BART_TEXT: 'Stukkateur',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4078',
    BART_TEXT: 'Stundenhotel',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5080',
    BART_TEXT: 'Supermarkt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3755',
    BART_TEXT: 'Surfschule',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4974',
    BART_TEXT: 'Süßwarenhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4975',
    BART_TEXT: 'Süßwarenhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4088',
    BART_TEXT: 'Süßwarenherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3757',
    BART_TEXT: 'Swingerclub',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3758',
    BART_TEXT: 'Synagoge',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3759',
    BART_TEXT: 'Synthesekautschuk - Be- und Verarbeitung',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1294',
    BART_TEXT: 'Tabakverarbeitung',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4342',
    BART_TEXT: 'Tabakwarenhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4343',
    BART_TEXT: 'Tabakwarenhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1296',
    BART_TEXT: 'Tabakwarenherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1297',
    BART_TEXT: 'Tagescafé',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5170',
    BART_TEXT: 'Tagesmutter/Kindertagesbetreuung',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3761',
    BART_TEXT: 'Tankbau',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-C',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3762',
    BART_TEXT: 'Tankherstellung (Gummi)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-C',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3763',
    BART_TEXT: 'Tankherstellung (Kunststoff)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-C',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3764',
    BART_TEXT: 'Tankherstellung (Metall)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-C',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3765',
    BART_TEXT: 'Tankreinigungsbetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3766',
    BART_TEXT: 'Tankschutzbetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3767',
    BART_TEXT: 'Tankstelle',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1303',
    BART_TEXT: 'Tanzlokal',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1304',
    BART_TEXT: 'Tanzschule',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1305',
    BART_TEXT: 'Tanzverein',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4976',
    BART_TEXT: 'Tapetenhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4977',
    BART_TEXT: 'Tapetenhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3769',
    BART_TEXT: 'Tapetenherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3770',
    BART_TEXT: 'Tapezierbetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5655',
    BART_TEXT: 'Tattoo- und Piercingstudio',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3774',
    BART_TEXT: 'Tauchschule',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4980',
    BART_TEXT: 'Tauchsportartikelhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4981',
    BART_TEXT: 'Tauchsportartikelhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1307',
    BART_TEXT: 'Tauchverein',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3776',
    BART_TEXT: 'Tauschgeschäft (Spielwaren)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3777',
    BART_TEXT: 'Tauschgeschäft (Textilien)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1308',
    BART_TEXT: 'Taxiunternehmen',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3778',
    BART_TEXT: 'Technische Lehranstalt (öffentlich)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5535',
    BART_TEXT: 'Technische Lehranstalt (privat)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4982',
    BART_TEXT: 'Technische Öle - Handel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4983',
    BART_TEXT: 'Technische Öle - Handel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4564',
    BART_TEXT: 'Teehandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4565',
    BART_TEXT: 'Teehandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3782',
    BART_TEXT: 'Teeherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1310',
    BART_TEXT: 'Teestube',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1311',
    BART_TEXT: 'Teichwirtschaft',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1312',
    BART_TEXT: 'Teigwarenherstellung ohne Backwaren',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4566',
    BART_TEXT: 'Telefonhandel (ohne Handyhandel) - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4567',
    BART_TEXT: 'Telefonhandel (ohne Handyhandel) - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4568',
    BART_TEXT: 'Telekommunikationsgerätehandel (ohne Handyhandel) - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4569',
    BART_TEXT: 'Telekommunikationsgerätehandel (ohne Handyhandel) - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3788',
    BART_TEXT: 'Telekommunikationsnetzbetreiber',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4092',
    BART_TEXT: 'Tennisanlage',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3789',
    BART_TEXT: 'Tenniscenter',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3790',
    BART_TEXT: 'Tennishalle',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3791',
    BART_TEXT: 'Tennisschule',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5384',
    BART_TEXT: 'Tennisverein',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4984',
    BART_TEXT: 'Teppichhandel (mit Orientteppichen) - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4985',
    BART_TEXT: 'Teppichhandel (mit Orientteppichen) - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4986',
    BART_TEXT: 'Teppichhandel (ohne Orientteppiche) - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4987',
    BART_TEXT: 'Teppichhandel (ohne Orientteppiche) - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3794',
    BART_TEXT: 'Teppichherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5333',
    BART_TEXT: 'Teppichreinigungsbetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1319',
    BART_TEXT: 'Teppichverlegebetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3796',
    BART_TEXT: 'Teppichweberei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3797',
    BART_TEXT: 'Testamentsvollstrecker',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5334',
    BART_TEXT: 'Textil - Ausrüstungs- und Veredelungsbetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3800',
    BART_TEXT: 'Textildruckerei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3801',
    BART_TEXT: 'Textile Flächenbeläge (genadelt, gewebt, getuftet) - Herstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4570',
    BART_TEXT: 'Textilienhandel (ohne Pelz-, Leder-, Alcantarawaren) - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4571',
    BART_TEXT: 'Textilienhandel (ohne Pelz-, Leder-, Alcantarawaren) - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3803',
    BART_TEXT: 'Textilrecycling',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5335',
    BART_TEXT: 'Textilreinigungsbetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3805',
    BART_TEXT: 'Textilveredelung',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3806',
    BART_TEXT: 'Textilwarenherstellung (Bekleidung)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1325',
    BART_TEXT: 'Textilwarenherstellung (Kunstseide, Zellwolle)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1327',
    BART_TEXT: 'Textilwarenherstellung (vollsynthetische Fasern)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1328',
    BART_TEXT: 'Theater',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1329',
    BART_TEXT: 'Theaterverein ohne Berufsschauspieler',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3807',
    BART_TEXT: 'Thermopapierherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3808',
    BART_TEXT: 'Thermoplaste - Spritzguss-, Strangpress-, Gieß- und Schleudergussproduktherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4081',
    BART_TEXT: 'Tiefbaubetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3809',
    BART_TEXT: 'Tiefgaragen, -Verwaltung',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3810',
    BART_TEXT: 'Tiefkühlkostherstellung (Fleisch, Wurst, Fisch)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3811',
    BART_TEXT: 'Tiefkühlkostherstellung (Obst, Gemüse)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1333',
    BART_TEXT: 'Tier- und Viehkommission',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4606',
    BART_TEXT: 'Tierartikel-/Zubehörhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4607',
    BART_TEXT: 'Tierartikel-/Zubehörhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1334',
    BART_TEXT: 'Tierarzt (Kleintierpraxis)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1335',
    BART_TEXT: 'Tierarzt (nicht Kleintierpraxis)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3813',
    BART_TEXT: 'Tiergarten',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4292',
    BART_TEXT: 'Tierhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4293',
    BART_TEXT: 'Tierhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3814',
    BART_TEXT: 'Tierhandlung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1337',
    BART_TEXT: 'Tierheilpraktiker',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1338',
    BART_TEXT: 'Tierheim',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1339',
    BART_TEXT: 'Tierklinik',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1340',
    BART_TEXT: 'Tierkörperverwertung',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1341',
    BART_TEXT: 'Tiernahrungherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1342',
    BART_TEXT: 'Tiertransporteur',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3815',
    BART_TEXT: 'Tinten - Herstellung und Verarbeitung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3816',
    BART_TEXT: 'Tischlerei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3818',
    BART_TEXT: 'Ton - Gewinnung und Aufbereitung',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3819',
    BART_TEXT: 'Tonerde - Herstellung und Verarbeitung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3820',
    BART_TEXT: 'Tonstudio',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4572',
    BART_TEXT: 'Tonträgerhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4573',
    BART_TEXT: 'Tonträgerhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3822',
    BART_TEXT: 'Tonträgerverlag',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1349',
    BART_TEXT: 'Töpferschule',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4988',
    BART_TEXT: 'Töpferwarenhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4989',
    BART_TEXT: 'Töpferwarenhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-G',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3824',
    BART_TEXT: 'Töpferwarenherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-G',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4082',
    BART_TEXT: 'Toxikologe',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3827',
    BART_TEXT: 'Trachtenmodengeschäft',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1352',
    BART_TEXT: 'Trachtenverein',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3829',
    BART_TEXT: 'Tragwerksplaner',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4261',
    BART_TEXT: 'Trainer (nicht Sport)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5656',
    BART_TEXT: 'Trainer (Sport)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3831',
    BART_TEXT: 'Transformator',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1353',
    BART_TEXT: 'Transformatorenherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3832',
    BART_TEXT: 'Transistorenherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3833',
    BART_TEXT: 'Transportbetonwerk',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4990',
    BART_TEXT: 'Treibgashandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4991',
    BART_TEXT: 'Treibgashandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3837',
    BART_TEXT: 'Treibhaus (Inhalt)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4992',
    BART_TEXT: 'Treibstoffhandel (nicht Tankstelle) - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4993',
    BART_TEXT: 'Treibstoffhandel (nicht Tankstelle) - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3839',
    BART_TEXT: 'Trennung, Sortierung, Aufbereitung gemischter Stoffe (Recycling)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5336',
    BART_TEXT: 'Tresorbaubetrieb',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3841',
    BART_TEXT: 'Trikotagenherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5075',
    BART_TEXT: 'Trinkhalle (im Gebäude)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3843',
    BART_TEXT: 'Trockenbaubetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3844',
    BART_TEXT: 'Trockenfertiggerichteherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-C',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3847',
    BART_TEXT: 'Trockenpulverherstellung (Nahrungsmittel)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-C',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3848',
    BART_TEXT: 'Trocknungsanlage (Futtermittel)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3849',
    BART_TEXT: 'Trocknungsanlage (Landwirtschaft)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3851',
    BART_TEXT: 'Trocknungsanlage (Nahrungsmittel)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4994',
    BART_TEXT: 'Trödelhandel (nicht Textil) - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4995',
    BART_TEXT: 'Trödelhandel (nicht Textil) - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4294',
    BART_TEXT: 'Tuchhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4295',
    BART_TEXT: 'Tuchhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3854',
    BART_TEXT: 'Tuchweberei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3855',
    BART_TEXT: 'Tüncher',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3856',
    BART_TEXT: 'Tunnelbaubetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3857',
    BART_TEXT: 'Türenherstellung (Holz)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3858',
    BART_TEXT: 'Türenherstellung (Kunststoff)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3859',
    BART_TEXT: 'Türenherstellung (Metall)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3861',
    BART_TEXT: 'Turnhalle (keine Schulsporthalle)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3862',
    BART_TEXT: 'Turnhalle (Schulsporthalle) (öffentlich)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5540',
    BART_TEXT: 'Turnhalle (Schulsporthalle) (privat)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1356',
    BART_TEXT: 'Turnverein',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5337',
    BART_TEXT: 'U-Bahn-Baubetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3864',
    BART_TEXT: 'Übergangswohnheim',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1358',
    BART_TEXT: 'Überschlagschaukel',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1359',
    BART_TEXT: 'Übersetzungsbüro',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5731',
    BART_TEXT: 'Uhrenhandel (nur Betriebseinrichtung)',
    'BART-Gruppe': 'VAL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'VAL-A'
  },
  {
    HGVBETRART: 'FHS5732',
    BART_TEXT: 'Uhrenherstellung (nur Betriebseinrichtung)',
    'BART-Gruppe': 'VAL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'VAL-A'
  },
  {
    HGVBETRART: 'FHS3872',
    BART_TEXT: 'Umformer-, Umspannstation',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3873',
    BART_TEXT: 'Umweltingenieur',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3874',
    BART_TEXT: 'Uni-Klinik',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4145',
    BART_TEXT: 'Universitätsklinikum',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4998',
    BART_TEXT: 'Unterhaltungselektronikhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4999',
    BART_TEXT: 'Unterhaltungselektronikhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1369',
    BART_TEXT: 'Unterhaltungselektronikherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4263',
    BART_TEXT: 'Unterhaltungselektronikreparatur (ohne Handel)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1370',
    BART_TEXT: 'Unterhaltungselektronik-Teileherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5338',
    BART_TEXT: 'Unternehmensberatungsbüro',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1372',
    BART_TEXT: 'Urologe - Assistenz-Facharzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1373',
    BART_TEXT: 'Urologe - freie Praxis - ambulant',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1374',
    BART_TEXT: 'Urologe - freie Praxis - ambulant/operativ',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1375',
    BART_TEXT: 'Urologe - freie Praxis - stationär bzw. Belegarzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1376',
    BART_TEXT: 'Urologe - stationär - Abteilungsleiter',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1377',
    BART_TEXT: 'Urologe - stationär - Chefarzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1378',
    BART_TEXT: 'Urologe - stationär - Oberarzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1379',
    BART_TEXT: 'Urologe - stationär - Vertretungsarzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3878',
    BART_TEXT: 'Varieté (mit Bewirtschaftung)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3879',
    BART_TEXT: 'Varieté (ohne Bewirtschaftung)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3880',
    BART_TEXT: 'Vaselineverarbeitung',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5086',
    BART_TEXT: 'Veranstaltungsservice (Ton- und Lichttechnik)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5339',
    BART_TEXT: 'Verbraucherberatungsbüro',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1383',
    BART_TEXT: 'Verbrauchermarkt',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3881',
    BART_TEXT: 'Verein (nicht Vereinsheim)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3882',
    BART_TEXT: 'Verein mit ideeller Tendenz',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4083',
    BART_TEXT: 'Vereinsheim',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5340',
    BART_TEXT: 'Verfugungsbetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1390',
    BART_TEXT: 'Vergnügungsbetrieb, Eroscenter, Massagesalon, Stundenhotel',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3883',
    BART_TEXT: 'Vergnügungspark (stationäre Fahrgeschäfte)',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3884',
    BART_TEXT: 'Verkaufsbude (freistehend, jedoch ohne Imbiss-, Verkaufswagen)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3885',
    BART_TEXT: 'Verkaufsbude (im Gebäude)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3886',
    BART_TEXT: 'Verkaufswagen',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3887',
    BART_TEXT: 'Verlag (mit Rotationstiefdruck)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3888',
    BART_TEXT: 'Verlag (ohne Druckerei)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3889',
    BART_TEXT: 'Verlag (ohne Rotationstiefdruck)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4084',
    BART_TEXT: 'Vermessungsingenieur',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3890',
    BART_TEXT: 'Verputzerei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5000',
    BART_TEXT: 'Versandhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5001',
    BART_TEXT: 'Versandhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1397',
    BART_TEXT: 'Versandhaus',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1398',
    BART_TEXT: 'Verschönerungsverein',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5341',
    BART_TEXT: 'Versicherungsmaklerbüro',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1399',
    BART_TEXT: 'Versicherungsvertreter',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3892',
    BART_TEXT: 'Verwaltung (öffentlich)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3893',
    BART_TEXT: 'Verwaltung (privat)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3894',
    BART_TEXT: 'Verzinkerei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3895',
    BART_TEXT: 'Videofilmverleih',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3898',
    BART_TEXT: 'Videospieleverleih',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3899',
    BART_TEXT: 'Videostudio',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1401',
    BART_TEXT: 'Videothek',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5370',
    BART_TEXT: 'Viehhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5003',
    BART_TEXT: 'Viehhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3902',
    BART_TEXT: 'Vogelhandlung',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1421',
    BART_TEXT: 'Vogelpark',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3903',
    BART_TEXT: 'Vollsynthetische Fasern - Herstellung und Verarbeitung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3904',
    BART_TEXT: 'Vorverkaufsstelle',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3905',
    BART_TEXT: 'Vulkanisierbetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3906',
    BART_TEXT: 'Wach- und Sicherheitsunternehmen',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1424',
    BART_TEXT: 'Wachs-, Talg- und Stearinherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-C',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3907',
    BART_TEXT: 'Wachsleinwandherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3909',
    BART_TEXT: 'Wachstuchherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5077',
    BART_TEXT: 'Waffenhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5005',
    BART_TEXT: 'Waffenhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3911',
    BART_TEXT: 'Waffenherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3912',
    BART_TEXT: 'Waisenhaus',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3914',
    BART_TEXT: 'Walzwerk - Eisen, Stahl',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1434',
    BART_TEXT: 'Wanderschäferei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1435',
    BART_TEXT: 'Wanderverein',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1436',
    BART_TEXT: 'Warenhaus',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4574',
    BART_TEXT: 'Wäschehandel (Bekleidung) - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4575',
    BART_TEXT: 'Wäschehandel (Bekleidung) - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-C',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4576',
    BART_TEXT: 'Wäschehandel (Bett-, Haushaltswäsche) - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4577',
    BART_TEXT: 'Wäschehandel (Bett-, Haushaltswäsche) - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-C',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3919',
    BART_TEXT: 'Wäschemangel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3920',
    BART_TEXT: 'Wäscherei (auch inkl. Annahmestelle)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3921',
    BART_TEXT: 'Wäscherei (nur Annahmestelle)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3922',
    BART_TEXT: 'Waschmittelherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1438',
    BART_TEXT: 'Waschsalon',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3923',
    BART_TEXT: 'Waschstrasse',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3927',
    BART_TEXT: 'Wasserfahrzeugbau (Polyester, reaktionshärtende Kunststoffe)',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1440',
    BART_TEXT: 'Wasserfahrzeugteile- und -Komponentenherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3928',
    BART_TEXT: 'Wassergewinnung',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1441',
    BART_TEXT: 'Wasserinstallationsbetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3929',
    BART_TEXT: 'Wasserkraftanlage',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4578',
    BART_TEXT: 'Wassersportartikelhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4579',
    BART_TEXT: 'Wassersportartikelhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3931',
    BART_TEXT: 'Wasserversorgung',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3932',
    BART_TEXT: 'Wasserverteilung',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3933',
    BART_TEXT: 'Wasserwerk',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1442',
    BART_TEXT: 'Wasserwerk mit AVB-Vereinbarung',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1443',
    BART_TEXT: 'Wasserwerk ohne AVB-Vereinbarung',
    'BART-Gruppe': 'DL',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5171',
    BART_TEXT: 'Web Developer',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5172',
    BART_TEXT: 'Webdesigner',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1444',
    BART_TEXT: 'Weberei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5173',
    BART_TEXT: 'Webmaster',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4296',
    BART_TEXT: 'Weinhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4297',
    BART_TEXT: 'Weinhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1446',
    BART_TEXT: 'Weinkellerei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3935',
    BART_TEXT: 'Weinkelterei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3936',
    BART_TEXT: 'Weinsäure, kosmetisch - Verarbeitung',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3937',
    BART_TEXT: 'Weinstube',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5006',
    BART_TEXT: 'Wellness- und Esoterische Massagen (nicht erotisch)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5658',
    BART_TEXT: 'Wellness-Masseur/-in',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3938',
    BART_TEXT: 'Wellpappeherstellung',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1447',
    BART_TEXT: 'Werbeagentur',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3939',
    BART_TEXT: 'Werbegraphikatelier',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5174',
    BART_TEXT: 'Werbetechniker',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3940',
    BART_TEXT: 'Werft - Reparatur und Wartung',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5558',
    BART_TEXT: 'Werkzeug-, Maschinen-, Baumaschinen-, Baugerätehandel und Verleih - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5559',
    BART_TEXT: 'Werkzeug-, Maschinen-, Baumaschinen-, Baugerätehandel und Verleih - Großhandel, Baumaschinenhandel (ohne Reparatur) - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5342',
    BART_TEXT: 'Werkzeugbaubetrieb',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5007',
    BART_TEXT: 'Werkzeughandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5008',
    BART_TEXT: 'Werkzeughandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5549',
    BART_TEXT: 'Werkzeugverleih',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3943',
    BART_TEXT: 'Wettannahme',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3944',
    BART_TEXT: 'Wettbüro',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5009',
    BART_TEXT: 'Wildhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5010',
    BART_TEXT: 'Wildhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1450',
    BART_TEXT: 'Wildpark',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1451',
    BART_TEXT: 'Wildwasserbahn',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3946',
    BART_TEXT: 'Windkraftanlage',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4122',
    BART_TEXT: 'Winterdienst',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1452',
    BART_TEXT: 'Wintersportverein',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1453',
    BART_TEXT: 'Wirkerei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5343',
    BART_TEXT: 'Wirtschaftsberatungsbüro',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3950',
    BART_TEXT: 'Wirtschaftsprüfer',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1454',
    BART_TEXT: 'Wissenschaftlicher Verein',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1455',
    BART_TEXT: 'Wissenschaftliches Institut, höhere technische Lehranstalt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1456',
    BART_TEXT: 'Wohnwagen(teile)bau',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5011',
    BART_TEXT: 'Wohnwagenhandel (mit Reparatur) - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5371',
    BART_TEXT: 'Wohnwagenhandel (mit Reparatur) - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5013',
    BART_TEXT: 'Wohnwagenhandel (ohne Reparatur) - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5078',
    BART_TEXT: 'Wohnwagenhandel (ohne Reparatur) - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3953',
    BART_TEXT: 'Wohnwagenverleih (Büro)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5015',
    BART_TEXT: 'Wollhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5016',
    BART_TEXT: 'Wollhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3956',
    BART_TEXT: 'Wurstwarenherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-C',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5659',
    BART_TEXT: 'Yogastudio/Yogalehrer',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1457',
    BART_TEXT: 'Zahnarzt - freie Praxis - ambulant/operativ',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1458',
    BART_TEXT: 'Zahnarzt - freie Praxis - stationär bzw. Belegarzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5017',
    BART_TEXT: 'Zahnarzt - stationär',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3957',
    BART_TEXT: 'Zahnarzt mit kosmetischer Chirurgie',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1460',
    BART_TEXT: 'Zahnarzt mit kosmetischer Chirurgie - freie Praxis - ambulant/operativ',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1461',
    BART_TEXT: 'Zahnarzt mit kosmetischer Chirurgie - freie Praxis - Belegarzt',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3958',
    BART_TEXT: 'Zahnklinik',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3959',
    BART_TEXT: 'Zahnmedizinisches Labor',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3960',
    BART_TEXT: 'Zahnpastaherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-F',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1462',
    BART_TEXT: 'Zahntechnischer Betrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3963',
    BART_TEXT: 'Zaponlackherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-D',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1463',
    BART_TEXT: 'Zauberer',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1464',
    BART_TEXT: 'Zeichenschule',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3967',
    BART_TEXT: 'Zeitarbeitsunternehmen',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4580',
    BART_TEXT: 'Zeitschriftenhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4581',
    BART_TEXT: 'Zeitschriftenhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4592',
    BART_TEXT: 'Zeitschriftenhandel, Lotto - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4593',
    BART_TEXT: 'Zeitschriftenhandel, Lotto - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3969',
    BART_TEXT: 'Zeitschriftenverlag (ohne Druckerei)',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3970',
    BART_TEXT: 'Zeitschriftenverlag mit Rotationstiefdruck',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3971',
    BART_TEXT: 'Zeitschriftenverlag ohne Rotationstiefdruck',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1465',
    BART_TEXT: 'Zellstoff-, Zellulosefabrik',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5344',
    BART_TEXT: 'Zeltherstellungsbetrieb',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3979',
    BART_TEXT: 'Zeltverleih',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1466',
    BART_TEXT: 'Zeltverleih ohne Mobiliar',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3980',
    BART_TEXT: 'Zementherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-A',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1468',
    BART_TEXT: 'Ziegelei',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-G',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1469',
    BART_TEXT: 'Ziehwerk',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4590',
    BART_TEXT: 'Zigarettenhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4591',
    BART_TEXT: 'Zigarettenhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3982',
    BART_TEXT: 'Zigarettenherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4344',
    BART_TEXT: 'Zigarrenhandel - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4345',
    BART_TEXT: 'Zigarrenhandel - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3983',
    BART_TEXT: 'Zigarrenherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1472',
    BART_TEXT: 'Zimmerei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1473',
    BART_TEXT: 'Zimmervermittlung',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3986',
    BART_TEXT: 'Zinkoxid-Farbpigmente - Verarbeitung',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3988',
    BART_TEXT: 'Zinngießerei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1474',
    BART_TEXT: 'Zirkus',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3991',
    BART_TEXT: 'Ziselierbetrieb',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1477',
    BART_TEXT: 'Zoo',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1478',
    BART_TEXT: 'Zoologische Handlung',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5024',
    BART_TEXT: 'Zubehörhandel für Bekleidung - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5025',
    BART_TEXT: 'Zubehörhandel für Bekleidung - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5026',
    BART_TEXT: 'Zubehörhandel für Strickwaren - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-B',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5027',
    BART_TEXT: 'Zubehörhandel für Strickwaren - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS1480',
    BART_TEXT: 'Zuchtviehgenossenschaft',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3994',
    BART_TEXT: 'Zuckerfabrik',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS4089',
    BART_TEXT: 'Zuckerwarenherstellung',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'TRW-HAHE-B',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5345',
    BART_TEXT: 'Zwangsverwaltungsbüro',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-C',
    'Ausstellung (AUS)': 'AUS-C',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5028',
    BART_TEXT: 'Zweiradhandel (nicht Fahrrad, mit Reparatur) - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5372',
    BART_TEXT: 'Zweiradhandel (nicht Fahrrad, mit Reparatur) - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5030',
    BART_TEXT: 'Zweiradhandel (nicht Fahrrad, ohne Reparatur) - Einzelhandel',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS5079',
    BART_TEXT: 'Zweiradhandel (nicht Fahrrad, ohne Reparatur) - Großhandel',
    'BART-Gruppe': 'HA/HE',
    'Ware (TRW)': 'kein Preis',
    'Werkverkehr (WKV)': 'kein Preis',
    'Ausstellung (AUS)': 'kein Preis',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3998',
    BART_TEXT: 'Zwirnerei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-A',
    'Werkverkehr (WKV)': 'WKV-HW-A',
    'Ausstellung (AUS)': 'AUS-A',
    'Valoren (VAL)': 'kein Preis'
  },
  {
    HGVBETRART: 'FHS3999',
    BART_TEXT: 'Zylinderschleiferei',
    'BART-Gruppe': 'HW',
    'Ware (TRW)': 'TRW-HW-C',
    'Werkverkehr (WKV)': 'WKV-HW-B',
    'Ausstellung (AUS)': 'AUS-B',
    'Valoren (VAL)': 'kein Preis'
  },
];
